import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import "./FilterBtnGroup.css";

const FilterBtnGroup = ({ filterValue, setFilterValue, filterOptions }) => {
  const handleButtonClick = (value) => {
    setFilterValue(value);
  };
  // how to pass  two different select options to component which filters react table data on select toggle options

  return (
    <ButtonGroup className="filter-btn-group">
      {filterOptions?.map((listItem) => (
        <Button
          key={listItem.value}
          variant={filterValue === listItem.label ? "active" : "inactive"}
          onClick={() => handleButtonClick(listItem.label)}
          size="sm"
        >
          {listItem.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default FilterBtnGroup;
