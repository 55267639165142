import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { toast, ToastContainer } from "react-toastify";
import api from "../../../../Services/api";
import {
  Editicon,
  Deleteicon,
  Viewicon,
} from "../../../../Components/IconComponents";
import { useKeycloak } from "@react-keycloak/web";
import Loader from "react-spinner-loader";
import {
  keycloakbaseurl,
  getRoleDataByRoleNameURL,
  getallRolesByRealm,
  getRoleById,
  deleteRole,
  getAllRole,
  deletRoleById,
  getAllPermissions,
} from "../../../../Services/EndPoints";
import { getData, putDataWithBody } from "../../../../Services/Services";
import Table from "../../../../Components/UiComponents/Table/Table";
import Moment from "react-moment";
import { Modal, Button } from "react-bootstrap";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { roleTableDatacolumnsForExport } from "../../../../JsonData/RoleDetails";
import { useDispatch } from "react-redux";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { useTranslation } from "react-i18next";

const RoleTable = forwardRef((props, ref) => {
  let roleDataArr = [];
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const [userdata, setUserdata] = useState([]);
  const qs = require("qs");
  const [roleData, setRoleData] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [isShow, invokeModal] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [permissionDataFrmService, setPermissionDataFrmService] = useState({});
  const { t, i18n } = useTranslation();
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  const addNewHandler = () => {
    navigate("/users/create-role");
  };
  useImperativeHandle(ref, () => ({
    refreshRoleTbl() {
      fetchAllRoles();
    },
  }));

  useEffect(() => {
    fetchAllRoles();
    getAllUserPermission();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  async function getAllUserPermission() {
    let permissionData = await getData(getAllPermissions);
    permissionData.forEach(function (permission) {
      permissionDataFrmService[permission.id] = permission.name;
    });
    setPermissionDataFrmService(permissionDataFrmService);
  }
  const getLoggedInUserAuthToken = async () => {
    return keycloak.token;
  };
  const getSuperAdminUserAuthToken = async () => {
    return process.env.REACT_APP_URM_SUPERADMIN_AUTH_TOKEN;
  };
  const showUserDeleteModal = (roleId) => {
    setSelectedRoleId(roleId);
    invokeModal(true);
  };
  const populatRoleDataFrEdt = async (roleData) => {
    // const userData = await getRoleDataByRoleId(roleId);
    props.invokePrntComponentRoleDataUpdteFun(roleData);
  };
  const hideModal = () => {
    invokeModal(false);
  };

  const getURMHeader = async () => {
    const authToknObj = await getSuperAdminUserAuthToken();
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToknObj,
    };
    return headers;
  };

  const deleteRoleByRoleId = async (id) => {
    /*let data = { id: id };
    var res = await putDataWithBody(deleteRole, data, {});
    if (res.status == "success") {
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setIsdelete(false);
      fetchAllRoles();
    }
    
   let url =
      keycloakbaseurl +
      deletRoleById + selectedRoleId
    const authToknObj = await getLoggedInUserAuthToken();
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToknObj,
    };
    const response = await api.deleteData(url, headers);

    if (response.status == 204) {
      fetchAllRoles();
      invokeModal(false);
    }*/
  };

  const getURMRoleIdByAppRoleID = (id) => {
    let roleURMId = null;
    roleDataArr.forEach(function (roleObj) {
      if (roleObj.id == id) {
        roleURMId = roleObj.urm_role_id;
      }
    });
    return roleURMId;
  };
  const fetchAllRoles = async () => {
    setLoader(true);
    let roleArrFiltered = [];
    let rolearr = await getData(getAllRole);
    rolearr.forEach(function (roleObj) {
      if (roleObj.is_deleted != true) {
        roleArrFiltered.push(roleObj);
      }
    });
    setLoader(false);
    roleDataArr = roleArrFiltered;
    let sortedList = roleArrFiltered.sort((a, b) => {
      return b.createdOn - a.createdOn;
    });
    setRoleData(sortedList);
    setRowCount(rolearr.length);
  };

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = roleTableDatacolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("roletableheaders.rolename"),
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: t("roletableheaders.description"),
        accessor: "description",
        disableSortBy: true,
      },

      {
        Header: t("roletableheaders.createdon"),
        accessor: "createdOn",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },

      {
        Header: t("roletableheaders.permission"),
        accessor: "role_permission",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value && props.value.length > 0) {
            let permissionStr = "";
            let all_permissions = props.value;
            all_permissions.forEach(function (permission) {
              permissionStr +=
                permissionDataFrmService[permission["permission_id"]] + ",";
            });
            let permissionText = permissionStr;
            if (permissionText.length > 15) {
              permissionText = permissionText.substring(0, 15) + "...";
            }
            return <span title={permissionStr}>{permissionText}</span>;
          } else {
            return "NA";
          }
        },
      },
      // {
      //   Header: "Status  ",
      //   accessor: "status",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     return <StatusHandler props={props} />;
      //   },
      // },
      {
        Header: t("roletableheaders.action"),
        accessor: "action",
        disableSortBy: true,

        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };

          const deleteRoleByRoleId = async (id) => {
            /*let roleIdInURM = getURMRoleIdByAppRoleID(id);
            let url =keycloakbaseurl +deletRoleById.replace("{REALM}", process.env.REACT_APP_URM_REALM) + roleIdInURM;
            const response = await api.deleteData(url, getURMHeader());
            if (response.status == 204) {
              alert("Role Deleted");
              /*let data = { id: id };
              var res = await putDataWithBody(deleteRole, data, {});
              if (res.status == "success") {
                toast.success(res.msg, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000, // Close after 2 seconds
                });
               setIsdelete(false);
                fetchAllRoles();
              }*/

            let data = { id: id };
            var res = await putDataWithBody(deleteRole, data, {});
            if (res.status == "success") {
              toast.success(res.msg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000, // Close after 2 seconds
              });
              setIsdelete(false);
              fetchAllRoles();
            }
          };

          return (
            <div className="usertable__actionsicon">
              <span
                onClick={() =>
                  navigate("/users/create-role", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }>
                <Editicon value={props.value} className="cursor-pointer me-2" />
              </span>

              <span onClick={deletePopUp}>
                <Deleteicon
                  value={props.value}
                  className="cursor-pointer mr-1"
                />
              </span>

              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  //   data={data}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.name}
                  deleteRow={deleteRoleByRoleId}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t]
  );
  return (
    <div className="container  create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {roleData && (
          <Table
            data={roleData}
            setSearchData={setSearchData}
            columns={columns}
            numberOfRows={7}
            addButtonLabel={t("createroleaddbuttonlabel")}
            addNewHandler={addNewHandler}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        )}
      </div>

      <Modal show={isShow}>
        <Modal.Header closeButton onClick={hideModal}>
          <Modal.Title>Alert !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete the role ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="delete_user_btn"
            onClick={hideModal}>
            Cancel
          </Button>
          <Button
            onClick={deleteRoleByRoleId}
            variant="dark"
            className="create_user_btn">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default RoleTable;
