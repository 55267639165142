import React, { useEffect } from "react";
import {
  AgencyManagement,
  Awareness,
  ConfigurationManagement,
  ContactManagement,
  DevicesIcon,
  EventsIcon,
  Execution,
  FacilityManagement,
  GisManagement,
  Home,
  MasterResourceManagement,
  MyTasks,
  PolicesIcon,
  RegionManagement,
  ReportsIcon,
  ResourceManagement,
  Resources,
  SopManagement,
  TaskManagement,
  TasksList,
  UserRoleManagement,
  UsersIcon,
} from "../Components/IconComponents";

const height = "1.2em";
const width = "1.2em";

const navLinks = [
  {
    to: "dist-dashboard",
    icon: <Resources width={width} height={height} />,
    label: "navlinklabels.dashboard",
    children: [
      {
        to: "affected-floodreport",
        label: "navlinklabels.dailyreport",
        children: [
          {
            to: "",
            icon: "",
            label: "Form 1 - Daily Report",
          },
          {
            to: "../partiallyaffected-floodreport",
            icon: "",
            label: "Partially Affected Report",
          },
          {
            to: "../dailyfloodreport",
            icon: "",
            label: "Flood Report Consolidated",
          },
          {
            to: "../reporting-status",
            icon: "",
            label: "Form 1 - Reporting Status",
          },
          {
            to: "../misdist-report",
            icon: "",
            label: "MIS District Flood Report",
          },
        ],
      },
      {
        to: "affected-report",
        label: "State Flood Report",
        children: [
          {
            to: "",
            icon: "",
            label: "Affected Report",
          },
          {
            to: "../partiallyaffected-report",
            icon: "",
            label: "Partially Affected Report",
          },
          {
            to: "../statefloodreport",
            icon: "",
            label: "Consolidated Report",
          },
          {
            to: "../misstate-report",
            icon: "",
            label: "MIS State Flood Report",
          },
          // {
          //   to: "../form9",
          //   icon: "",
          //   label: "Form 9",
          //   children: [],
          // },
        ],
      },
      {
        to: "incidentdashboard",
        icon: <TasksList width={width} height={height} />,
        label: "Incident Dashboard",
      },
      {
        to: "dmsdashboard",
        icon: <TasksList width={width} height={height} />,
        label: "navlinklabels.incidentdashboard",
      },
      {
        to: "animal-shelter",
        icon: <TasksList width={width} height={height} />,
        label: "navlinklabels.animalshelter",
      },
      {
        to: "community-kitchen",
        icon: <TasksList width={width} height={height} />,
        label: "navlinklabels.communitykitchen",
      },
      {
        to: "fl-camp",
        icon: <TasksList width={width} height={height} />,
        label: "navlinklabels.floodcamp",
      },
      /*{
      to: "daily-flood-report",
      icon: <TasksList width={width} height={height} />,
      label: "navlinklabels.dailyreport",
    },*/
      ,
    ],
  },
  {
    to: "my-tasks",
    icon: <MyTasks width={width} height={height} />,
    label: "navlinklabels.mytask",
    children: [
      {
        to: "my-tasks",
        icon: <TasksList width={width} height={height} />,
        label: "navlinklabels.tasklist",
      },
      {
        to: "view-usertask",
        icon: <ReportsIcon width={width} height={height} />,
        label: "navlinklabels.taskmgmnt",
      },
    ],
  },
  {
    icon: <ReportsIcon width={width} height={height} />,
    label: "navlinklabels.myforms",
  },
  {
    to: "masterdata-table",
    // icon: <ReportsIcon width={width} height={height} />,
    icon: <UsersIcon />,
    allowedPermissionIds: [1],
    label: "navlinklabels.adminstrativeactivities",
    children: [
      {
        to: "masterdata-table",
        icon: <ConfigurationManagement width={width} height={height} />,
        label: "navlinklabels.configmgmnt",
        allowedPermissionIds: [2],
        children: [],
      },
      {
        to: "../region-list",
        icon: <RegionManagement width={width} height={height} />,
        label: "navlinklabels.regionmgmnt",
        allowedPermissionIds: [6],
        children: [],
      },
      {
        to: "../agency-list",
        icon: <AgencyManagement width={width} height={height} />,
        label: "navlinklabels.agencymgmnt",
        children: [],
        allowedPermissionIds: [10],
      },
      {
        to: "../users",
        icon: <UserRoleManagement width={width} height={height} />,
        label: "navlinklabels.userrolemgmnt",
        allowedRoles: ["manage-users"],
        allowedPermissionIds: [37],
        children: [
          {
            to: "",
            icon: "",
            label: "navlinklabels.users",
            children: [],
          },

          {
            to: "roles",
            icon: "",
            label: "navlinklabels.roles",
            children: [],
          },
        ],
      },

      {
        to: "../master-resource",
        icon: <MasterResourceManagement width={width} height={height} />,
        label: "navlinklabels.masterresource",
        allowedPermissionIds: [18],
        children: [],
      },
      {
        to: "../reportbuilder",
        icon: <GisManagement width={width} height={height} />,
        label: "navlinklabels.dashboard/reportbuilder",
        allowedPermissionIds: [38],
        children: [],
      },
      {
        to: "../reportmanagement",
        icon: <MasterResourceManagement width={width} height={height} />,
        label: "navlinklabels.adhocformbuilder",
        allowedPermissionIds: [39],
        children: [],
      },
    ],
  },
  {
    to: "planning",
    icon: <DevicesIcon />,
    label: "navlinklabels.planning",
    allowedPermissionIds: [26],
    children: [
      {
        to: "view-contact",
        icon: <ContactManagement width={width} height={height} />,
        label: "navlinklabels.contactmgmnt",
        allowedPermissionIds: [22],
        children: [],
      },
      {
        to: "../facility-mgmt",
        icon: <FacilityManagement width={width} height={height} />,
        label: "navlinklabels.facilitymgmnt",
        allowedPermissionIds: [34],
        children: [],
      },
      {
        to: "../resource-list",
        icon: <Resources width={width} height={height} />,
        label: "navlinklabels.resourcemgmnt",
        allowedPermissionIds: [35],
        children: [],
      },
      {
        to: "view-sop",
        icon: <SopManagement width={width} height={height} />,
        label: "navlinklabels.sopmgmnt",
        allowedPermissionIds: [36],
        children: [
          {
            to: "../view-task",
            icon: <ReportsIcon width={width} height={height} />,
            label: "navlinklabels.task",
          },
          {
            to: "../../resource-list",
            icon: <ReportsIcon width={width} height={height} />,
            label: "navlinklabels.resource",
          },
        ],
      },
    ],
  },
  {
    allowedPermissionIds: [27],
    to: "gismanagement",
    icon: <Awareness width={width} height={height} />,
    label: "navlinklabels.situationalawarness/gis",
    children: [],
  },
  {
    allowedPermissionIds: [31],
    to: "response-relief",
    icon: <Resources width={width} height={height} />,
    label: "navlinklabels.response&relief",
    children: [],
  },
];
// return navLinks;
// };

export default navLinks;
