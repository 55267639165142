import { useState,useEffect } from "react";
//import { useState } from "react/cjs/react.development";
import TableRows from "./TableRows";
import "./TableRow.css";
const AddDeleteTableRows = ({ updateRowValues,rowValues}) => {
  const [rowsData, setRowsData] = useState([]);

  const addTableRows = () => {
    const rowsInput = {
      attributeName: "",
      attributeValue: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };


  useEffect(() => {
    if(rowValues.length == 0){
      setRowsData([{"attributeName":"Total Male","attributeValue":""}]);
    }
    else{
      setRowsData(rowValues);
    }
    
  },[rowValues]);


  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    updateRowValues(rows);
    // Also update the rowValues state
    //const newRowValues = [...rowValues];
    //newRowValues.splice(index, 1);
    //updateRowValues(newRowValues);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
    updateRowValues(rowsInput);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <table className="table dyna_tbl">
            <thead>
              <tr>
                <th style={{ color: "white" }}><label>Name</label></th>
                <th style={{ color: "white" }}><label>Value</label></th>

                <th>
                  <input type="button"
                    className="btn btn-outline-success"
                    onClick={addTableRows}
                    value={"+"}
                  />
                  
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
              />
            </tbody>
          </table>
        </div>
        <div className="col-sm-4"></div>
      </div>
    </div>
  );
};
export default AddDeleteTableRows;
