import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { toast, ToastContainer } from "react-toastify";
import Moment from "react-moment";
import Table from "../../../../Components/UiComponents/Table/Table";
import { Viewicon } from "../../../../Components/IconComponents";
import { useKeycloak } from "@react-keycloak/web";
import Loader from "react-spinner-loader";
import { getData } from "../../../../Services/Services";
import {
  getDailyReportListURL
} from "../../../../Services/EndPoints"
import { filterDate, getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { columnsForExport } from "../../../../JsonData/DailyFloodReportConfig";
import { useTranslation } from "react-i18next";

const DailyFloodReportTable = ({ selectedValues }) => {
   console.log("selectedValues in the report table=", selectedValues);
  const [dailyReportTableData, setDailyReportTableData] =useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const [rowCount, setRowCount] = useState(0);
   const dispatch = useDispatch();
  useEffect(() => {
    getDailyReportData();
  }, []);

  const sendDataToCentralStore = (data) => {
    console.log("ckdata=", data);
    if (data.length > 0) {
      console.log("ckdata=", data);
      const columns = columnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  //   const getAnimalShelterList = async () => {
  //     var animalShelterList = await postDataWithBody(
  //       getAnimalShelterListURL,
  //       { user_name: getLoggedInuserName() },
  //       {}
  //     );
  //     setRowCount(animalShelterList.length);
  //     setAnimalShelterData(animalShelterList);
  //   };

  const getDailyReportData = async () => {
    setLoader(true);
    var dailyReportList = await getData(getDailyReportListURL);
    setLoader(false);
    setDailyReportTableData(dailyReportList);
    sendDataToCentralStore(dailyReportList);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('dailyfloodtableheaders.slno'),
        accessor: "sno",
        disableSortBy: true,
        Cell: (props) => <span>{props.row.index + 1}</span>,
      },
      {
        Header: t('dailyfloodtableheaders.incident'),
        accessor: "incident_name",
        disableSortBy: true,
      },
      {
        Header: t('dailyfloodtableheaders.district'),
        accessor: "dist_name",
        disableSortBy: true,
      },
      {
        Header: t('dailyfloodtableheaders.block'),
        accessor: "block_name",
        disableSortBy: true,
      },
     
      {
        Header: t('dailyfloodtableheaders.createdby'),
        accessor: "created_by",
        disableSortBy: true,
      },
      {
        Header: t('dailyfloodtableheaders.createdon'),
        accessor: "created_on_dt",
        disableSortBy: true,
      },
      {
        Header: t('dailyfloodtableheaders.reportedon'),
        accessor: "reported_dt",
        disableSortBy: true,
      },

      {
        Header: t('dailyfloodtableheaders.action'),
        disableSortBy: true,
        Cell: (props) => {
          return (
            <div>
              <span className="icon__position text-center">
                <span
                  onClick={(s) =>
                    navigate("/dmsdashboard/view-daily-flood-report", {
                      state: {
                        id: props.row.original.id,
                      },
                    })
                  }
                  className="cursor-pointer ms-3"
                >
                  <Viewicon value={props.value} />
                </span>
              </span>
            </div>
          );
        },
      },
    ],
    [t]
  );
  return (
    <div>
      <div className="container  create-user">
        <div className="datatable__container">
          {dailyReportTableData && (
            <Table
              data={dailyReportTableData}
              columns={columns}
              rowCount={rowCount}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              //   rowCount={rowCount} // Pass rowCount to the Table component
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DailyFloodReportTable;
