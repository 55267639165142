import React, { useState } from 'react';
import './ToggleView.css';

export default function ToggleView({ activeTab, setActiveTab }) {

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);

    };

    return (
        <div className="toggle-switch-container">
            <div
                className={`toggle-item ${activeTab === 'bar' ? 'active' : ''}`}
                onClick={() => handleTabClick('bar')}
            >
                Bar Chart
            </div>
            <div
                className={`toggle-item ${activeTab === 'trendChart' ? 'active' : ''}`}
                onClick={() => handleTabClick('trendChart')}
            >
                Trend Chart
            </div>
        </div>
    );
}
