import React, { useEffect, useRef,useState } from "react";
import { useLocation } from "react-router-dom";
import { GridStack } from "gridstack";
import GridItem from "../../../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../../../DMSHeader/DMSHeader";
import AffectedAreaReportTable from "./AffectedAreaReportTable/AffectedAreaReportTable";
import AffectedPopulation from "./AffectedPopulation/AffectedPopulation";
import PropertyDamage from "./PropertyDamage/PropertyDamage";
import FishingAndBoats from "./FishingAndBoats/FishingAndBoats";
import ReliefActivity from "./ReliefActivity/ReliefActivity";
import { getData,postDataWithBody } from "../../../../../Services/Services";
import {
  getDailyReportbyId,
  findRegionsInfoById,
  getConfigsByCategoryName
} from "../../../../../Services/EndPoints"
import { useTranslation } from "react-i18next";
const ViewDailyFloodReport = (props) => {
  const { state } = useLocation();
  let dailyReportId = state?.id;
  const [affectedAreaReportTableData, setAffectedAreaReportTableData] = useState([]);
  const [affectedPopulationData, setAffectedPopulationData] = useState({});
  const [propertyDamageData, setPropertyDamageData] = useState({});
  const [fishingAndBoatsData, setFishingAndBoatsData] = useState({});
  const { t, i18n } = useTranslation();
  const [reliefActivityData, setReliefActivityData] = useState({});
  useEffect(() => {
    getDailyReportId();
    GridStack.init();
  }, []);

  let dataMap = {};
  let attachmentMap = {};

  const getdataOnBasisOfGroupIndex= async(dailyReportData) => {
    let villageIdArr = [];
    let pointOfViewId = [];
    let dataMapByGroupIndex = {};
    dailyReportData.forEach(function(data,index){
      if(data.group_index+"" && data.group_index+"" != "null"){
        if(data.attribute_code == 'AAR_VILLAGE_ID'){
          villageIdArr.push(data.daily_report_attribute_value);
        }
        if(data.attribute_code == 'AAR_POINT_OF_VIW_ID'){
          pointOfViewId.push(data.daily_report_attribute_value);
        }
        if(dataMapByGroupIndex[data.category_code+'_'+data.group_index]){
          dataMapByGroupIndex[data.category_code+'_'+data.group_index].push(data);
        }
        else{
          dataMapByGroupIndex[data.category_code+'_'+data.group_index] = {};
          dataMapByGroupIndex[data.category_code+'_'+data.group_index] = [data]
        }
      }
    });
    let regionListMap = await getRegionInfoByRegionIds(villageIdArr);
    let pointOfViewListMap = await getPointOfViewInfoByIds();


    var sanitizedArr = [];
    Object.keys(dataMapByGroupIndex).forEach(function(key) {
      let tempArr = dataMapByGroupIndex[key];
      let rowObj = {};
      tempArr.forEach(function(data,index){
        if(data.attribute_code == 'AAR_VILLAGE_ID'){
          rowObj['village_name'] = regionListMap[data.daily_report_attribute_value];
        }
        else if(data.attribute_code == 'AAR_TOTAL_AREA_AFFECTED'){
          rowObj['total_area_affected'] = data.daily_report_attribute_value;
        }
        else if(data.attribute_code == 'AAR_POINT_OF_VIW_ID'){
          rowObj['point_of_view'] = pointOfViewListMap[data.daily_report_attribute_value];
        }
      });
      sanitizedArr.push(rowObj);
    });
    return sanitizedArr;
  }
  const getDailyReportId = async() => {
    var dailyReportData = await getData(getDailyReportbyId+dailyReportId);
    dailyReportData.forEach(function(data,index){
      if(dataMap[data.category_code]){
        let tempData = dataMap[data.category_code];
        tempData[data.attribute_code] = data.daily_report_attribute_value;
      }
      else{
        dataMap[data.category_code] = {};
        dataMap[data.category_code][data.attribute_code] = data.daily_report_attribute_value;
      }
      dataMap[data.category_code]["attachment_path"] = data.attachment_path;
      
    });
    setReliefActivityData(dataMap['RAR']);
    setFishingAndBoatsData(dataMap['FBR']);
    setPropertyDamageData(dataMap['PDR']);
    setAffectedPopulationData(dataMap['PAR']);
    setAffectedAreaReportTableData(await getdataOnBasisOfGroupIndex(dailyReportData));
  };

  const getRegionInfoByRegionIds = async(regionIdArr) => {
    let regionInfoMap = {};
    var regionInfo = await postDataWithBody(findRegionsInfoById,regionIdArr,{});
    regionInfo.forEach(function(data,node){
      regionInfoMap[data.id] = data.name;
    })
    return regionInfoMap;
  };

  const getPointOfViewInfoByIds = async() => {
    var pointOfViewMapList = await getData(getConfigsByCategoryName +'AR_PT_VIEW');
    let pointOfViewMap = {};
    pointOfViewMapList.forEach(function(data,node){
      pointOfViewMap[data.id] = data.value;
    })
    return pointOfViewMap;
  };

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader title={t('daily_flood_header')} showIconsForback={true} />
      ),
      haveOverflowVisible: true,
    },

    {
      x: 0,
      y: 1,
      width: 12,
      height: 5,
      id: 2,
      component: <AffectedAreaReportTable data={affectedAreaReportTableData}/>,
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 6,
      width: 12,
      height: 6,
      id: 3,
      component: <AffectedPopulation data={affectedPopulationData}/>,
    },
    {
      x: 0,
      y: 12,
      width: 12,
      height:6,
      id: 3,
      component: <PropertyDamage data={propertyDamageData}/>,
    },
    {
      x: 0,
      y: 18,
      width: 12,
      height: 5,
      id: 3,
      component: <FishingAndBoats data={fishingAndBoatsData}/>,
    },
    {
      x: 0,
      y: 23,
      width: 12,
      height: 5,
      id: 3,
      component: <ReliefActivity data={reliefActivityData}/>,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default ViewDailyFloodReport;
