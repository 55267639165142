import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import { ReactFormBuilder, FormElementsEdit } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { toast, ToastContainer } from "react-toastify";
import './form_builder.css';
import { ElementStore } from "react-form-builder2";
import {
  postDataWithBody
} from "../../../Services/Services";
import {
  createFormStructure
} from "../../../Services/EndPoints";
import { useTranslation } from 'react-i18next';
const FormBuilder = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [formName, setFormName] = useState(null);
  const [formIsReportable, setFormIsReportable] = useState(false);
  const [formDescription, setFormDescription] = useState(null);
  const { t } = useTranslation();
  ElementStore.subscribe((state) => {
    setFormData(state.data);
  });

  const translatedLabels = {
  headerText: t('formBuilder.label.headertext'),
  label: t('formBuilder.label.label'),
  }




  const handleChange = event => {
    if (event.target.checked) {
      setFormIsReportable(true);
    } else {
      setFormIsReportable(false);
    }
  };
  // function handleUpdate() {
  //   console.log("sadadsf")
  // }
  const onPostHandler = async () => {
    if (formName == null || formName == "") {
      toast.error("Please enter form name.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
    else {
      var input = { "form_name": formName, "structure": formData, "description": formDescription, "reportable": formIsReportable };
      let headers = { "content-type": "application/json" };
      let res = await postDataWithBody(createFormStructure, JSON.stringify(input), headers);

      if (res.status == 'success') {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        navigate("../reportmanagement");
      }
      else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
      }

    }

  };

  function setFormNameData(event) {
    setFormName(event.target.value);
  }
  function setFormDescriptionData(event) {
    setFormDescription(event.target.value);
  }


  function submitFormData() {
    return;
  }
  return (
    <div className="container ">
      <div className="row">
        <div className="col-sm-3 input_text-form">
          <label className="">{t('adhocformlabels.name')}</label>
          <div>
          <input onChange={setFormNameData} type="text" placeholder={t('adhocformlabels.enterformname')}className='form-control mb-3 form__input'/>
          </div>

        </div>
        <div className="col-sm-3 input_text-form">
          <label className="">{t('adhocformlabels.description')}</label>
          <div>
          <input onChange={setFormDescriptionData} type="text" placeholder={t('adhocformlabels.description')} className='form-control mb-3 form__input'/>
          </div>

        </div>

        <div className="col-sm-3 mt-2">
          <label></label>
          <div>
            <input value={formIsReportable}
              onChange={handleChange}

              type="checkbox" /><span className="ms-2">{t('adhocformlabels.useasreport')}</span></div>
        </div>
      </div>
      <div className="row">
      <ReactFormBuilder className="row" 
      edit
      showCorrectColumn = {true}
      saveAlways={false}
      onPost={submitFormData}
     


        />
      </div>
      <div className="container row">
        <div className="col-sm-6">
        <button className="btn btn-success btn-sm add_config mt-2" onClick={onPostHandler}>{t('adhocformlabels.submit')}</button>

        </div>

      </div>

    </div>
  )
}

export default FormBuilder