import React, { useEffect, useState, useRef, useContext } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../..";

const CommunityKitchenTrendView = (props) => {
  const echartsDom2 = useRef(null);
  const { theme } = useContext(ThemeContext);
  const [chart, setChart] = useState(null);
  const { t } = useTranslation();
  let totalmax = 0;
  const data = {
    facility_type_name: "Community Kitchen",
    open_count: 67,
    increase_prcntage: "-12%",
    attributes: [
      {
        attribute_name: t("communitykitchentableheaders.lunch"),
        attribute_value: props.dataval ? props.dataval["Lunch"] : 0,
      },
      {
        attribute_name: t("communitykitchentableheaders.breakfast"),
        attribute_value: props.dataval ? props.dataval["Breakfast"] : 0,
      },
      {
        attribute_name: t("communitykitchentableheaders.dinner"),
        attribute_value: props.dataval ? props.dataval["Dinner"] : 0,
      },
    ],
  };

  const getLegendColor = (theme) => {
    return theme === "light" ? "#2B6591" : "#fff";
  };
  const options = {
    tooltip: {
      trigger: "item",
      confine: true,
    },
    legend: {
      // orient: "vertical",
      right: "0%", // Align the legend to the right
      bottom: "0",
      selectedMode: false,
      textStyle: {
        color: getLegendColor(theme), // Legend text color
      },
    },
    xAxis: {
      type: "category",
      data: ["Men", "Women", "Kids", "Transgender"], // X-axis labels based on data
      axisLine: {
        lineStyle: {
          color: getLegendColor(theme), // X-axis line color
        },
      },
      axisTick: {
        show: false, // Remove ticks if unnecessary
      },
      splitLine: {
        show: false, // Remove split lines
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: getLegendColor(theme), // Y-axis line color
        },
      },
      splitLine: {
        show: false, // Remove split lines
      },
      min: 0, // Minimum value for the y-axis
    },
    series: [
      {
        name: "Men",
        data: [10, 0, 0, 0], // Men data for the first category
        type: "bar",
        itemStyle: {
          color: "#519EFE",
          barBorderRadius: [10, 10, 0, 0],
        },
        barWidth: "20",
      },
      {
        name: "Women",
        data: [0, 20, 0, 0], // Women data for the second category
        type: "bar",
        itemStyle: {
          color: "#B981F6",
          barBorderRadius: [10, 10, 0, 0],
        },
        barWidth: "20",
      },
      {
        name: "Kids",
        data: [0, 0, 20, 0], // Kids data for the third category
        type: "bar",
        itemStyle: {
          color: "#E28771",
          barBorderRadius: [10, 10, 0, 0],
        },
        barWidth: "20",
      },
      {
        name: "Transgender",
        data: [0, 0, 0, 20], // Transgender data for the fourth category
        type: "bar",
        itemStyle: {
          color: "#D252AA",
          barBorderRadius: [10, 10, 0, 0],
        },
        barWidth: "20",
      },
    ],
    grid: {
      left: "5%", // Adjust as needed to fit the layout
      bottom: "25%",
      top: "5%",
      right: "5%", // To provide some spacing on the right
    },
  };
  const option = {
    xAxis: {
      type: "category",
      data: ["Men", "Women", "Kids", "Transgender"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [120, 200, 150, 80],
        type: "bar",
      },
    ],
  };

  useEffect(() => {
    const myChart = echarts.init(echartsDom2.current);

    myChart.setOption(options);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [props.dataval, t, theme]);

  return (
    <div class="card" style={{ width: "100%", height: "95%" }}>
      <div class="card-body" style={{ height: "100%" }}>
        <div class="card-title">
          {t("navlinklabels.communitykitchen")}
          {/* <span className="float-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots-vertical"
              viewBox="0 0 16 16">
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
          </span> */}
        </div>
        <div ref={echartsDom2} style={{ height: "100%", width: "100%" }} />
      </div>
    </div>
  );
};
export default CommunityKitchenTrendView;
