import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import { toast, ToastContainer } from "react-toastify";
import {
  schema,
  createFacilityFormConfig,
} from "../../../../JsonData/CreateFacilityConfig";
import {
  postDataWithBody,
  getData,
  putDataWithBody,
  postDataWithBodyFrGIS,
  postDataWithBodyRetErr,
} from "../../../../Services/Services";
import { useTranslation } from "react-i18next";
import {
  createFacility,
  getContacts,
  getAllContacts,
  getFacilityTypeURL,
  updateFacility,
  updategisFacilityEntry,
  creategisFacilityEntry,
  findFacilityById,
  getRestrictedRegionListURL,
} from "../../../../Services/EndPoints";
import "./CreateFacilityRegForm.css";
import Modal from "react-bootstrap/Modal";
import Loader from "react-spinner-loader";
import MapWrapper from "../../../../Components/UiComponents/MapWrapper/MapWrapper";
import { XCircleblack } from "../../../../Components/IconComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import LocationAutoComplete from "./LocationAutoComplete";
import lmsToast from "../../Common/CustomToastifier/CustomToastifier";

const CreateFacilityRegForm = () => {
  const [contactList, setContctList] = useState([]);
  const [coordinates1, setCOOrdinates] = useState([
    {
      y: 85.56225740541943,
      x: 25.74755419155397,
    },
  ]);
  const [mapCenterCoordinates, setMapCenterCoordinates] = useState(null);

  const [facilitygisId, setFacilitygisId] = useState(null);
  const [facilityTypeList, setFacilityTypeList] = useState([]);
  const [facilityConfig, setFacilityConfig] = useState(
    createFacilityFormConfig
  );
  const { state } = useLocation();
  let regionList = [];
  let id = state?.data;

  const [lgShow, setLgShow] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [zoomLevel, setZoomLevel] = useState(8);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [loader, setLoader] = useState(false);
  const [mapKey, setMapKey] = useState(0);
  const chooseCoordinates = (longitude, latitude) => {
    setLongitude(longitude);
    setValue("latitude", latitude);
    setValue("longitude", longitude);
    setLatitude(latitude);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,

    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });

  useEffect(() => {
    setValue("latitude", latitude);
    setValue("longitude", longitude);
    if (longitude && latitude) {
      updateCoordinates(latitude, longitude);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    getInitFormData();
  }, []);
  const getInitFormData = async () => {
    await getFacilityTypes();
    await getRestrictedRegionList();
    await getContactList();
  };

  async function populateFacilityFormFrEdit(contactList, id) {
    setLoader(true);
    var facilitydata = await postDataWithBody(findFacilityById, [id], {});
    facilitydata = facilitydata[0];
    setFacilitygisId(facilitydata.gis_id);
    reset(facilitydata);
    // let contactArr = [];
    let contactIdNameMap = {};
    contactList.forEach((contact, index) => {
      contactIdNameMap[contact.id] = {
        name: contact.name,
        job_title: contact.job_title,
        phone1: contact.phone1,
      };
    });

    let contactArr = facilitydata.facility_contact_map.map((contact) => ({
      label: `${contactIdNameMap[contact.contact_id].name}, ${
        contactIdNameMap[contact.contact_id].job_title
      }, (${contactIdNameMap[contact.contact_id].phone1})`,
      value: contact.contact_id,
      name: contactIdNameMap[contact.contact_id].name,
      job_title: contactIdNameMap[contact.contact_id].job_title,
      phone1: contactIdNameMap[contact.contact_id].phone1,
    }));
    setValue("contact_list", contactArr);
    setTimeout(function () {
      regionList.forEach(function (data, elem) {
        if (data.value == facilitydata.region_id) {
          setValue("region_id", { label: data.label, value: data.value });
        }
      });
    }, 500);

    setLoader(false);
  }
  async function populateFacilityTypeFrEdit(facilityList, id) {
    setLoader(true);
    var facilitydata = await postDataWithBody(findFacilityById, [id], {});
    facilitydata = facilitydata[0];
    setFacilitygisId(facilitydata.gis_id);
    reset(facilitydata);
    let facilityArr = [];
    let facilityIdNameMap = {};
    facilityList.forEach((facility, index) => {
      facilityIdNameMap[facility.id] = facility?.label;
    });
    facilitydata.facility_type_map.forEach((facility, index) => {
      facilityArr.push({
        label: facilityIdNameMap[facility["facility_type_id"]],
        value: facility["facility_type_id"],
      });
    });
    setValue("facility_type", facilityArr);
    setLoader(false);
  }

  const GenerateForm = (fields, register, errors, control, is_multi) => {
    return (
      <div>
        {contactList.length > 0 && facilityTypeList.length > 0 && (
          <FormHandler
            register={register}
            errors={errors}
            fields={fields}
            control={control}
            columns={2}
            is_multi={is_multi}
            getValues={getValues}
          />
        )}
      </div>
    );
  };
  const updateCoordinates = (x, y) => {
    let tempCoordinates = coordinates1;
    tempCoordinates[0].x = x;
    setValue("latitude", x);
    setValue("longitude", y);
    tempCoordinates[0].y = y;
    //setCoordinates(tempCoordinates);
  };

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  function updateMapLocation(lat, lng) {
    setCOOrdinates([{ y: lng, x: lat }]);
    setValue("latitude", lat);
    setValue("longitude", lng);
    setMapCenterCoordinates({
      y: lng,
      x: lat,
    });
    setZoomLevel(16);
    setMapKey((prevKey) => prevKey + 1);
  }
  async function getContactList() {
    setLoader(true);
    var contactList = await getData(getAllContacts);
    contactList.forEach((contact) => {
      contact[
        "label"
      ] = `${contact["name"]}, ${contact["job_title"]},(${contact["phone1"]}) `;
      contact["value"] = contact["id"];
    });
    createFacilityFormConfig[0].fields.find(
      (field) => field.name === "contact_list"
    ).options = contactList;
    setFacilityConfig([...createFacilityFormConfig]);
    setContctList(contactList);
    if (id) {
      populateFacilityFormFrEdit(contactList, id);
    }
    setLoader(false);
  }
  async function getRestrictedRegionList() {
    var regionListArr = await getData(getRestrictedRegionListURL);
    regionListArr.forEach(function (data, index) {
      data.label += "   (District : " + data.district + ")";
    });
    facilityConfig[0].fields[7]["options"] = regionListArr;
    setFacilityConfig(facilityConfig);
    regionList = regionListArr;
  }
  async function getFacilityTypes() {
    setLoader(true);
    var facilityList = await getData(getFacilityTypeURL);
    for (let facility in facilityList) {
      facilityList[facility]["label"] = facilityList[facility]["value"];
      facilityList[facility]["value"] = facilityList[facility]["id"];
    }
    facilityConfig[0].fields[1]["options"] = facilityList;
    setFacilityConfig(facilityConfig);
    setFacilityTypeList(facilityList);

    if (id) {
      populateFacilityTypeFrEdit(facilityList, id);
    }
    setLoader(false);
  }
  const submitHandler = async (data, e) => {
    e.preventDefault();
    data.status = true;
    // setLoader(true);
    let facility_contact_map = [];
    for (let contact in data.contact_list) {
      facility_contact_map.push({
        contact_id: data.contact_list[contact].value,
      });
    }
    data["facility_contact_map"] = facility_contact_map;

    let facility_type_map = [];
    facility_type_map.push({
      facility_type_id: data.facility_type.id,
    });

    data["facility_type_map"] = facility_type_map;
    var gisData = {
      geometry: { coordinates: [data.longitude, data.latitude] },
      isactive: true,
    };
    data["region_id"] = data["region_id"]["value"];
    if (id) {
      data["gis_id"] = facilitygisId;
      delete data["created_on"];
      delete data["updated_on"];
      data["id"] = id;
      gisData["id"] = data["gis_id"];
      var res = await putDataWithBody(updateFacility, data, {});
      var gisres = await postDataWithBodyFrGIS(
        updategisFacilityEntry + "/" + facilitygisId,
        gisData,
        {}
      );
      lmsToast({
        toasterType: "success",
        msg: "Facility Updated Successfully",
      });
      navigate("../facility-mgmt");
    } else {
      var gisres = await postDataWithBodyFrGIS(
        creategisFacilityEntry,
        gisData,
        {}
      );
      data["gis_id"] = gisres.id;
      await postDataWithBody(createFacility, data, {}).then((res) => {
        if (res.status == "success") {
          lmsToast({
            toasterType: "success",
            msg: "Facility Created Successfully",
          });
          navigate("../facility-mgmt");
        } else {
          lmsToast({
            toasterType: "error",
            msg: "Facility with the same name already exists",
          });
        }
      });
    }
    setLoader(false);
  };

  const openMapHandler = () => {
    setLgShow(true); // Set the state to true to show the modal
  };

  const closeMapHandler = () => {
    setLgShow(false); // Set the state to false to hide the modal
  };
  const tabsData = generateTabsData(facilityConfig);
  return (
    <div className="mt-4 facility-container">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />

      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="CreateFacilityRegForm">
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                      <div className="position-relative">
                        <span
                          className="facility_maplink--position"
                          onClick={openMapHandler}>
                          {t("locateonmap")}
                        </span>
                      </div>
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                  {/* Save & Submit */}
                  {t("saveAndSubmit")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      {lgShow && (
        <Modal
          className="modal-position"
          size="lg"
          show={lgShow}
          onHide={closeMapHandler}
          aria-labelledby="example-modal-sizes-title-lg"
          right>
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              {t("chooselocation")}
            </Modal.Title>
            <XCircleblack
              className="cursor-pointer"
              onClick={closeMapHandler}
              width="1.5em"
              height="1.5em"
            />
          </Modal.Header>
          <Modal.Body>
            <div className="gis-mapcontainer">
              <LocationAutoComplete updateMapLocation={updateMapLocation} />

              <div key={mapKey} className="assetFormMap">
                <MapWrapper
                  headerStyles={{ display: "none" }}
                  coordinates={coordinates1}
                  chooseCoordinates={chooseCoordinates}
                  zoom={zoomLevel}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default CreateFacilityRegForm;
