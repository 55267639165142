import "./RegionTree.css";
import React from "react";
import Tree from "rc-tree";
import myImage from "./region.svg";
import RegionNode from "./RegionNode";
import { XCircleblack } from "../../../../../Components/IconComponents";
import RegionRegistrationForm from "../../CreateRegion/RegionRegistrationForm";
import { getChildRegionsById } from "../../../../../Services/EndPoints";
import { postDataWithBody } from "../../../../../Services/Services";
import Modal from "react-bootstrap/Modal";
async function generateTreeNodes(treeNode) {
  const arr = [];
  let keySantized = null;
  let key = treeNode.props.eventKey;
  let isLastNode = false;
  if (key.indexOf("-") > -1) {
    keySantized = key.split("-");
    if (keySantized.length == 4) {
      isLastNode = true;
    }
    keySantized = keySantized[keySantized.length - 1];
  } else {
    keySantized = key;
  }
  var rgnList = await postDataWithBody(getChildRegionsById, [keySantized], {});
  rgnList.forEach(function (region) {
    let keyVal = `${key}-${region.id}`;
    arr.push({
      title: (
        <RegionNode
          regionTypeId={region.region_type_id}
          isLastNode={isLastNode}
          keyVal={keyVal}
          selectedId={selectedId}
          id={region.id}
          text={region.name}
        />
      ),
      key: `${key}-${region.id}`,
      isLeaf: isLastNode,
    });
  });

  return arr;
}
function selectedId(idval, type, keyVal, regionTypeId) {
  window.regionTreeComponent.showModalPopup(idval, type, keyVal, regionTypeId);
}
function setLeaf(treeData, curKey, level) {
  const loopLeaf = (data, lev) => {
    const l = lev - 1;
    data.forEach((item) => {
      if (
        item.key.length > curKey.length
          ? item.key.indexOf(curKey) !== 0
          : curKey.indexOf(item.key) !== 0
      ) {
        return;
      }
      if (item.children) {
        loopLeaf(item.children, l);
      } else if (l < 1) {
        // eslint-disable-next-line no-param-reassign
        item.isLeaf = true;
      }
    });
  };
  loopLeaf(treeData, level + 1);
}

function getNewTreeData(treeData, curKey, child, level) {
  const loop = (data) => {
    if (level < 1) return;
    data.forEach((item) => {
      if (curKey.indexOf(item.key) === 0) {
        if (item.children) {
          loop(item.children);
        } else {
          // eslint-disable-next-line no-param-reassign
          item.children = child;
        }
      }
    });
  };
  loop(treeData);
  setLeaf(treeData, curKey, level);
}

class RegionTree extends React.Component {
  state = {
    treeData: [],
    checkedKeys: [],
    showPopup: false,
    regionTypeId: null,
    expandedKeys: [52],
  };
  constructor() {
    super();
    window.regionTreeComponent = this;
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        treeData: [{ title: "Bihar", key: "52" }],
      });
    }, 100);
  }

  onSelect = (info) => {
    console.log("selected", info);
  };

  showModalPopup = (id, type, keyVal, regionTypeId) => {
    let actionTypeStr =
      type == "EDIT" ? "Edit Region Information" : "Create Region";
    this.setState({
      regionTypeId: regionTypeId,
      showPopup: true,
      actionType: type,
      keyVal: keyVal,
      actionTypeHeader: actionTypeStr,
      id: id,
    });
  };
  hideModalPopup = () => {
    this.setState({
      showPopup: false,
    });
  };
  onCheck = (checkedKeys) => {
    console.log(checkedKeys);
    this.setState({
      checkedKeys,
    });
  };

  onLoadData = (treeNode) => {
    console.log("load data...");
    return new Promise(async (resolve, reject) => {
      const treeData = [...this.state.treeData];
      let regionList = await generateTreeNodes(treeNode);
      getNewTreeData(treeData, treeNode.props.eventKey, regionList, 7);
      this.setState({ treeData });
      resolve();
    });
  };

  render() {
    const { treeData } = this.state;

    return (
      <div class=" tree_container mt-2 d-flex">
        <div class="col-sm-4 tree_div">
          <Tree
            onSelect={this.onSelect}
            onCheck={this.onCheck}
            loadData={this.onLoadData}
            treeData={treeData}
          />
        </div>
        <div className="image_div">
          <img src={myImage} />
        </div>

        {this.state.showPopup == true && (
          <Modal
            show={this.state.showPopup}
            size="lg"
            className="table__modal__popup"
            centered>
            <Modal.Header>
              <Modal.Title>{this.state.actionTypeHeader}</Modal.Title>
              <XCircleblack
                className="cursor-pointer"
                onClick={window.regionTreeComponent.hideModalPopup}
                width="1.5em"
                height="1.5em"
              />
            </Modal.Header>
            <Modal.Body>
              <div>
                <RegionRegistrationForm
                  regionTypeId={this.state.regionTypeId}
                  id={this.state.id}
                  keyVal={this.state.keyVal}
                  actionType={this.state.actionType}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/* <button
              //   onClick={saveConfigData}
              type="button"
              className="btn btn-success"
              style={{ backgroundColor: "#40B2B7" }}
            >
              Save changes
            </button> */}
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}

export default RegionTree;
