import * as React from "react";
const SvgComunityKitchenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 47 45"
    {...props}
  >
    <mask
      id="ComunityKitchenIcon_svg__a"
      width={47}
      height={45}
      x={0}
      y={0}
      fill="#000"
      maskUnits="userSpaceOnUse"
    >
      <path fill="currentColor" d="M0 0h47v45H0z" />
      <path d="M9.42 1a.936.936 0 0 0-.935.935v3.257L2.327 9.586a.935.935 0 0 0-.391.762v3.739a.934.934 0 0 0 .935.935h20.584a.936.936 0 0 0 .936-.935v-3.74a.934.934 0 0 0-.391-.76L17.84 5.193v-3.26A.935.935 0 0 0 16.906 1H9.42Zm.936 1.87h5.614v2.804a.934.934 0 0 0 .391.761l6.159 4.393v2.324H3.807v-2.324l6.158-4.393a.935.935 0 0 0 .391-.761V2.87Zm-4.21 17.76c-.612 0-1.125.391-1.318.935H1.936A.936.936 0 0 0 1 22.5v20.565a.935.935 0 0 0 .936.935h43.128a.936.936 0 0 0 .936-.935V22.5a.934.934 0 0 0-.936-.935H21.498a1.396 1.396 0 0 0-1.318-.935H6.146Zm-3.275 2.805h20.674V42.13H2.87V23.435Zm22.545 0h18.713v5.609H25.416v-5.61Zm-19.738 1.87a.937.937 0 0 0-.935.934v13.087a.934.934 0 0 0 .935.935h14.97a.937.937 0 0 0 .936-.935V26.24a.934.934 0 0 0-.936-.935H5.678Zm23.391 0a.938.938 0 0 0-.949.934.933.933 0 0 0 .949.935h11.228a.936.936 0 1 0 0-1.87H29.069ZM6.614 27.173h13.099V38.39h-13.1V27.174Zm18.802 3.739h18.713V42.13H25.416V30.913Zm3.638 2.792a.936.936 0 0 0-.92.947v4.674a.934.934 0 0 0 1.296.876.938.938 0 0 0 .575-.876v-4.674a.934.934 0 0 0-.95-.947Z" />
    </mask>
    <path
      fill="currentColor"
      d="M9.42 1a.936.936 0 0 0-.935.935v3.257L2.327 9.586a.935.935 0 0 0-.391.762v3.739a.934.934 0 0 0 .935.935h20.584a.936.936 0 0 0 .936-.935v-3.74a.934.934 0 0 0-.391-.76L17.84 5.193v-3.26A.935.935 0 0 0 16.906 1H9.42Zm.936 1.87h5.614v2.804a.934.934 0 0 0 .391.761l6.159 4.393v2.324H3.807v-2.324l6.158-4.393a.935.935 0 0 0 .391-.761V2.87Zm-4.21 17.76c-.612 0-1.125.391-1.318.935H1.936A.936.936 0 0 0 1 22.5v20.565a.935.935 0 0 0 .936.935h43.128a.936.936 0 0 0 .936-.935V22.5a.934.934 0 0 0-.936-.935H21.498a1.396 1.396 0 0 0-1.318-.935H6.146Zm-3.275 2.805h20.674V42.13H2.87V23.435Zm22.545 0h18.713v5.609H25.416v-5.61Zm-19.738 1.87a.937.937 0 0 0-.935.934v13.087a.934.934 0 0 0 .935.935h14.97a.937.937 0 0 0 .936-.935V26.24a.934.934 0 0 0-.936-.935H5.678Zm23.391 0a.938.938 0 0 0-.949.934.933.933 0 0 0 .949.935h11.228a.936.936 0 1 0 0-1.87H29.069ZM6.614 27.173h13.099V38.39h-13.1V27.174Zm18.802 3.739h18.713V42.13H25.416V30.913Zm3.638 2.792a.936.936 0 0 0-.92.947v4.674a.934.934 0 0 0 1.296.876.938.938 0 0 0 .575-.876v-4.674a.934.934 0 0 0-.95-.947Z"
    />
    <path
      stroke="currentColor"
      d="M9.42 1a.936.936 0 0 0-.935.935v3.257L2.327 9.586a.935.935 0 0 0-.391.762v3.739a.934.934 0 0 0 .935.935h20.584a.936.936 0 0 0 .936-.935v-3.74a.934.934 0 0 0-.391-.76L17.84 5.193v-3.26A.935.935 0 0 0 16.906 1H9.42Zm.936 1.87h5.614v2.804a.934.934 0 0 0 .391.761l6.159 4.393v2.324H3.807v-2.324l6.158-4.393a.935.935 0 0 0 .391-.761V2.87Zm-4.21 17.76c-.612 0-1.125.391-1.318.935H1.936A.936.936 0 0 0 1 22.5v20.565a.935.935 0 0 0 .936.935h43.128a.936.936 0 0 0 .936-.935V22.5a.934.934 0 0 0-.936-.935H21.498a1.396 1.396 0 0 0-1.318-.935H6.146Zm-3.275 2.805h20.674V42.13H2.87V23.435Zm22.545 0h18.713v5.609H25.416v-5.61Zm-19.738 1.87a.937.937 0 0 0-.935.934v13.087a.934.934 0 0 0 .935.935h14.97a.937.937 0 0 0 .936-.935V26.24a.934.934 0 0 0-.936-.935H5.678Zm23.391 0a.938.938 0 0 0-.949.934.933.933 0 0 0 .949.935h11.228a.936.936 0 1 0 0-1.87H29.069ZM6.614 27.173h13.099V38.39h-13.1V27.174Zm18.802 3.739h18.713V42.13H25.416V30.913Zm3.638 2.792a.936.936 0 0 0-.92.947v4.674a.934.934 0 0 0 1.296.876.938.938 0 0 0 .575-.876v-4.674a.934.934 0 0 0-.95-.947Z"
      mask="url(#ComunityKitchenIcon_svg__a)"
    />
  </svg>
);
export default SvgComunityKitchenIcon;
