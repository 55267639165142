import React from 'react'
import "./AffectedAreaReportTable.css"
import { useTranslation } from 'react-i18next';

const AffectedAreaReportTable = (props) => {
  const { t, i18n } = useTranslation();

  
  return (
    <div className='affectedtable-container'>
      <div className="affectedtable-header-style"> {t('affectedareareport')}</div>
      <div className="p-3">
        <table className="affected-table">
          <thead>
            <tr>
              <th>{t('villagename')}</th>
             
              <th>{t('affectedarea')}</th>
            </tr>
          </thead>
          <tbody>
            {(props.data).map((row, index) => (
              <tr key={index}>
                <td>{row.village_name}</td>
                
                <td>{row.total_area_affected}</td>
               
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AffectedAreaReportTable