import * as React from "react";
const SvgGisManagement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 480 480"
    {...props}
  >
    <path d="M384 192c0-79.4-64.6-144-144-144S96 112.6 96 192s64.6 144 144 144 144-64.6 144-144zm-272 0c0-70.576 57.424-128 128-128s128 57.424 128 128-57.424 128-128 128-128-57.424-128-128zM240 352c-22.056 0-40 17.944-40 40s17.944 40 40 40 40-17.944 40-40-17.944-40-40-40zm0 64c-13.232 0-24-10.768-24-24s10.768-24 24-24 24 10.768 24 24-10.768 24-24 24z" />
    <path d="M328 264c4.416 0 8-3.584 8-8V128c0-4.416-3.584-8-8-8H152c-4.416 0-8 3.584-8 8v128c0 4.416 3.584 8 8 8h176zm-96-128h16v16h-16v-16zm-16 88v24h-56V136h56v24c0 4.416 3.584 8 8 8h32c4.416 0 8-3.584 8-8v-24h56v112h-56v-24c0-4.416-3.584-8-8-8h-32c-4.416 0-8 3.584-8 8zm32 24h-16v-16h16v16z" />
    <path d="M208 176h-24c-4.416 0-8 3.584-8 8s3.584 8 8 8h24c4.416 0 8-3.584 8-8s-3.584-8-8-8zM192 200h-8c-4.416 0-8 3.584-8 8s3.584 8 8 8h8c4.416 0 8-3.584 8-8s-3.584-8-8-8zM274.344 205.656A7.978 7.978 0 0 0 280 208a7.978 7.978 0 0 0 5.656-2.344l16-16c3.128-3.128 3.128-8.184 0-11.312s-8.184-3.128-11.312 0L280 188.688l-2.344-2.344a7.991 7.991 0 0 0-11.312 0 7.991 7.991 0 0 0 0 11.312l8 8z" />
    <path d="M368 464h-86.664C349.552 417.248 432 278.296 432 192 432 86.136 345.864 0 240 0S48 86.136 48 192c0 86.296 82.448 225.248 150.664 272H112c-4.416 0-8 3.584-8 8s3.584 8 8 8h256c4.416 0 8-3.584 8-8s-3.584-8-8-8zM64 192c0-97.048 78.952-176 176-176s176 78.952 176 176c0 101.24-119.968 272-176 272-65.504 0-176-185.408-176-272z" />
  </svg>
);
export default SvgGisManagement;
