import React from "react";
import { useState } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../../Components//UiComponents/GridItem/GridItem";
import DMSHeader from "../../DMSHeader/DMSHeader";
import { useLocation } from "react-router-dom";
import ContactRegistrationForm from "./ContactRegistrationForm";
import { useTranslation } from 'react-i18next';

const CreateContact = (props) => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: <DMSHeader title={t('contact_management_formheader')} showIconsForback={true}/>,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 17,
      id: 2,
     
      component: (
        <ContactRegistrationForm/>
      ),
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 ">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default CreateContact;
