import html2canvas from "html2canvas";
import moment from "moment";
import api from "../../Services/api";
import ViewDetails from "../../Pages/DMS/Common/ViewDetails/ViewDetails";
import { getContextForkey } from "../../Pages/DMS/Common/ContextDto";
const qs = require("qs");
//This function will download a screenshot a div with ref

export const downLoadScreenShot = (ref, scale, fileName, hideZoomControls) => {
  if (hideZoomControls) {
    let control = document.querySelector(".leaflet-control-zoom");
    control.setAttribute("data-html2canvas-ignore", "true");
  }
  html2canvas(ref.current, {
    useCORS: true,
    backgroundColor: null,
    scale: scale,
    windowHeight: ref.current.scrollHeight,
    windowWidth: ref.current.scrollWidth,
  }).then((canvas) => {
    const a = document.createElement("a");
    a.href = canvas.toDataURL();
    a.download = fileName;
    a.click();
  });
};

export const filterDate = (response, fieldName) => {
  response.forEach((record) => {
    if (record[fieldName]) {
      record[fieldName] = getFormattedDate(record[fieldName]);
    }
  });
  return response;
};

//This function will take date object as parameter and return the date DD/MM/YYYY
export const getFormattedDate = (date, format = "DD/MM/YYYY HH:mm:ss") =>
  moment(date).format(format);
//  export const getFormattedTableDate = (date) => moment(date).format("DD/MM/YYYY");
// // This function will convert the data into array of strings
export const getStringFormatData = (data) => {
  return data.map((item) => item.value).join(",");
};

export const getDRAttributeValue = (data,key)=>{
  if(data[key] && data[key] != null){
    return data[key]
  }
  else{
    return null;
  }
};

export const downloadFile = async (fileURL,fileName) => {
  let res = await fetch(fileURL, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer '+localStorage.getItem("auth_token")
    }
  });
  res.blob().then(function(content){
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(content);
    a.href = url;
    a.download = fileName;
    a.click();
  });
    
};

//This function is used for Append THe data of Images and Text
// export const appendFormData = (data, formData) => {
//   const myArray = Object.entries(data);
//   const keyYEnd = [];
//   const keysWithFile = [];
//   for (let i = 0; i < myArray.length; i++) {
//     const key = myArray[i][0];
//     if (key.endsWith("_image")) {
//       keysWithFile.push(key + "_file");
//       keyYEnd.push(key);
//       for (let i = 0; i < keysWithFile.length; i++) {
//         formData.append(keysWithFile[i], getContextForkey(keyYEnd[i]));
//       }
//     } else if (
//       key !== "registration_date" &&
//       key !== "deleted" &&
//       key !== "id"
//     ) {
//       formData.append(key, data[key]);
//     }
//   }
// };

//This function is used for Append THe data of Images and Text

export const appendFormData = (data, formData, additionalExclusions = []) => {
  const defaultExclusions = ["registration_date", "deleted", "id"];
  const excludedKeys = [...defaultExclusions, ...additionalExclusions];

  for (const key in data) {
    if (excludedKeys.includes(key)) {
      // Skip this key if it's in the excludedKeys array
      continue;
    } else {
      formData.append(key, data[key]);
    }
  }
};

// This function will take submittedData and formConfig as parameter and
// return the catergoried data based on the tabs also adds the Registered On for the first tab.
export const getCategroisedFormData = (submittedData, configData, colNumb) => {
  console.log(submittedData, configData);
  const config = configData;
  const categorisedData = {};
  for (let i = 0; i < config.length; i++) {
    const section = config[i];
    const sectionData = [];
    for (let j = 0; j < section.fields.length; j++) {
      let field = section.fields[j];
      let fieldName = field?.name;
      let fieldValue = submittedData[fieldName];
      if (i === 0 && j === section.fields.length - 1) {
        sectionData.push({
          label: "Registered On",
          value: submittedData["registration_date"],
          type: "date",
        });
      }
      sectionData.push({
        label: field.label,
        value: fieldValue,
        type: field.type,
      });
    }
    categorisedData[section.name.toLowerCase()] = sectionData;
    section.component = <ViewDetails data={sectionData} columns={colNumb} />;
  }
  return config;
};

export const getCategroisedDataForPreview = (submittedData, configData, id) => {
  const config = configData;
  const categorisedData = {};
  for (const section of config) {
    const sectionData = [];
    for (const field of section.fields) {
      const fieldName = field.name;
      const fieldValue = submittedData[fieldName];
      sectionData.push({
        label: field.label,
        value: fieldValue,
        type: field.type,
      });
    }

    categorisedData[section.name.toLowerCase()] = sectionData;
    section.component = <ViewDetails data={sectionData} columns={3} id={id} />;
  }
  return config;
};

export const getExportedColumnsData = (columns, data) => {
  const exportedData = data.map((item) => {
    const exportedItem = {};
    columns.forEach((column) => {
      if (item.hasOwnProperty(column.key)) {
        // if (column.key === "created_on") {
        //   exportedItem[column.label] = item[column.key]
        //   ? moment(item[column.key]).format("DD/MM/YYYY, HH:mm")
        //   : "NA";
        // } else {
        exportedItem[column.label] = item[column.key];
        // }
      }
    });
    return exportedItem;
  });
  return exportedData;
};

export const setValuesFromGetValues = (fieldNames, setValue, getValues) => {
  console.log("fieldNames=", fieldNames);
  fieldNames.forEach((fieldName) => {
    const valueToSet = getValues(fieldName); // Get the value from getValues
    setValue(fieldName, valueToSet); // Set the value using setValue
  });
};

export function isNotNullOrEmptyOrUndefined(value) {
  return value !== null && value !== undefined && value !== "";
}


// export const getCategroisedDataForPreview = (submittedData, configData, id) => {
//   const config = configData;
//   const categorisedData = {};

//   for (let i = 0; i < config.length; i++) {
//     const section = config[i];
//     const sectionData = [];

//     for (let j = 0; j < section.fields.length; j++) {
//       const field = section.fields[j];
//       const fieldName = field.name;
//       // let fieldValue;

//       // if (fieldName === "x") {
//       //   fieldValue = submittedData.geoJsonPoint?.x[fieldName];
//       // } else {
//        const fieldValue = submittedData[fieldName];
//       // }
//       console.log(submittedData.geoJsonPoint.x,"latitude");
// const mapvalue=submittedData[fieldName];
// console.log(mapvalue,"vbn");
//       sectionData.push({
//         label: field.label,
//         value: submittedData.geoJsonPoint.x['x'],
//         type: field.type,
//       });
//     }

//     categorisedData[section.name.toLowerCase()] = sectionData;
//     section.component = <ViewDetails data={sectionData} columns={3} id={id} />;
//   }

//   return config;
// };

//This function is calling MasterData API and will give the data by category
// export const getMasterDataByCategory = (catagory) => {
//   return getData(managemaster + "/" + catagory)
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });
// };
