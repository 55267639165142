import * as React from "react";
const SvgEditicon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.86 1.752a2.42 2.42 0 0 1 .787-.557 2.309 2.309 0 0 1 1.856 0c.294.13.562.319.787.557.225.238.404.522.525.833a2.699 2.699 0 0 1 0 1.965c-.122.312-.3.595-.525.833L5.715 17.638 1 19l1.286-4.993L13.86 1.752Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgEditicon;
