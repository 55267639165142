import * as React from "react";
const SvgJpgicon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M28.35 0H4.2v42h33.6V9.45L28.35 0Z" fill="#F8F8F9" />
    <path d="M28.35 9.45V0H21v42h16.8V9.45h-9.45Z" fill="#EDEDED" />
    <path d="M37.8 18.9H4.2v16.8h33.6V18.9Z" fill="#95D5A7" />
    <path d="M37.8 18.9H21v16.8h16.8V18.9Z" fill="#80CB93" />
    <path
      d="M17.574 30.45v-6.71h2.863c1.238 0 2.193 1.145 2.193 2.269 0 1.291-.968 2.277-2.145 2.277h-1.361v2.164h-1.55Zm1.55-3.515h1.266c.413 0 .671-.402.671-.926 0-.54-.285-.907-.727-.907h-1.21v1.833ZM28.546 29.789c-.51.472-1.1.708-1.767.708-1.846 0-3.222-1.517-3.222-3.449 0-1.953 1.553-3.355 3.345-3.355 1.32 0 2.214.585 2.684 1.465l-1.153.87c-.363-.657-.94-.955-1.588-.955-.954 0-1.72.849-1.72 2.032 0 1.127.727 2.013 1.786 2.013.605 0 1.15-.28 1.635-.841v-.208h-1.304v-1.125h2.59v3.506h-1.286v-.661ZM12.17 28.872c.2.121.588.302 1.114.302.904 0 1.059-.555 1.059-2.022v-3.411h1.55v3.411c0 2.38-.497 3.402-2.609 3.402-.567 0-1.052-.138-1.455-.416l.34-1.266Z"
      fill="#F8F8F9"
    />
  </svg>
);
export default SvgJpgicon;
