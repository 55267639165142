import React, { useEffect } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import { useTranslation } from "react-i18next";
import AffectedReportTable from "./AffectedReportTable/AffectedReportTable";


const AffectedReport = () => {
  const { t } = useTranslation();

  useEffect(() => {
    GridStack.init();
  }, []);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title="Affected Report - Bihar State"
                 

        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height:78,
      id: 2,
      component: <AffectedReportTable/>,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};


export default AffectedReport
