import * as React from "react";
const SvgContactManagement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M469.333 85.333v341.334H42.667V85.333h426.666ZM426.667 128H85.333v256h341.334V128ZM384 298.667v32H277.333v-32H384Zm-194.667-57.6c30.827 0 56 25.6 56 57.6h-128c0-32 25.072-57.6 56-57.6h16Zm194.667-6.4v32H277.333v-32H384ZM181.333 163.96c15.464 0 28 12.536 28 28s-12.536 28-28 28-28-12.536-28-28 12.536-28 28-28ZM384 170.667v32H277.333v-32H384Z"
    />
  </svg>
);
export default SvgContactManagement;
