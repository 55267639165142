import React, { useEffect } from 'react'
import "./GisManagement.css";

const DeploymentList = () => {

  useEffect(()=>{
    window.location.href = process.env.REACT_APP_GIS_SERVICE+"/resourceallocationlist"
})
return (
<div>Loading...</div>
)
}

export default DeploymentList;