import React from "react";
import {
  AnimalShelterIcon,
  ComunityKitchenIcon,
  FlCampIcon,
} from "../../../../../Components/IconComponents";
import "./FacilityRegistered.css";

export default function FacilityRegistered() {
  return (
    <div className="card">
      <div className="card-body">
        <div>
          Facility Registered
          {/* <span className="float-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots-vertical"
              viewBox="0 0 16 16">
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
          </span> */}
        </div>
        <div>
          <div className="facility-card">
            <ComunityKitchenIcon width="2rem" height="2rem" />
            <div className="facility-card_value">
              <div className="fc-card-value">10</div>
              <div className="fc-card-label">Comunity Kitchen</div>
            </div>
          </div>
          <div className="facility-card_ora">
            <FlCampIcon width="2rem" height="2rem" />
            <div className="facility-card_value">
              <div className="fc-card-value">10</div>
              <div className="fc-card-label">Flood Relief Camp</div>
            </div>
          </div>
          <div className="facility-card_pin">
            <AnimalShelterIcon width="2rem" height="2rem" />
            <div className="facility-card_value">
              <div className="fc-card-value">10</div>
              <div className="fc-card-label">Animal Shelter</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
