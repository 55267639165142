import React, { useState, useContext } from "react";
import "./Signout.css";
import { Modal, Button } from "react-bootstrap";
import { Logout, XCircleblack } from "../../Components/IconComponents";
import { useKeycloak } from "@react-keycloak/web";
import { appserveraddr } from "../../Services/EndPoints";
import { LayoutState } from "../../Context/LayoutContext/LayoutContext";
import { useTranslation } from 'react-i18next';
import { ThemeContext } from "../..";
const Signout = () => {
  const [isShow, invokeModal] = useState(false);
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { collapsed } = LayoutState();
  const hideModal = () => {
    invokeModal(false);
  };
  const { theme } = useContext(ThemeContext);
  return (
    // <div className={`${collapsed ? "sb-collapsed" : "sidebar1"}`}>
    //    <div className="sidebar__content1">
    <div className={collapsed ? " sidebarCollapsed" : "sidebar1"}>
      <div className="sidebar__content1" onClick={invokeModal}>
        <span>
          <Logout width="1.1em" height="1.1em" />
          {!collapsed ? t("signout") : null}
        </span>
      </div>
      <Modal id={theme} show={isShow}>
        <Modal.Header>
          {" "}
          <XCircleblack
            className="cursor-pointer close-position"
            onClick={hideModal}
            width="1.5em"
            height="1.5em"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="exit_btns_container">
            <div className="row mt-2 mb-3">
              <div className="col-6">
                <div
                  className={`exit_btns sidebar__bottom ${collapsed && " logout"
                    }`}
                  onClick={() => {
                    localStorage.clear();
                    keycloak.logout({ redirectUri: appserveraddr });
                  }}
                >
                  Logout
                </div>
              </div>
              <div className="col-6">
                This will log you out from all the applications.
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Signout;
