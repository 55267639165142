import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import {
  FormInputData,
  schema,
} from "../../../../../JsonData/DailyFloodReportSubFormConfig";
import FormHandler from "../../../Common/Form/FormHandler/FormHandler";
import { yupResolver } from "@hookform/resolvers/yup";

const DailyReportSubForm = ({ data, submitHandler, form_name }) => {
  const [reportData, setReportData] = useState(FormInputData[form_name]);
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });

  useEffect(() => {
    if (data && data.length > 0) {
      const report = data[0];
      for (var elem in report) {
        setValue(elem, report[elem]);
      }
    }
  }, [data]);

  //AffectedPopulation watched keys
  const watchedaffected_population_rural_yesterday = watch(
    "affected_population_rural_yesterday",
    0
  );
  const watchedaffected_population_urban_yesterday = watch(
    "affected_population_urban_yesterday",
    0
  );
  const watchedaffected_families_rural_yesterday = watch(
    "affected_families_rural_yesterday",
    0
  );
  const watchedaffected_families_urban_yesterday = watch(
    "affected_families_urban_yesterday",
    0
  );
  const watchedaffected_animals_rural_yesterday = watch(
    "affected_animals_rural_yesterday",
    0
  );
  const watchedaffected_animals_urban_yesterday = watch(
    "affected_animals_urban_yesterday",
    0
  );
  const watchedaffected_families_rural_today = watch(
    "affected_families_rural_today",
    0
  );
  const watchedaffected_families_urban_today = watch(
    "affected_families_urban_today",
    0
  );
  const watchedaffected_population_rural_today = watch(
    "affected_population_rural_today",
    0
  );
  const watchedaffected_population_urban_today = watch(
    "affected_population_urban_today",
    0
  );
  const watchedaffected_animals_rural_today = watch(
    "affected_animals_rural_today",
    0
  );
  const watchedaffected_animals_urban_today = watch(
    "affected_animals_urban_today",
    0
  );
  const watchedaffected_population_rural = watch(
    "affected_population_rural",
    0
  );
  const watchedaffected_population_urban = watch(
    "affected_population_urban",
    0
  );
  const watchedaffected_families_rural = watch("affected_families_rural", 0);
  const watchedaffected_families_urban = watch("affected_families_urban", 0);
  const watchedaffected_animals_rural = watch("affected_animals_rural", 0);
  const watchedaffected_animals_urban = watch("affected_animals_urban", 0);
  const watchedagricultural_area_affected = watch(
    "agricultural_area_affected",
    0
  );

  const watchednon_agricultural_area_affected = watch(
    "non_agricultural_area_affected",
    0
  );

  //Evacuated watched keys
  const watchedtotal_evacuated_till_yesterday = watch(
    "total_evacuated_till_yesterday",
    0
  );
  const watchedtotal_evacuated_today = watch("total_evacuated_today", 0);
  //floodReliefCamp watched keys
  const watchedtotal_camps_opened_so_far = watch(
    "total_camps_opened_so_far",
    0
  );
  const watchedtotal_camps_closed_so_far = watch(
    "total_camps_closed_so_far",
    0
  );
  const watchedtotal_people_registered_till_yesterday = watch(
    "total_people_registered_till_yesterday",
    0
  );
  const watchednew_people_registered_today = watch(
    "new_people_registered_today",
    0
  );
  const watchedtotal_people_registered_so_far = watch(
    "total_people_registered_so_far",
    0
  );
  const watchedtotal_people_gone_back_from_camps = watch(
    "total_people_gone_back_from_camps",
    0
  );
  //CommunityKitchen watched keys
  const watchedtotal_community_kitchens_opened_so_far = watch(
    "total_community_kitchens_opened_so_far",
    0
  );
  const watchedtotal_community_kitchens_closed_so_far = watch(
    "total_community_kitchens_closed_so_far",
    0
  );
  const watchednumber_of_people_eating_lunch_today = watch(
    "number_of_people_eating_lunch_today",
    0
  );
  const watchednumber_of_people_eating_dinner_tonight = watch(
    "number_of_people_eating_dinner_tonight",
    0
  );
  const watchedtotal_number_of_meals_provided_till_date = watch(
    "total_number_of_meals_provided_till_date",
    0
  );
  const watchednumber_of_people_eating_in_community_kitchens = watch(
    "number_of_people_eating_in_community_kitchens",
    0
  );

  //HealthCenter watched keys
  const watchednumber_of_health_centers_running_daily = watch(
    "number_of_health_centers_running_daily",
    0
  );
  const watchednumber_of_people_treated_daily = watch(
    "number_of_people_treated_daily",
    0
  );
  const watchednumber_of_halogen_tablets_distributed_daily = watch(
    "number_of_halogen_tablets_distributed_daily",
    0
  );
  const watchedamount_of_bleaching_powder_sprinkled_daily = watch(
    "amount_of_bleaching_powder_sprinkled_daily",
    0
  );
  const watchednumber_of_animal_camps_daily = watch(
    "number_of_animal_camps_daily",
    0
  );
  const watchednumber_of_animals_treated_daily = watch(
    "number_of_animals_treated_daily",
    0
  );

  //HealthCenter
  //HealthCentersRunning
  useEffect(() => {
    const healthCentersRunning =
      parseFloat(watchednumber_of_health_centers_running_daily) || 0;
    const updateHealthCentersTillNow =
      data[0]?.number_of_health_centers_running_till_now + healthCentersRunning;
    setValue(
      "number_of_health_centers_running_till_now",
      updateHealthCentersTillNow
    );
  }, [watchednumber_of_health_centers_running_daily, setValue]);

  //peopleTreatedDaily
  useEffect(() => {
    const peopleTreatedDaily =
      parseFloat(watchednumber_of_people_treated_daily) || 0;
    const updatePeopleTreatedTillNow =
      data[0]?.number_of_people_treated_till_now + peopleTreatedDaily;
    setValue("number_of_people_treated_till_now", updatePeopleTreatedTillNow);
  }, [watchednumber_of_people_treated_daily, setValue]);

  //HallogenTablets
  useEffect(() => {
    const halogenTabletsDaily =
      parseFloat(watchednumber_of_halogen_tablets_distributed_daily) || 0;
    const updateHalogenTabletsTillNow =
      data[0]?.number_of_halogen_tablets_distributed_till_now +
      halogenTabletsDaily;
    setValue(
      "number_of_halogen_tablets_distributed_till_now",
      updateHalogenTabletsTillNow
    );
  }, [watchednumber_of_halogen_tablets_distributed_daily, setValue]);

  //BleachingpowderSprinklled
  useEffect(() => {
    const bleachingPowderSprinkledDaily =
      parseFloat(watchedamount_of_bleaching_powder_sprinkled_daily) || 0;
    const updateBleachingPowderSprinkledTillNow =
      data[0]?.amount_of_bleaching_powder_sprinkled_till_now +
      bleachingPowderSprinkledDaily;
    setValue(
      "amount_of_bleaching_powder_sprinkled_till_now",
      updateBleachingPowderSprinkledTillNow
    );
  }, [watchedamount_of_bleaching_powder_sprinkled_daily, setValue]);

  //AnimalCamps
  useEffect(() => {
    const animalCampsDaily =
      parseFloat(watchednumber_of_animal_camps_daily) || 0;
    const updateAnimalCampsTillNow =
      data[0]?.number_of_animal_camps_till_now + animalCampsDaily;
    setValue("number_of_animal_camps_till_now", updateAnimalCampsTillNow);
  }, [watchednumber_of_animal_camps_daily, setValue]);

  //AnimalsTreated
  useEffect(() => {
    const animalsTreatedDaily =
      parseFloat(watchednumber_of_animals_treated_daily) || 0;
    const updateAnimalTreatedTillNow =
      data[0]?.number_of_animals_treated_till_now + animalsTreatedDaily;
    setValue("number_of_animals_treated_till_now", updateAnimalTreatedTillNow);
  }, [watchednumber_of_animals_treated_daily, setValue]);
  //end of HealthCentersRunning

  //CommunityKitchen
  useEffect(() => {
    const total_community_kitchens_opened_so_far =
      parseInt(watchedtotal_community_kitchens_opened_so_far) || 0;
    const total_community_kitchens_closed_so_far =
      parseInt(watchedtotal_community_kitchens_closed_so_far) || 0;
    setValue(
      "total_community_kitchens_open_today",
      total_community_kitchens_opened_so_far -
        total_community_kitchens_closed_so_far
    );
  }, [
    watchedtotal_community_kitchens_opened_so_far,
    watchedtotal_community_kitchens_closed_so_far,
    setValue,
  ]);

  useEffect(() => {
    const lunchCount =
      parseFloat(watchednumber_of_people_eating_lunch_today) || 0;
    const dinnerCount =
      parseFloat(watchednumber_of_people_eating_dinner_tonight) || 0;

    const peopleEatingInCommunityKitchens = lunchCount + dinnerCount;
    setValue(
      "number_of_people_eating_in_community_kitchens",
      peopleEatingInCommunityKitchens
    );
    const updatedMealsProvided =
      data[0]?.total_number_of_meals_provided_till_date +
      peopleEatingInCommunityKitchens;

    // const updatedMealsProvided =
    //   data[0]?.total_number_of_meals_provided_till_yesterday +
    //   peopleEatingInCommunityKitchens;

    setValue("total_number_of_meals_provided_till_date", updatedMealsProvided);
  }, [
    watchednumber_of_people_eating_lunch_today,
    watchednumber_of_people_eating_dinner_tonight,
    setValue,
  ]);

  //PopulationAffected
  useEffect(() => {
    const affected_families_rural_yesterday =
      parseFloat(watchedaffected_families_rural_yesterday) || 0;
    const affected_families_urban_yesterday =
      parseFloat(watchedaffected_families_urban_yesterday) || 0;
    const affected_population_rural_yesterday =
      parseFloat(watchedaffected_population_rural_yesterday) || 0;
    const affected_population_urban_yesterday =
      parseFloat(watchedaffected_population_urban_yesterday) || 0;
    const affected_animals_rural_yesterday =
      parseFloat(watchedaffected_animals_rural_yesterday) || 0;
    const affected_animals_urban_yesterday =
      parseFloat(watchedaffected_animals_urban_yesterday) || 0;
    const affected_population_rural_today =
      parseFloat(watchedaffected_population_rural_today) || 0;
    const affected_population_urban_today =
      parseFloat(watchedaffected_population_urban_today) || 0;
    const affected_families_rural_today =
      parseFloat(watchedaffected_families_rural_today) || 0;
    const affected_families_urban_today =
      parseFloat(watchedaffected_families_urban_today) || 0;
    const affected_animals_rural_today =
      parseFloat(watchedaffected_animals_rural_today) || 0;
    const affected_animals_urban_today =
      parseFloat(watchedaffected_animals_urban_today) || 0;
    const affected_population_rural =
      parseFloat(watchedaffected_population_rural) || 0;
    const affected_population_urban =
      parseFloat(watchedaffected_population_urban) || 0;
    const affected_families_rural =
      parseFloat(watchedaffected_families_rural) || 0;
    const affected_families_urban =
      parseFloat(watchedaffected_families_urban) || 0;
    const affected_animals_rural =
      parseFloat(watchedaffected_animals_rural) || 0;
    const affected_animals_urban =
      parseFloat(watchedaffected_animals_urban) || 0;
    const agricultural_area_affected =
      parseFloat(watchedagricultural_area_affected) || 0;
    const non_agricultural_area_affected =
      parseFloat(watchednon_agricultural_area_affected) || 0;
    setValue(
      "total_area_affected",
      Number(
        (agricultural_area_affected + non_agricultural_area_affected).toFixed(5)
      )
    );
    setValue(
      "affected_animals_total",
      affected_animals_rural + affected_animals_urban
    );
    setValue(
      "affected_families_total",
      affected_families_rural + affected_families_urban
    );
    setValue(
      "affected_population_total",
      affected_population_rural + affected_population_urban
    );
    setValue(
      "affected_animals_total_today",
      affected_animals_rural_today + affected_animals_urban_today
    );
    setValue(
      "affected_families_total_today",
      affected_families_rural_today + affected_families_urban_today
    );
    setValue(
      "affected_population_total_today",
      affected_population_rural_today + affected_population_urban_today
    );
    setValue(
      "affected_animals_total_yesterday",
      affected_animals_rural_yesterday + affected_animals_urban_yesterday
    );
    setValue(
      "affected_population_total_yesterday",
      affected_population_rural_yesterday + affected_population_urban_yesterday
    );
    setValue(
      "affected_families_total_yesterday",
      affected_families_rural_yesterday + affected_families_urban_yesterday
    );
    setValue(
      "affected_population_rural",
      affected_population_rural_yesterday + affected_population_rural_today
    );
    setValue(
      "affected_population_urban",
      affected_population_urban_yesterday + affected_population_urban_today
    );
    setValue(
      "affected_families_rural",
      affected_families_rural_yesterday + affected_families_rural_today
    );
    setValue(
      "affected_families_urban",
      affected_families_urban_yesterday + affected_families_urban_today
    );
    setValue(
      "affected_animals_rural",
      affected_animals_rural_yesterday + affected_animals_rural_today
    );
    setValue(
      "affected_animals_urban",
      affected_animals_urban_yesterday + affected_animals_urban_today
    );
  }, [
    watchedaffected_population_rural_yesterday,
    watchedaffected_population_rural_today,
    watchedaffected_population_urban_yesterday,
    watchedaffected_families_rural_yesterday,
    watchedaffected_families_urban_yesterday,
    watchedaffected_animals_rural_yesterday,
    watchedaffected_animals_urban_yesterday,
    watchedaffected_population_rural_today,
    watchedaffected_population_urban_today,
    watchedaffected_families_rural_today,
    watchedaffected_families_urban_today,
    watchedaffected_animals_rural_today,
    watchedaffected_animals_urban_today,
    watchedaffected_population_rural,
    watchedaffected_families_rural,
    watchedaffected_families_urban,
    watchedaffected_population_urban,
    watchedaffected_animals_rural,
    watchedaffected_animals_urban,
    watchedagricultural_area_affected,
    watchednon_agricultural_area_affected,
    setValue,
  ]);

  //Evacuated
  useEffect(() => {
    const total_evacuated_till_yesterday =
      parseFloat(watchedtotal_evacuated_till_yesterday) || 0;
    const total_evacuated_today = parseFloat(watchedtotal_evacuated_today) || 0;
    setValue(
      "total_evacuated_till_now",
      total_evacuated_till_yesterday + total_evacuated_today
    );
  }, [
    watchedtotal_evacuated_till_yesterday,
    watchedtotal_evacuated_today,
    setValue,
  ]);

  //useEffect of floodReliefReport
  useEffect(() => {
    const total_camps_opened_so_far =
      parseInt(watchedtotal_camps_opened_so_far) || 0;
    const total_camps_closed_so_far =
      parseInt(watchedtotal_camps_closed_so_far) || 0;
    setValue(
      "total_camps_open_today",
      total_camps_opened_so_far - total_camps_closed_so_far
    );
  }, [
    watchedtotal_camps_opened_so_far,
    watchedtotal_camps_closed_so_far,
    setValue,
  ]);
  useEffect(() => {
    const total_people_registered_till_yesterday =
      parseInt(watchedtotal_people_registered_till_yesterday) || 0;
    const new_people_registered_today =
      parseInt(watchednew_people_registered_today) || 0;
    setValue(
      "total_people_registered_so_far",
      total_people_registered_till_yesterday + new_people_registered_today
    );
  }, [
    watchedtotal_people_registered_till_yesterday,
    watchednew_people_registered_today,
    setValue,
  ]);
  useEffect(() => {
    const total_people_registered_so_far =
      parseInt(watchedtotal_people_registered_so_far) || 0;
    const total_people_gone_back_from_camps =
      parseInt(watchedtotal_people_gone_back_from_camps) || 0;
    setValue(
      "people_remaining_in_camps",
      total_people_registered_so_far - total_people_gone_back_from_camps
    );
  }, [
    watchedtotal_people_registered_so_far,
    watchedtotal_people_gone_back_from_camps,
    setValue,
  ]);

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        <FormHandler
          register={register}
          errors={errors}
          fields={fields}
          control={control}
          columns={2}
        />
      </div>
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const tabsData = generateTabsData(reportData);

  return (
    <div className="mt-4">
      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name={form_name}>
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData?.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                  {t("saveAndSubmit")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default DailyReportSubForm;
