import React, { useState,useEffect }  from 'react';
import { useParams } from "react-router";
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import { ReactFormBuilder } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { ElementStore } from "react-form-builder2";
import { ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { toast, ToastContainer } from "react-toastify";
import {
  getData,postDataWithBody
} from "../../../Services/Services";
import {
  createFormDataViaBuilder,getFormStructureURL
} from "../../../Services/EndPoints";
import { useTranslation } from 'react-i18next';
const FormViewerForm = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formName = props.formName;
  const [formStructure, steFormStructure] = useState(null);
  useEffect(() => {
    getFormStructure();
  },[]);

  async function handleSubmit(data, e){
    //e.preventDefault();
    var input = {"form_name":formName,"data":data};
    let headers = {"content-type": "application/json"};
    let res = await postDataWithBody(createFormDataViaBuilder,JSON.stringify(input),headers);
    if(res.status == 'success'){
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      navigate("/report-view/"+props.formName);
    }
    else{
      toast.error(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
  }
  async function getFormStructure(){

    if(formName){
      var formStructure = await getData(getFormStructureURL+"?form_name="+formName);
      steFormStructure(formStructure);
    }
    
  }
 
  return (
    <div className="container p-4">
     {formStructure != null && (
          <ReactFormGenerator
          form_action="/formbuilder/submit"
          form_method="POST"
          onSubmit={handleSubmit}
          data={formStructure} // Question data
          submitButton={<button type={"submit"} className={"btn btn-primary"}>{t("submit")}</button>}
          />
     )}

    
    </div>
  )
}

export default FormViewerForm