import React from "react";
import CellDetails from "../../../../Pages/DMS/Common/CellDetails/CellDetails";

const ViewDetails = ({ data, columns }) => {
  const styles = {
    columnCount: columns,
    columnGap: "30%", // sets the size of the gap between columns
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: "0.9rem",
    padding: "2em 1em 1em 3em"
  };
  return (
    <div style={styles}>
      {data?.map((item) => (
        <CellDetails item={item} />
      ))}
    </div>
  );
};

export default ViewDetails;
