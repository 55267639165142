import * as React from "react";
const SvgFacilityManagement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    viewBox="0 0 664.371 664.371"
    {...props}
  >
    <path
      d="M332.195 0c-115.6 0-209.306 93.676-209.306 209.287s209.306 455.084 209.306 455.084 209.287-339.494 209.287-455.084C541.482 93.676 447.796 0 332.195 0zm0 371.208c-89.299 0-161.94-72.651-161.94-161.94s72.641-161.931 161.94-161.931c89.28 0 161.931 72.651 161.931 161.931 0 89.298-72.651 161.94-161.931 161.94z"
      style={{
        fill: "currentColor",
      }}
    />
    <path
      d="m303.031 285.455 59.383.01v20.302h23.966V77.526H278v13.043h96.91v16.668H278v198.52h25.031v-20.302zm38.524-160.475h32.31v32.31h-32.31v-32.31zm-.254 55.719h32.31v32.329h-32.31v-32.329zm0 55.241h32.31v32.281h-32.31V235.94zm-50.277-110.96h32.32v32.31h-32.32v-32.31zm-.254 55.719h32.3v32.329h-32.3v-32.329zm0 55.25h32.3v32.281h-32.3v-32.281zM362.414 305.777h-59.383v2.618h-60.457v22.931h179.243v-22.931h-59.403z"
      style={{
        fill: "currentColor",
      }}
    />
  </svg>
);
export default SvgFacilityManagement;
