import * as React from "react";
const SvgPdficon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M39 9.665V42H3.92V0h25.415l4.502 4.502L39 9.665Z" fill="#FFFEFE" />
    <path d="M19.823 2.813H3v7.528h16.824V2.813Z" fill="#2F9C91" />
    <path
      d="M9.034 5.281a1.439 1.439 0 0 0-.31-.476 1.428 1.428 0 0 0-.494-.321 1.76 1.76 0 0 0-.668-.118h-1.85V9.14h.935v-1.8h.915c.247 0 .47-.037.668-.117.191-.074.358-.185.494-.322.137-.136.235-.29.31-.476a1.601 1.601 0 0 0 0-1.144ZM8.026 6.326a.715.715 0 0 1-.507.18h-.872V5.195h.872c.21 0 .383.062.507.185.117.118.179.278.179.476a.643.643 0 0 1-.18.47ZM13.499 6.215a4.705 4.705 0 0 0-.031-.452 1.773 1.773 0 0 0-.093-.377 1.347 1.347 0 0 0-.173-.322 1.573 1.573 0 0 0-.6-.531 1.943 1.943 0 0 0-.841-.167h-1.719V9.14h1.719c.328 0 .606-.056.841-.167.229-.112.433-.291.6-.532.074-.1.13-.21.173-.322.043-.117.074-.24.093-.377.018-.136.024-.284.03-.451.007-.161.007-.34.007-.538s0-.378-.006-.538Zm-.934 1.02c0 .142-.012.254-.025.353a1.08 1.08 0 0 1-.055.24.686.686 0 0 1-.105.186c-.155.198-.39.29-.7.29h-.71v-3.11h.71c.31 0 .545.1.7.297a.65.65 0 0 1 .105.18c.024.067.043.148.055.247.013.093.025.21.025.346.006.136.006.303.006.489 0 .185 0 .346-.006.482ZM17.486 5.194v-.828H14.34V9.14h.934V7.204h1.886V6.37h-1.886V5.194h2.213Z"
      fill="#FFFEFE"
    />
    <path
      d="M9.034 5.281a1.439 1.439 0 0 0-.31-.476 1.428 1.428 0 0 0-.494-.321 1.76 1.76 0 0 0-.668-.118h-1.85V9.14h.935v-1.8h.915c.247 0 .47-.037.668-.117.191-.074.358-.185.494-.322.137-.136.235-.29.31-.476a1.601 1.601 0 0 0 0-1.144ZM8.026 6.326a.715.715 0 0 1-.507.18h-.872V5.195h.872c.21 0 .383.062.507.185.117.118.179.278.179.476a.643.643 0 0 1-.18.47Z"
      fill="#FFFEFE"
    />
    <path
      opacity={0.08}
      d="M39 9.665V42H3.92v-7.581l16.4-16.4.97-.97.538-.538.897-.897 1.063-1.064L33.837 4.502 39 9.665Z"
      fill="#040000"
    />
    <path d="M39 9.666h-9.666V0L39 9.666Z" fill="#BBBBBA" />
    <path
      d="M33.992 30.406a4.044 4.044 0 0 0-.829-1.008 5.98 5.98 0 0 0-.995-.724c-1.522-.896-3.692-1.33-6.642-1.33h-.297c-.16-.148-.327-.296-.5-.45-1.12-1.009-2.01-2.103-2.703-3.321 1.36-3 2.01-5.67 1.93-7.947a4.258 4.258 0 0 0-.167-1.075 3.485 3.485 0 0 0-.742-1.404l-.013-.013c-.55-.618-1.317-.97-2.096-.97-.785 0-1.509.34-2.035.964-.519.619-.797 1.466-.81 2.461a21.094 21.094 0 0 0 .365 4.292c.025.105.044.21.068.315.26 1.206.643 2.35 1.138 3.414a43.733 43.733 0 0 1-1.577 2.893c-.31.526-.65 1.076-1.002 1.627-1.589.284-2.937.63-4.112 1.064-1.589.575-2.863 1.317-3.778 2.2-.594.57-.996 1.176-1.194 1.807-.21.649-.197 1.298.038 1.88.216.537.606.989 1.125 1.298.124.074.254.136.39.192.395.16.828.247 1.28.247 1.045 0 2.12-.451 3.03-1.261 1.694-1.516 3.444-3.952 4.477-5.492a63.16 63.16 0 0 1 2.807-.352 52.327 52.327 0 0 1 3.29-.235c.303.272.594.532.872.779 1.546 1.379 2.72 2.375 3.784 3.073 0 .006.006.006.012.006.421.279.823.507 1.225.693.451.21.909.322 1.379.322.593 0 1.15-.18 1.614-.508.47-.34.81-.828.965-1.378.179-.662.074-1.398-.297-2.06ZM20.32 18.02a18.92 18.92 0 0 1-.112-2.406c.013-.798.303-1.336.73-1.336.321 0 .662.291.816.848.043.167.074.358.08.575.013.26.007.531-.006.81a12.22 12.22 0 0 1-.408 2.517 17.353 17.353 0 0 1-.556 1.799 15.149 15.149 0 0 1-.544-2.807ZM10.005 34.295c-.105-.26.012-.76.656-1.379.983-.94 2.523-1.682 4.668-2.244a29.37 29.37 0 0 1-1.2 1.527c-.593.717-1.162 1.323-1.675 1.78-.44.396-.921.644-1.38.706a1.58 1.58 0 0 1-.24.018c-.402 0-.73-.16-.829-.408Zm9.882-6.573.043-.068-.068.012a51.115 51.115 0 0 0 .99-1.738l.03.069.08-.167c.26.37.545.736.842 1.088.142.167.29.328.439.489l-.1.006.087.08c-.253.019-.52.043-.785.068-.167.019-.34.031-.513.05l-1.045.11Zm10.407 3.858c-.736-.47-1.558-1.131-2.573-2.015 1.441.148 2.573.463 3.377.933.352.204.618.415.797.619.297.315.402.6.347.791-.05.192-.266.322-.532.322-.155 0-.315-.037-.483-.118a8.044 8.044 0 0 1-.896-.507c-.012 0-.025-.012-.037-.024Z"
      fill="#2F9C91"
    />
  </svg>
);
export default SvgPdficon;
