import * as React from "react";
const SvgModalCloseIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5 41.25c10.355 0 18.75-8.395 18.75-18.75S32.855 3.75 22.5 3.75 3.75 12.145 3.75 22.5s8.395 18.75 18.75 18.75ZM28.125 16.875l-11.25 11.25M16.875 16.875l11.25 11.25"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgModalCloseIcon;
