import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../Components/UiComponents/Table/Table";
import { Editicon, Deleteicon } from "../../../../Components/IconComponents";
import {
  facilityTableData,
  facilitycolumnsForExport,
} from "../../../../JsonData/CreateFacilityConfig";
import { getData, putDataWithBody } from "../../../../Services/Services";
import {
  getFacilities,
  deleteFacilityURL,
} from "../../../../Services/EndPoints";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { toast, ToastContainer } from "react-toastify";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import Loader from "react-spinner-loader";
import { useTranslation } from "react-i18next";
import { RoleContext } from "../../../..";

const FacilityList = () => {
  const { roleName } = useContext(RoleContext);
  const { t } = useTranslation();
  const [data, setData] = useState(facilityTableData);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getFacilityList();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const getFacilityList = async () => {
    setLoader(true);
    var facilityList = await getData(getFacilities);
    let sortedList = facilityList.sort((a, b) => {
      return new Date(b.created_on) - new Date(a.created_on);
    });
  
    setLoader(false);
    setData(sortedList);
    setRowCount(sortedList.length);
    // sendDataToCentralStore(facilityList);
  };
  const addNewHandler = () => {
    navigate("/facility-mgmt/create-facility");
    // localStorage.removeItem("DriverRegForm");
  };

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = facilitycolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("facilitytableheaders.name"),
        accessor: "name",
        disableSortBy: true,
        // Cell: (props) => <TextEllipsis text={props?.value} maxLength={15} />,
      },
      {
        Header: t("facilitytableheaders.address"),
        accessor: "address",
        Cell: (props) => {
          if (props.value) {
            let addressText = props.value;
            if (props.value.length > 15) {
              addressText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{addressText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("facilitytableheaders.region"),
        accessor: "dist_name",
        disableSortBy: true,
        Cell: (props) => {
          return props.value || "NA";
        },
      },
      {
        Header: t("facilitytableheaders.description"),
        accessor: "description",

        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("facilitytableheaders.createdon"),
        accessor: "created_on",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,

        // Cell: (props) => <TextEllipsis text={props?.value} maxLength={15} />,
      },

      {
        Header: t("facilitytableheaders.facilitytype"),

        accessor: "facility_type",
        disableSortBy: true,
        Cell: (props) => {
          const value = props.value;
          return <div>{value}</div>;
        },
      },

      // {
      //   Header: "Status  ",
      //   accessor: "status",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     return <StatusHandler props={props} />;
      //   },
      // },
      //
      {
        Header: t("facilitytableheaders.action"),
        accessor: "actions",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const isFacility = props.row?.original?.facility_type
            .split(",")
            .map((type) => type.trim()); 

          const disableDeleteValues = [
            "Community Kitchen",
            "Flood Relief Camp",
            "Animal Shelter",
          ];

          const isDeleteDisabled = disableDeleteValues.some((value) =>
            isFacility.includes(value)
          );

          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };

          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteFacilityURL, data, {});
            if (res.status === "success") {
              getFacilityList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };

          return (
            <div className="usertable__actionsicon">
              <span
                className="ms-3"
                onClick={() =>
                  navigate("/facility-mgmt/create-facility", {
                    state: {
                      data: props.row.original.id,
                    },
                  })
                }>
                <Editicon value={props.value} className="cursor-pointer me-2" />
              </span>
              {roleName === process.env.REACT_APP_SUPER_ADMIN &&
                !isDeleteDisabled && (
                  <span onClick={deletePopUp}>
                    <Deleteicon
                      value={props.value}
                      className="cursor-pointer mr-1"
                    />
                  </span>
                )}

              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.name}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t,roleName]
  );

  return (
    <div className="container  create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div>
        {facilityTableData && (
          <Table
            data={data}
            title=""
            showFilter={false}
            columns={columns}
            numberOfRows={7}
            addButtonLabel={t("facilityaddButtonLabel")}
            addNewHandler={addNewHandler}
            setSearchData={setSearchData}
            showExport={false}
            showReset={false}
            onExpand={false}
            showSearch={true}
            isHeaderFixed={true}
            rowCount={rowCount} // Pass rowCount to the Table component
            // addButtonLabel="Add New"
            // addNewHandler={addNewHandler}
            enableColumnFilters={true}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FacilityList;
