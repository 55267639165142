import { createSlice } from "@reduxjs/toolkit";

const initialRegState = {
  resource_image_name: null,
  task_media_file:null,
   };
const initialMultiState = [
  {
    upload_file: null,
  },
];

// const initialSalesForm = {};
const registrationSlice = createSlice({
  name: "registrations",

  initialState: {
   
    registrationFiles: initialRegState,
    
    multiFiles: initialMultiState,
  },
  reducers: {
   

    setRegFilesToStore: (state, actions) => {
      const { type, file } = actions.payload;
      if (type === "reset") {
        state.registrationFiles = initialRegState;
      } else {
        state.registrationFiles[type] = file;
      }
    },
    setMultiFilesToStore: (state, actions) => {
      const { type, files } = actions.payload;
      if (type === "reset") {
        state.multiFiles = initialMultiState;
      } else {
        state.multiFiles = [...files];
      }
    },

    // speedBlackListHandler: (state, actions) => {
    //   const { type } = actions.payload;

    //   switch (type) {
    //     case "blackList":
    //       state.blackListStatus = true;
    //       break;
    //     default:
    //       // Handle other cases or do nothing if needed
    //       break;
    //   }
    // },
  },
});

export const { actions: registrationActions, reducer: registrationReducer } =
  registrationSlice;

export const {
//   changeActiveTab,
  setRegFilesToStore,
//   speedBlackListHandler,
//   setDynamicFormData,
//   setDateRedux,
//   setsalesFormData,
//   setSelectedLogId,
//   setUniqueId,
//   setTypeAheadValue,
//   setDateForTable,
  setMultiFilesToStore
} = registrationActions;
