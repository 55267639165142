import * as yup from "yup";
const FileSize = 5000000;
const allowedFormats = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const TaskFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "incident_type_id",
        label: "taskformlabels.incident",
        type: "select",
        options: [],
      },

      {
        name: "task_name",
        label: "taskformlabels.overview",
        type: "input",
        mandatory: "true",
      },
      {
        name: "assign_to_id",
        label: "taskformlabels.assignto",
        type: "select",
        options: [],
      },

      {
        name: "task_type_id",
        label: "taskformlabels.tasktype",
        type: "select",
        options: [],
      },

      {
        name: "description",
        label: "taskformlabels.summary",
        type: "text_area",
      },

      {
        name: "task_media_file",
        label: "taskformlabels.attachments",
        type: "multi_file_upload",
      },
    ],
  },
];

export const sopTableData = [
  {
    title: "New Task",
    incidentname: "Daily Operations",
    status: "Not Started",
    assignedby: "Odhisha Test",
    duedate: "30/07/2025, 10:00 AM",
    assignedto: "Odhisha Test",
    dateassigned: "30/07/2023, 12:00 AM",
    actions: "actions",
  },
  {
    title: "New Task",
    incidentname: "Daily Operations",
    status: "Not Started",
    assignedby: "Odhisha Test",
    duedate: "30/07/2025, 10:00 AM",
    assignedto: "Odhisha Test",
    dateassigned: "12/08/2023, 12:00 AM",
    actions: "actions",
  },
  {
    title: "New Task",
    incidentname: "Daily Operations",
    status: "Not Started",
    assignedby: "Odhisha Test",
    duedate: "30/07/2025, 10:00 AM",
    assignedto: "Odhisha Test",
    dateassigned: "12/07/2023, 12:00 AM",
    actions: "actions",
  },
];

export const viewtaskcolumnsForExportView = [
  { label: "Name", key: "task_name" },
  { label: "Incident Type", key: "incident_type" },
  { label: "Priority", key: "task_priority" },
  { label: "Task Status", key: "task_status" },
  { label: "Task Type", key: "task_type" },
  { label: "Assigned To", key: "role_name" },
];

export const SoPFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "name",
        label: "sopformlabels.name",
        type: "input",
      },
      {
        name: "sop_task",
        label: "sopformlabels.task",
        type: "multi_select",
        mandatory: "true",
        options: [],
      },
      {
        name: "sop_resource",
        label: "sopformlabels.resource",
        type: "multi_select",
        options: [],
      },
      {
        name: "description",
        label: "sopformlabels.description",
        type: "text_area",
      },
    ],
  },
];

export const sopformschema = [
  yup.object({
    name: yup.string().required("Please provide the name"),
    sop_task: yup
      .array()
      .required("Please select SOP task type")
      .min(1, "Pick at least 1")
      .of(
        yup.object().shape({
          value: yup.string().required(),
        })
      ),
  }),
];

export const taskmanagementschema = [
  yup.object({
    // incident_type_id: yup.string().required("Please select the Incident type")
    task_name: yup.string().required("Please enter the task name"),
    description: yup
      .string()
      .nullable() // Allow null values (empty string)
      .max(200, "Description must be at most 200 characters"),

    task_media_file: yup
      .mixed()
      .required("Please upload an image or file")
      .test("required", "Please upload the image or file", (value) => {
        if (value && value[0]) {
          return true;
        } else if (value?.length) return value?.length > 0;
        else if (value?.id) return true;
      })
      .test(
        "fileFormat",
        "Invalid file format. Allowed file formats (png,jpeg,pdf,docx)",
        (value) => {
          if (value && value.length > 0) {
            if (value && value[0]) {
              return allowedFormats.includes(value[0]?.type);
            } else {
              return allowedFormats.includes(value?.type);
            }
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "Max size 5 MB ", (value) => {
        if (value && value.length > 0) {
          if (value && value[0]) {
            return value[0]?.size <= FileSize;
          } else {
            return value?.size <= FileSize;
          }
        } else {
          return true;
        }
      }),
    // task_type_id: yup
    //     .string()
    //     .required("Please select the Task Type"),

    // description: yup
    //     .string()
    //     .min(3, "Description must be atleast 3 characters")
    //     .max(150, "Description must be at most 200 characters")
    //     .required("Please provide  the Description"),

    // disposition: yup
    //     .string()
    //     .min(3, "Description must be atleast 3 characters")
    //     .max(150, "Description must be at most 200 characters")
    //     .required("Please provide  the Description"),
  }),
];
