import * as React from "react";
const SvgFlCampIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 41 45"
    {...props}
  >
    <path
      fill="currentColor"
      d="M39.636 19.224h-.002L23.458 8.428l4.32-2.214c1.085-.556 1.086-2.113 0-2.67L21.184.168A1.5 1.5 0 0 0 19 1.502v5.954L1.366 19.224h-.002a1.511 1.511 0 0 0-.666 1.247V43.5a1.5 1.5 0 0 0 1.5 1.5h36.604a1.5 1.5 0 0 0 1.5-1.5V20.471c0-.485-.239-.96-.666-1.247ZM22 3.955l1.803.925L22 5.804V3.955Zm-1.5 6.106 13.352 8.91H7.148l13.352-8.91ZM13.028 42h-9.33v-1.987h9.33V42Zm11.944 0h-8.944v-8.491h8.944v8.49Zm2.063-11.491h-13.07v-1.736h13.07v1.736Zm10.267 11.49h-9.33v-1.986h9.33V42Zm0-4.986h-9.33v-3.504h.563a1.5 1.5 0 0 0 1.5-1.5v-4.736a1.5 1.5 0 0 0-1.5-1.5h-16.07a1.5 1.5 0 0 0-1.5 1.5v4.736a1.5 1.5 0 0 0 1.5 1.5h.563v3.504h-9.33V21.97h33.604v15.042Z"
    />
  </svg>
);
export default SvgFlCampIcon;
