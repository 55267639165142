export const SOPTableData = [
    {
        task: "OSDMA/SRC",
        resource: "Revenue Disaster Management Department",
        description: "State",
    },
    {
        task: "Additional District Megistrate,anugul",
        resource: "Police Department",
        description: "Block/Sub Division",
    },
    {
        task: "ADH/Sonepur",
        resource: "Agriculture & Farmers Empowerment Department",
        description: "Block/Sub Division",
    },
];

export const sopcolumnsForExport = [
  { label: "Name", key: "name" },
  { label: "Description", key: "description" },
  { label: "Task List", key: "sop_task_map" },
  { label: "Resource List", key: "sop_resource_map" },
];
