import React from "react";
import "./ResetTableData.css";

const ResetTableData = ({ reset }) => {
  return (
    <button className="reset-button" onClick={reset}>
      View All
    </button>
  );
};

export default ResetTableData;
