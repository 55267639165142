import React, { useEffect, useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import "./FacilityTypeView.css";
const FacilityTypeView = (props) => {
  const { title, config, dataval } = props;
  // const [config, setConfig] = useState(props);
  const [rangetxt, setRangetxt] = useState("");
  const [data, setData] = useState({});
  const { t } = useTranslation();

  const facilityconfig = {
    // communitykitchen: {
    //   header: "Community Kitchen",
    //   data: {
    //     facility_type_name: t("navlinklabels.communitykitchen"),
    //     open_count: props?.dataval["Open Count"],
    //     increase_prcntage:
    //       props?.dataval["Total Meals served"] &&
    //       calculatePercentageChange(props?.dataval, "Total Meals served"),
    //     attributes: [
    //       {
    //         attribute_name: t("mealsearved"),
    //         attribute_value: props?.dataval["Total Meals served"]
    //           ? props?.dataval["Total Meals served"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.lunch"),
    //         attribute_value: props?.dataval["Lunch"]
    //           ? props?.dataval["Lunch"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.breakfast"),
    //         attribute_value: props?.dataval["Breakfast"]
    //           ? props?.dataval["Breakfast"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.dinner"),
    //         attribute_value: props?.dataval["Dinner"]
    //           ? props?.dataval["Dinner"]
    //           : 0,
    //       },
    //     ],
    //   },
    // },
    // floodrelifcamp: {
    //   header: "Flood Relief Camp",
    //   data: {
    //     facility_type_name: t("navlinklabels.floodcamp"),
    //     open_count: props?.dataval["Open Count"],
    //     increase_prcntage:
    //       props?.dataval["Total Meals served"] &&
    //       calculatePercentageChange(props?.dataval, "Total Meals served"),
    //     attributes: [
    //       {
    //         attribute_name: t("mealsearved"),
    //         attribute_value: props?.dataval["Total Meals served"]
    //           ? props?.dataval["Total Meals served"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.lunch"),
    //         attribute_value: props?.dataval["Lunch"]
    //           ? props?.dataval["Lunch"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.breakfast"),
    //         attribute_value: props?.dataval["Breakfast"]
    //           ? props?.dataval["Breakfast"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.dinner"),
    //         attribute_value: props?.dataval["Dinner"]
    //           ? props?.dataval["Dinner"]
    //           : 0,
    //       },
    //     ],
    //   },
    // },
    // floodrelifcenter: {
    //   header: "Flood Relief Center",
    //   data: {
    //     facility_type_name: "Flood Relief Center",
    //     open_count: props?.dataval["Open Count"],
    //     increase_prcntage:
    //       props?.dataval["Total Meals served"] &&
    //       calculatePercentageChange(props?.dataval, "Total Meals served"),
    //     attributes: [
    //       {
    //         attribute_name: t("mealsearved"),
    //         attribute_value: props?.dataval["Total Meals served"]
    //           ? props?.dataval["Total Meals served"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.lunch"),
    //         attribute_value: props?.dataval["Lunch"]
    //           ? props?.dataval["Lunch"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.breakfast"),
    //         attribute_value: props?.dataval["Breakfast"]
    //           ? props?.dataval["Breakfast"]
    //           : 0,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.dinner"),
    //         attribute_value: props?.dataval["Dinner"]
    //           ? props?.dataval["Dinner"]
    //           : 0,
    //       },
    //     ],
    //   },
    // },
    // animalcenters: {
    //   header: "Animal Center",
    //   data: {
    //     facility_type_name: "Animal Center",
    //     open_count: 123,
    //     increase_prcntage: "-6%",
    //     attributes: [
    //       { attribute_name: t("mealsearved"), attribute_value: 12 },
    //       {
    //         attribute_name: t("communitykitchentableheaders.lunch"),
    //         attribute_value: 34,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.breakfast"),
    //         attribute_value: 45,
    //       },
    //       {
    //         attribute_name: t("communitykitchentableheaders.dinner"),
    //         attribute_value: 55,
    //       },
    //     ],
    //   },
    // },
  };

  function calculatePercentageChange(dataval, attribute) {
    if (
      dataval &&
      dataval?.prev_data !== undefined &&
      dataval[attribute] !== undefined &&
      dataval[attribute] &&
      dataval?.prev_data !== null
    ) {
      const initial = dataval.prev_data;
      const final = dataval[attribute];
      const percentageChange = ((final - initial) / initial) * 100;
      return percentageChange.toFixed(2);
    } else {
      return "0";
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const widgetconfig = facilityconfig[config["type"]];
      const widgetdata = widgetconfig?.data;
      if (widgetdata?.increase_prcntage) {
        const inper = widgetconfig?.data?.increase_prcntage;
        if (inper.startsWith("-")) {
          widgetdata["isup"] = false;
        } else {
          widgetdata["isup"] = true;
        }
      }
      if (props?.range === "TODAY") {
        setRangetxt("From Yesterday");
      } else if (props?.range === "WEEK") {
        setRangetxt("From Last Week");
      } else if (props?.range === "MONTH") {
        setRangetxt("From Last Month");
      }
      setData(widgetdata);
    }, 100);
  }, [props]);
  const getBackgroundClass = (index) => {
    const backgroundClasses = [
      "singleCardBlue",
      "singleCardperpel",
      "singleCardorange",
      "singleCardpink",
    ];
    return backgroundClasses[index % backgroundClasses.length];
  };
  return (
    <div class="card">
      <div class="card-body">
        <div class="card-title d-flex justify-content-between">{title}</div>
        {config?.map((conf, index) => {
          return (
            <div className={getBackgroundClass(index)}>
              <div>{conf.label}</div>
              <div> {dataval[conf.keyName] || 0}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default FacilityTypeView;
