import * as React from "react";
const SvgComplete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1.5em"
    height="1.5em"
    fill="#ffa100"
    viewBox="0 0 47 47"
    {...props}
  >
    <path d="M6.12 38.52V5.136h26.962v28.037l5.137-4.243V2.568A2.567 2.567 0 0 0 35.652 0h-32.1A2.567 2.567 0 0 0 .985 2.568v38.519a2.567 2.567 0 0 0 2.567 2.568H25.96l-3.63-5.135H6.12z" />
    <path d="M45.613 27.609a1.284 1.284 0 0 0-1.698-.057l-11.778 9.734-7.849-4.709a1.283 1.283 0 0 0-1.709 1.842l8.506 12.037c.238.337.625.539 1.037.543h.012a1.28 1.28 0 0 0 1.035-.525l12.6-17.173a1.287 1.287 0 0 0-.156-1.692zM27.306 8.988H11.897a2.567 2.567 0 0 0 0 5.136h15.408c1.418 0 2.566-1.15 2.566-2.568s-1.147-2.568-2.565-2.568zM27.306 16.691H11.897a2.567 2.567 0 0 0 0 5.136h15.408a2.566 2.566 0 0 0 2.566-2.568 2.564 2.564 0 0 0-2.565-2.568zM27.306 24.395H11.897a2.567 2.567 0 0 0 0 5.136h15.408a2.567 2.567 0 0 0 2.566-2.568 2.564 2.564 0 0 0-2.565-2.568z" />
  </svg>
);
export default SvgComplete;
