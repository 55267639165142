import { data } from 'jquery';
import React from 'react'
import { downloadFile,getDRAttributeValue } from "../../../../../../Components/UiComponents/utils";
import { useTranslation } from 'react-i18next';

const ReliefActivity = (props) => {
  let downloadAttachmentURL = process.env.REACT_APP_APP_SERVER_ADDRESS +"/"+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+"/download/download_attachments/?directoryPath=[DIRECTORY_PATH]&fileName=Relief_Activity.zip";
  const { t, i18n } = useTranslation(); 
  return (
    <div className="affectedtable-container">
      <div className="affectedtable-header-style">{t('reliefactivity')}</div>
      <div className="m-2">
        {/* <div className="row mainsection"> */}
        <div className="row section1 px-3 mt-3 mb-4">
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('noofrationpckts')}
            </div>
            <div>Completely : {getDRAttributeValue(props.data,'RAR_NUMBER_OF_DRY_RATION')}</div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('nofofoodpckts')}
            </div>
            <div>
              Humans : {getDRAttributeValue(props.data,'RAR_NUMBER_OF_FOOD_PACKETS')}
            </div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('noofpolythenedistributed')}
            </div>
            <div>{getDRAttributeValue(props.data,'RAR_NUMBER_OF_POLYTHENE_SHEETS')}</div>
          </div>
          
        </div>

        <div className="row section2 px-3 mt-3 mb-4">
          <div className="col-md-6">
            <div className="affected-population-labelstyle">
              {t('noofgrrecomendation')}
            </div>
            <div>{getDRAttributeValue(props.data,'RAR_AMOUNT_OF_GR')}</div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">{t('attachments')}</div>
            <div>
              {
              getDRAttributeValue(props.data,'attachment_path') != null ? (
              <div className="affected-population-labelstyle" role="button"
              onClick={() => downloadFile(downloadAttachmentURL.replace("[DIRECTORY_PATH]",getDRAttributeValue(props.data,'attachment_path')),'Relief_Activity_Report_'+new Date().getTime()+'.zip')} 
              >Download</div>
              ) : (
                "Not Available"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default ReliefActivity