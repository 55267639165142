import React, { useLayoutEffect } from "react";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";
import "./GridItem.css";

const GridItem = ({ layoutData }) => {
  const options = {
    staticGrid: true,
    column: 24,
  };

  useLayoutEffect(() => {
    var grid = GridStack.init(options);
  }, []);

  return layoutData.map((gridItem) => (
    <div
      className="grid-stack-item"
      gs-x={gridItem.x}
      gs-y={gridItem.y}
      gs-w={gridItem.width}
      gs-h={gridItem.height}
      key={gridItem.id}
    >
      <div
        className={`grid-stack-item-content ${
          gridItem?.haveNoBg ? " " : " grid-item"
        } ${gridItem?.haveOverflowVisible ? " apply__overflow--visible" : ""}`}
      >
        {gridItem.component}
      </div>
    </div>
  ));
};

export default GridItem;
