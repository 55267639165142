import React, { useEffect } from 'react'
import "./GisManagement.css";

const ReportBuilderManagement= () => {
    useEffect(()=>{
        window.location.href = process.env.REACT_APP_REPORTBUILDER_SERVICE+"/report/builder"
    })
  return (
    <div>Loading...</div>
  )
}

export default ReportBuilderManagement