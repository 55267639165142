import * as React from "react";
const SvgAwareness = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    fill="var(--white3-color)"
    viewBox="0 0 470 470"
    {...props}
  >
    <path d="m466.243 172.903-22.575-13a7.501 7.501 0 0 0-7.486 12.999L455 183.739v35.737l-18.818 10.836a7.5 7.5 0 0 0 7.486 12.999l22.575-13a7.502 7.502 0 0 0 3.757-6.5v-44.409a7.501 7.501 0 0 0-3.757-6.499zM409.925 29.257a7.5 7.5 0 0 0-7.5 7.5v18.475l-216.958 80.624H31.188C13.991 135.855 0 149.846 0 167.043v69.128c0 17.197 13.991 31.188 31.188 31.188h18.4v131.736c0 22.964 18.683 41.647 41.647 41.647h34.147a7.5 7.5 0 0 0 7.5-7.5v-94.818h4.344a7.5 7.5 0 0 0 0-15h-4.344v-20.533h4.344a7.5 7.5 0 0 0 0-15h-4.344V267.36h23.933a7.5 7.5 0 0 0 0-15H31.188c-8.926 0-16.188-7.262-16.188-16.188v-69.128c0-8.926 7.262-16.188 16.188-16.188h148.126V259.86a7.5 7.5 0 0 0 4.888 7.03l218.222 81.093v18.475c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V36.757a7.499 7.499 0 0 0-7.499-7.5zM117.882 425.743H91.235c-14.693 0-26.647-11.954-26.647-26.647v-96.204h53.294v122.851zm0-137.85H64.588V267.36h53.294v20.533zm76.433-33.247V148.569l208.11-77.335v260.748l-208.11-77.336z" />
  </svg>
);
export default SvgAwareness;
