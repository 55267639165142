import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Table from "../../../../Components/UiComponents/Table/Table";
import FLCKTable from "../PopupContent/FLCKTable";
import HLTHCamp from "../PopupContent/HLTHCamp";
import {
  Deleteicon,
  PaperClipIcon,
  XCircleblack,
} from "../../../../Components/IconComponents";
import {
  postDataWithBody,
  putDataWithBody,
} from "../../../../Services/Services";
import {
  deleteCKById,
  getFloodReliefData,
  getMealsDetailsURL,
} from "../../../../Services/EndPoints";
import { Modal } from "react-bootstrap";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { columnsForExport } from "../../../../JsonData/FloodReliefCampConfig";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { ThemeContext } from "../../../..";
import { useTranslation } from "react-i18next";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import { toast } from "react-toastify";
const FloodReliefCampTable = () => {
  const [floodReliefCampData, setFloodReliefCampData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [categoryCd, setCategoryCd] = useState(null);
  const { t } = useTranslation();
  const [headerText, setHeaderText] = useState("");
  const [ckStatusOpen, setCKStatusOpen] = useState(false);
  const [facilityReportId, setFacilityReportId] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState([]);
  useEffect(() => {
    getFloodReliefCampData();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = columnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const downloadFile = async (fileURL, fileName) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let headers = new Headers();
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("auth_token")
    );
    fetch(fileURL, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
      });
  };
  const getFloodReliefCampData = async () => {
    let input = { category_cd: "FLD_RLF_CMP" };
    var frList = await postDataWithBody(getFloodReliefData, input, {});
    setRowCount(frList.length);
    setFloodReliefCampData(frList);
    //sendDataToCentralStore(frList);
  };

  const getMealsDetailsData = async (mealTypeCd, facilityreportId) => {
    let inputobj = {
      facilityReportIds: [facilityreportId],
      facilityOfferingTypeCd: mealTypeCd,
    };
    var mealdataarr = await postDataWithBody(getMealsDetailsURL, inputobj, {});
    let totalAmount = 0;
    mealdataarr.forEach(function (data, node) {
      if (
        data.facility_report_attribute_value &&
        !isNaN(data.facility_report_attribute_value)
      ) {
        totalAmount += parseInt(data.facility_report_attribute_value);
      }
    });
    mealdataarr.push({
      attribute: "Total",
      facility_report_attribute_value: totalAmount,
    });
    setTableData(mealdataarr);
  };

  const hideModal = () => {
    setIsShowModal(false);
  };
  const openPopUP = (categoryCd, facilityreportId, ckStat) => {
    setCKStatusOpen(ckStat);
    setHeaderText(ckStat == true ? t("mealdetails") : t("healthcampdetails"));
    setCategoryCd(categoryCd);
    setFacilityReportId(facilityreportId);
    setIsShowModal(true);
    //getMealsDetailsData(mealTypeCd,facilityreportId);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: t("floodreliefcamptableheaders.slno"),
        accessor: "sno",
        disableSortBy: false,
        Cell: (props) => <span>{props.row.index + 1}</span>,
      },
      {
        Header: t("floodreliefcamptableheaders.facility"),
        accessor: "facility_name",
        disableSortBy: false,
      },
      {
        Header: t("floodreliefcamptableheaders.district"),
        accessor: "district_name",
        disableSortBy: false,
      },
      {
        Header: t("floodreliefcamptableheaders.block"),
        accessor: "block_name",
        disableSortBy: false,
      },

      {
        Header: t("floodreliefcamptableheaders.opendate"),
        accessor: "open_date",
        Cell: (props) => {
          const { open_date } = props.row.original;
          if (props.row.original.is_open) {
            return open_date;
          } else {
            return "NA";
          }
        },
        disableSortBy: false,
      },
      {
        Header: t("floodreliefcamptableheaders.closedate"),
        accessor: "close_date",
        Cell: (props) => {
          const { open_date } = props.row.original;
          if (!props.row.original.is_open) {
            return open_date;
          } else {
            return "NA";
          }
        },
        disableSortBy: false,
      },
      {
        Header: t("floodreliefcamptableheaders.operationaldate"),
        accessor: "operational_date",
        Cell: (props) => {
          if (props.value) {
            return props.value;
          } else {
            return "NA";
          }
        },
        disableSortBy: false,
      },

      {
        Header: t("floodreliefcamptableheaders.operational"),
        accessor: "operational_attribute",
        disableSortBy: false,
      },
      {
        Header: t("floodreliefcamptableheaders.totalmeals"),
        accessor: "total_meals",
        Cell: (props) => {
          return (
            <div
              className="text-center value-style"
              onClick={() => openPopUP("CK", props.row.original.id, true)}>
              {props.value}
            </div>
          );
        },
        disableSortBy: false,
      },
      {
        Header: t("floodreliefcamptableheaders.healthcamp"),
        Cell: (props) => {
          return (
            <div
              className="text-center value-style"
              onClick={() => openPopUP("FL", props.row.original.id, false)}>
              View
            </div>
          );
        },
        disableSortBy: true,
      },

      {
        Header: t("floodreliefcamptableheaders.action"),
        accessor: "attachment_path",
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { idVal: id };
            var res = await putDataWithBody(
              deleteCKById + "ck_delete",
              data,
              {}
            );
            if (res[0].status === "success") {
              getFloodReliefCampData();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };
          let downloadAttachmentURL =
            process.env.REACT_APP_APP_SERVER_ADDRESS +
            "/" +
            process.env.REACT_APP_CORE_CONTEXT_PATH_NAME +
            "/download/download_attachments/?directoryPath=" +
            props.row.original.attachment_path +
            "&fileName=Flood_Relief_Camp.zip";
          return (
            <div className="text-center d-flex align-items-center">
              {props.row.original &&
                props.row.original.attachment_path !== null && (
                  <span className="icon__position">
                    <div
                      onClick={() =>
                        downloadFile(
                          downloadAttachmentURL,
                          "Flood_Relief_Camp_" + new Date().getTime() + ".zip"
                        )
                      }>
                      <PaperClipIcon className="cursor-pointer" />
                    </div>
                  </span>
                )}
              <span
                className="ps-2 d-flex justify-center"
                onClick={deletePopUp}>
                <Deleteicon
                  value={props.value}
                  className="cursor-pointer mr-1"
                />
              </span>

              {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title="Confirm delete"
                  message="Are you sure you want to delete"
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.activity}
                  deleteRow={() => deleteTableDataById(props.row.original.id)}
                  cancel="Cancel"
                  action="Delete"
                />
              )}
            </div>
          );
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="container  create-user">
        <div className="datatable__container">
          {floodReliefCampData && (
            <Table
              data={floodReliefCampData}
              columns={columns}
              numberOfRows={7}
              setSearchData={setSearchData}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
            />
          )}
        </div>
      </div>
      <Modal show={isShowModal} id={theme} size="lg">
        <Modal.Header>
          <Modal.Title>{headerText}</Modal.Title>
          <XCircleblack onClick={hideModal} width="1.5em" height="1.5em" />
        </Modal.Header>
        <Modal.Body>
          {ckStatusOpen == true ? (
            <FLCKTable
              categoryCd={categoryCd}
              facilityReportId={facilityReportId}
            />
          ) : (
            <HLTHCamp
              categoryCd={categoryCd}
              facilityReportId={facilityReportId}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FloodReliefCampTable;
