import * as React from "react";
const SvgReload = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23 1.998v6h-6M1 17.998v-6h6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.567 6.664a1 1 0 0 0 1.886.667l-1.886-.667ZM18.36 3.638l-.707.707.022.022.685-.73Zm3.955 5.089a1 1 0 0 0 1.37-1.458l-1.37 1.458Zm-20.63 2.542a1 1 0 1 0-1.37 1.458l1.37-1.458Zm3.955 5.089.707-.707a.871.871 0 0 0-.022-.022l-.685.729Zm15.793-3.027a1 1 0 1 0-1.886-.667l1.886.667Zm-16.98-6a8 8 0 0 1 5.776-5.134L9.788.247a10 10 0 0 0-7.22 6.417l1.885.667Zm5.776-5.134a8 8 0 0 1 7.424 2.148l1.414-1.414A10 10 0 0 0 9.787.247l.442 1.95Zm7.446 2.17 4.64 4.36 1.37-1.458-4.64-4.36-1.37 1.458Zm-17.36 8.36 4.64 4.36 1.37-1.458-4.64-4.36-1.37 1.458Zm4.618 4.338a10 10 0 0 0 9.28 2.684l-.442-1.95a8 8 0 0 1-7.424-2.148l-1.414 1.414Zm9.28 2.684a10 10 0 0 0 7.22-6.418l-1.886-.667a8 8 0 0 1-5.776 5.134l.441 1.95Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgReload;
