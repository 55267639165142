import React from "react";

import { useState, useEffect } from "react";
import "./BlockDetails.css";
import { useTranslation } from "react-i18next";
import Table from "../../../Components/UiComponents/Table/Table";

const BlockDetails = (props) => {
  console.log("blockdata from Blockdetails component=", props.blockdata);
  const { t } = useTranslation();
  const [rowCount, setRowCount] = useState(0);
  const columns = React.useMemo(
    () => [
      {
        Header: "BlockName",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Value",
        accessor: "count",
        disableSortBy: true,
      },
    ],
    []
  );
  return (
    <div className="blockdetails">
      <div className="container  create-user">
        <div className="datatable__container">
          {props.blockdata && (
            <Table
              data={props.blockdata}
              popUpHeaderTitle={props.title}
              columns={columns}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              showRowCount={false}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                padding: "2px",
              }}
              applyTableHeaderCustomClass={true}
              disbalePaginationFilter={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BlockDetails;
