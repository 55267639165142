import React, { useState, createContext, useEffect, useCallback } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LayoutContext from "./Context/LayoutContext/LayoutContext";
import MapMarkerContext from "./Context/MapMarkerContext/MapMarkerContext";
import AppContext from "./Context/AppContext/AppContext";
import { Provider } from "react-redux";
import store from "./CentralStore/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import "./i18n";
import { getData } from "./Services/ProfileServices";
import { findRolesByUser, getThemeURL } from "./Services/EndPoints";
export const ThemeContext = createContext(null);
export const RoleContext = createContext();
const root = ReactDOM.createRoot(document.getElementById("root"));
 
function AppComponent() {
  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: "login-required" }}
      authClient={keycloak}
      onTokens={({ token }) => {
        localStorage.setItem("auth_token", token);
 
        localStorage.setItem("user_obj_data", JSON.stringify(keycloak));
      }}
    >
      <LayoutContextComponent />
    </ReactKeycloakProvider>
  );
}
 
function LayoutContextComponent() {
  const [theme, setTheme] = useState("dark");
  const [roleName, setRoleName] = useState("");
  const [initialized, setInitialized] = useState(false);
 
  useEffect(() => {
    keycloak.onReady = () => {
      setInitialized(true);
    };
  }, []);
 
  useEffect(() => {
    if (initialized) {
      getUserRoles();
    }
  }, [initialized]);
  useEffect(() => {
    const fetchThemeData = async () => {
      const themeval = await getThemeData();
      setTheme(themeval);
    };
    fetchThemeData();
  }, []);
  const getThemeData = async () => {
    try {
      const userProfile = await getData(getThemeURL); // Adjust the URL as needed
      return userProfile[0]?.theme;
    } catch (error) {
      console.error("Error fetching theme data:", error);
      return "dark"; // Default theme in case of error
    }
  };
 
  const getUserRoles = async () => {
    try {
      let rolesSanitized = [];
      let roleArr = await getData(findRolesByUser);
      roleArr.forEach((roleObj) => {
        rolesSanitized.push(roleObj.name);
      });
      rolesSanitized = rolesSanitized.join(",");
      if (rolesSanitized.length > 20) {
        rolesSanitized = rolesSanitized.substring(0, 20) + "...";
      }
      setRoleName(rolesSanitized);
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };
 
  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };
 
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <RoleContext.Provider value={{ roleName }}>
        <LayoutContext>
          <AppContext>
            <MapMarkerContext>
              <div id={theme}>
                <BrowserRouter>
                  <Provider store={store}>
                    <App />
                  </Provider>
                </BrowserRouter>
              </div>
            </MapMarkerContext>
          </AppContext>
        </LayoutContext>
      </RoleContext.Provider>
    </ThemeContext.Provider>
  );
}
 
root.render(<AppComponent />);