import React, { useEffect, useState } from "react";
import GridItem from "../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import ProfileImageComponent from "./ProfileImageComponent/ProfileImageComponent";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import { AuthDetailsConfig, authDetailsConfig } from "./PersonalDetails/PersonalDetailsConfig";
import { useSelector } from "react-redux";
import DMSHeader from "../../Pages/DMS/DMSHeader/DMSHeader";
import DetailsHandler from "../DMS/Common/DetailsHandler/DetailsHandler";
import { useTranslation } from 'react-i18next';

export default function UserProfile() {
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();
  const { profileData } = useSelector((store) => store.data);
  console.log(profileData)
  useEffect(() => {
    GridStack.init();
    setUserData(profileData);
  }, [profileData]);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: <DMSHeader title={t('user_profile')} showIconsForback={true} />,
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 4,
      height: 13,
      id: 2,
      component: <ProfileImageComponent profileData={userData && userData[0]} />,
    },
    {
      x: 4,
      y: 1,
      width: 8,
      height:4,
      id: 2,
      component: (
        <DetailsHandler
          title={t('authorized_detail')}
          details={userData && userData[0]}
          columns={2}
          config={AuthDetailsConfig()}
        />
      ),
    },
    {
      x: 4,
      y: 5,
      width: 8,
      height: 9,
      id: 2,
      component: <PersonalDetails userData={userData && userData[0]} />,
    },
  ];
  return (
    <div className="grid-stack grid-stack-instance-1 ">
      <GridItem layoutData={layoutData} />
    </div>
  );
}
