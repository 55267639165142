import React, { createRef, useState } from "react";
import "./MapWrapper.css";
import ModalComponent from "../ModalComponent/ModalComponent";
import SelectComponent from "../SelectComponent/SelectComponent";
import { Expand, DownlaodNew } from "../../IconComponents";
import { downLoadScreenShot } from "../utils";
//import Map from "../../../Pages/SmartPoles/SmartPolesMap/Map/Map";
import { useEffect } from "react";
import CustomMap from "../CustomMap/CustomMap";
const MapWrapper = (props) => {
  const {
    title, //string
    dropdownOptions, //array
    selectedOption, //string
    onChangeHandler, //function
    containerStyles = {}, //object
    headerStyles = {}, //object
    bodyStyles = {}, //object
    coordinates, // array,
    chooseCoordinates,
  } = props;
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const chooseCoordinates1 = (longitude, latitude) => {
    setLongitude(longitude);
    setLatitude(latitude);
  };

  useEffect(() => {
    {
      chooseCoordinates && chooseCoordinates1(longitude, latitude);
    }
  }, []);

  const ref = createRef();
  const [showExpand, setShowExpand] = useState(false);
  const expandHandler = () => {
    setShowExpand(!showExpand);
  };

  const renderMap = () => {
    return (
      coordinates?.length > 0 && (
        <CustomMap chooseCoordinates={chooseCoordinates} {...props} />
      )
    );
  };

  return (
    <div className="map__container" style={containerStyles}>
      <div className="map__container--header" style={headerStyles}>
        {title && <span className="map__header--text">{title}</span>}
        {onChangeHandler && (
          <SelectComponent
            options={dropdownOptions}
            value={selectedOption}
            onChange={onChangeHandler}
          />
        )}
        <div className="map__container--export">
          <div className="map__container--export--icon cursor-pointer">
            {/* <DownlaodNew
              width="1.2em"
              height="1.2em"
              onClick={() =>
                downLoadScreenShot(
                  ref, //ref
                  1, //scale
                  "MapView.png", //fileName
                  true //Flag to hide zoom controls
                )
              }
            /> */}
          </div>
          {/* <div onClick={expandHandler} className="cursor-pointer">
            <Expand fill="#fff" width="1.1em" height="1.1em" />
          </div> */}
        </div>
      </div>

      <div className="map__container--body" ref={ref} bodyStyles={bodyStyles}>
        {renderMap()}
      </div>

      {showExpand && (
        <ModalComponent
          title="Map View"
          show={showExpand}
          handleToggle={expandHandler}
          fullscreen={true}
          component={renderMap()}
        />
      )}
    </div>
  );
};

export default MapWrapper;
