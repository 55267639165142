import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { getData } from "../../../Services/Services";
import { useTranslation } from "react-i18next";
import { getBlockInfoFloodURL } from "../../../Services/EndPoints";
import { ThemeContext } from "../../..";
import { Viewicon } from "../../../Components/IconComponents";
import BlockPopUp from "./BlockPopUp";
import DistrictBlockDetails from "./DistrictBlockDetails";

const DoughnutChart = (props) => {
  const { theme } = useContext(ThemeContext);
  const [distDetails, setDistDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("Affected");
  const echartsDom = useRef(null);
  const { t } = useTranslation();
  const dataValue = props?.dataval && props?.dataval[0];
  const [popUp, setPopUp] = useState(false);
  const showPopUp = () => {
    setPopUp(true);
  };


  const getLegendColor = (theme) => {
    return theme === "light" ? "#2B6591" : "#fff";
  };

 
  const total = dataValue?.total_dist || 0;
  const affected = dataValue?.affected_dist_count || 0;
  const partiallyAffected = dataValue?.partially_affected || 0;
  const nonAffected = total - (affected + partiallyAffected);
  const handleToggle = (props) => {
    setPopUp(!popUp);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "District Name",
        accessor: "dist_name",
        disableSortBy: true,
        centerAlign: true,
      },
    ],
    []
  );
  const fetchData = async (apiKey) => {
    try {
      const response = await getData(
        getBlockInfoFloodURL
          .replace("TODATE", props.endDateStr)
          .replace("DISTID", 0)
          .replace("APIKEY", apiKey)
          .replace("FROMDATE", props.startDateStr)
      );
      setDistDetails(response);
    } catch (error) {
      console.error("Error fetching block data:", error);
    }
  };

  useEffect(() => {
    if (popUp) {
      if (activeTab === "Affected") {
        fetchData("get_affected_dists");
      } else {
        fetchData("get_partially_affected_dists");
      }
    }
  }, [activeTab, popUp]);

  useEffect(() => {
    const myChart = echarts.init(echartsDom.current);

    const options = {
      tooltip: {
        trigger: "item",
        confine: true,
      },
      legend: {
        top: "75%",
        left: "center",
        selectedMode: false,
        textStyle: {
          color: getLegendColor(theme),
        },
        formatter: (name) => {
          if (name === t("flooddisaster.doughnutchartlegend")) {
            return `${name} (${affected})`;
          } else if (name === t("flooddisaster.doughnutchartlegend1")) {
            return `${name} (${nonAffected})`;
          } else if (
            props.partial &&
            props.partial === true &&
            name === t("flooddisaster.doughnutchartlegend2")
          ) {
            return `${name} (${partiallyAffected})`;
          }
        },
      },
      title: {
        show: false,
        text: total,
        textStyle: {
          fontSize: 14,
          color: "white",
        },
        subtext: t("flooddisaster.doughnutchartcentertxt"),
        subtextStyle: {
          fontSize: 16,
          color: "white",
        },
        left: "center",
        top: "center",
      },
      series: [
        {
          bottom: "20%",
          color: ["#E95060", "#EB7F00", "#327910"],
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: affected, name: t("flooddisaster.doughnutchartlegend") },

            {
              value: partiallyAffected,
              name: t("flooddisaster.doughnutchartlegend2"),
            },
            {
              value: nonAffected,
              name: t("flooddisaster.doughnutchartlegend1"),
            },
          ],
        },
      ],
    };

    myChart.setOption(options);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [props.dataval, t, theme]);

  return (
    <div className="card" style={{ width: "100%", height: "100%" }}>
      <div className="card-body">
        <div
          className="card-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span>{props.title}</span>
          <span className="cursor-pointer" onClick={showPopUp}>
            {props.showViewIcon && <Viewicon />}{" "}
          </span>
        </div>
        <div ref={echartsDom} style={{ height: "90%", width: "100%" }} />
      </div>
      {popUp && (
        <BlockPopUp
          title="Affected District Details"
          show={popUp}
          handleToggle={handleToggle}
          component={
            <DistrictBlockDetails
              data={distDetails}
              column={columns}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
        />
      )}
    </div>
  );
};

export default DoughnutChart;
