import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast, ToastContainer } from "react-toastify";
import { masterresourceschema } from "../../../../JsonData/MasterResourceConfig";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import { MasterResourceFormConfig } from "../../../../JsonData/MasterResourceConfig";
import { getData, postDataWithBody } from "../../../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-spinner-loader";
import {
  createMasterResource,
  getMasterResourceByIdURL,
  resourceActivityListURL,
  resourceCatListURL,
} from "../../../../Services/EndPoints";
import { useTranslation } from "react-i18next";

const CreateMasterResourceRegForm = () => {
  const [masterresourceConfig, setMasterResourceConfig] = useState(
    MasterResourceFormConfig
  );
  const [resourceCategoriesList, setResourceCategoriesList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [masterCategory, setMasterCategory] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const { state } = useLocation();
  let id = state?.id;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(masterresourceschema[activeTab]),
    mode: "all",
  });

  const rscractivity = useWatch({
    control,
    name: "activity",
  });
  useEffect(() => {
    getInitFormData();
  }, [t]);
  useEffect(() => {
    const rscractivity = getValues("activity");
    if (rscractivity && rscractivity != "") {
      getCategoryByActivity(rscractivity);
    }
  }, [rscractivity]);
  const getInitFormData = async () => {
    setLoader(true);
    await getActivityList();
    setLoader(false);
  };

  const getActivityList = async () => {
    var activityList = await getData(resourceActivityListURL);
    for (let activity in activityList) {
      activityList[activity]["value"] = activityList[activity]["label"] =
        activityList[activity]["activity"];
    }
    masterresourceConfig[0].fields[0]["options"] = [
      { label: t('masterresourceplaceholder.select'), value: "" },
    ].concat(activityList);
    setMasterResourceConfig(masterresourceConfig);
    await getCategoryByActivity("");
  };

  const getCategoryByActivity = async (activity) => {
    if (activity != "") {
      var resourceCategoryList = await getData(resourceCatListURL + activity);
      for (let resourceCat in resourceCategoryList) {
        resourceCategoryList[resourceCat]["label"] =
          resourceCategoryList[resourceCat]["category"];
        resourceCategoryList[resourceCat]["value"] =
          resourceCategoryList[resourceCat]["category"];
      }
      masterresourceConfig[0].fields[1]["options"] = [
        { label: t('masterresourceplaceholder.select'), value: "" },
      ].concat(resourceCategoryList);
    } else {
      masterresourceConfig[0].fields[1]["options"] = [
        { label: t('masterresourceplaceholder.select'), value: "" },
      ];
      setMasterResourceConfig(masterresourceConfig);
    }
    setMasterResourceConfig(masterresourceConfig);
    setResourceCategoriesList(resourceCategoryList);
    if (id) {
      await getMasterResourceList();
    }
  };
  async function getMasterResourceList() {
    let masterResourceList = await postDataWithBody(
      getMasterResourceByIdURL,
      [id],
      {}
    );
    masterResourceList = masterResourceList[0];
    reset(masterResourceList);
    setMasterCategory(masterResourceList.category);
    //  id &&
    //    imageFields?.forEach((field) => {
    //      const imageId = masterResourceList[field];
    //      setValue(field, imageId);
    //    });
  }
  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        <FormHandler
          register={register}
          errors={errors}
          fields={fields}
          control={control}
          // onChange={onChange}
          setValue={setValue}
          columns={2}
          getValues={getValues}
        />
      </div>
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const submitHandler = async (data, e) => {
    setLoader(true);
    const formData = new FormData();
    console.log(rscractivity);
    for (let dataElem in data) {
      if (dataElem == "resource_image_file") {
        if (data[dataElem].length != 0) {
          formData.append(dataElem, data[dataElem][0]);
        }
      } else {
        formData.append(dataElem, data[dataElem]);
      }
    }
    // function mapOptions(data, field) {
    //   return data[field].map((type) => ({
    //     label: type,
    //     value: type,
    //   }));
    // }

    // const resource_image_file = ["resource_image_file"];
    //   const fieldValue = mapOptions(data, resource_image_file);
    //   setValue(resource_image_file, fieldValue);

    formData.append("status", true);

    let headers = { "Content-Type": "multipart/form-data" };
    var res = await postDataWithBody(createMasterResource, formData, headers);
    e.preventDefault();
    if (res.status == "success") {
      toast.success(res.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      console.log("data=", data);
      navigate("/master-resource");
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
  };

  const tabsData = generateTabsData(masterresourceConfig);

  return (
    <div className="mt-4">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="MasterResourceRegForm"
          >
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div className="registration__form--heading">
                        {tab.heading}
                      </div>
                      <div>{tab.component}</div>
                    </>
                  )}
                </>
              ))}
            </div>

            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                  {t('saveAndSubmit')}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateMasterResourceRegForm;
