import React from "react";
import "./HierarchySelect.css";
import HierarchyDropdown from '../../../../../Components/UiComponents/HierarchyDropdown/HierarchyDropdown';
import { useTranslation } from 'react-i18next';


import 'react-dropdown-tree-select/dist/styles.css'
import { Controller } from "react-hook-form";
const HierarchySelect = ({
  name,
  label,
  register,
  errors,
  options,
  control,
  mandatory,
  onChange
}) => {

  const { t } = useTranslation();

  return (
    <div className="input_text-form">
      <label>
        {t(label)}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, } }) => (
          <HierarchyDropdown
            data={options ? options : null}
            className={
              errors[name]
                ? "form-select  form__select--errorborder"
                : ""
            }
            value={value}
            onChange={onChange}
            id={name}
          />
        )}
      />
      <div className="error__message">{errors[name]?.message}</div>
    </div>
  );
};

export default HierarchySelect;
