import * as React from "react";
const SvgAnimalShelterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 47 47"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="m24.363 36.89.015.004c.571.133 1.196.278 1.78.278.64 0 1.181-.19 1.563-.57.381-.38.574-.918.574-1.556 0-2.629-2.292-4.737-5.07-4.737-2.78 0-5.071 2.108-5.071 4.737 0 .636.188 1.175.563 1.554.375.38.908.572 1.539.572.561 0 1.168-.14 1.722-.268l.017-.004c.48-.111.93-.214 1.23-.214.254 0 .68.097 1.137.204Zm-2.829-1.979c-.492.114-.957.22-1.278.22l-.058-.001a1.106 1.106 0 0 1-.003-.084c0-1.462 1.333-2.697 3.03-2.697 1.695 0 3.029 1.235 3.029 2.697 0 .032-.001.06-.003.082a1.167 1.167 0 0 1-.093.004c-.342 0-.824-.111-1.332-.23l-.017-.004c-.54-.126-1.087-.253-1.585-.253-.54 0-1.133.137-1.674.262l-.016.004Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="M18.729 31.797c1.33 0 2.347-1.254 2.347-2.71 0-1.455-1.017-2.71-2.347-2.71s-2.348 1.255-2.348 2.71c0 1.456 1.017 2.71 2.348 2.71Zm-.307-2.71c0-.218.061-.4.14-.52.085-.132.158-.148.167-.148.008 0 .08.016.166.148a.97.97 0 0 1 .14.52.97.97 0 0 1-.14.521c-.085.132-.158.148-.166.148v.25-.25c-.009 0-.082-.016-.167-.148a.97.97 0 0 1-.14-.52Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="M23.364 30.275c1.33 0 2.347-1.254 2.347-2.71 0-1.455-1.017-2.709-2.347-2.709s-2.347 1.255-2.347 2.71 1.017 2.71 2.347 2.71Zm0-3.378c.009 0 .081.016.167.149a.97.97 0 0 1 .14.52.97.97 0 0 1-.14.52c-.086.133-.158.149-.167.149-.008 0-.081-.016-.167-.148a.97.97 0 0 1-.14-.521c0-.218.062-.4.14-.52.086-.133.159-.149.167-.149Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="M28 26.378c-1.33 0-2.348 1.254-2.348 2.71 0 1.455 1.017 2.709 2.348 2.709 1.33 0 2.347-1.254 2.347-2.71 0-1.455-1.017-2.71-2.347-2.71Zm0 3.378c-.009 0-.082-.016-.167-.148a.97.97 0 0 1-.14-.52c0-.219.061-.4.14-.521.085-.132.158-.148.167-.148.008 0 .08.016.166.148a.97.97 0 0 1 .14.52.97.97 0 0 1-.14.52c-.085.133-.158.149-.166.149Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="m42.24 20.873 1.663-2.224a.639.639 0 0 0-.13-.892L23.929 2.917a.627.627 0 0 0-.379-.126h-.04l18.73 18.082Zm0 0a.63.63 0 0 1-.512.256.63.63 0 0 1-.38-.127m.892-.129-.892.129m0 0L24.11 8.112m17.237 12.89L24.11 8.112m0 0a1.02 1.02 0 0 0-1.222 0m1.222 0H22.89m0 0L5.652 21.002a.629.629 0 0 1-.38.127.631.631 0 0 1-.512-.256L3.097 18.65a.639.639 0 0 1 .13-.893m19.662-9.645L3.226 17.757m0 0 19.846-14.84M3.226 17.756l19.846-14.84m0 0a.633.633 0 0 1 .388-.126m-.388.125.388-.125m0 0h.001m0 0h.018m-.018 0h.018m0 0h.012-.012Zm1.671-1.51a2.658 2.658 0 0 0-1.602-.53h-.01L23.525.75h-.015a.83.83 0 0 0-.017 0h-.041c-.582 0-1.137.184-1.602.532L2.004 16.123a2.681 2.681 0 0 0-.541 3.749l1.663 2.223a2.69 2.69 0 0 0 2.995.936v20.54a2.681 2.681 0 0 0 2.678 2.679h29.402a2.681 2.681 0 0 0 2.678-2.678v-20.54a2.69 2.69 0 0 0 2.995-.936l1.663-2.224a2.681 2.681 0 0 0-.54-3.749l-.15.2.15-.2L25.15 1.282ZM38.2 44.21H8.8a.638.638 0 0 1-.638-.637V21.673L23.5 10.203l15.338 11.47v21.899a.638.638 0 0 1-.637.637Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="M23.78 42.515a.769.769 0 0 1-.596-.015l-.103.228c-.144-.065-3.361-1.525-6.63-4.039l.153-.198-.153.198c-1.94-1.493-3.5-3.055-4.628-4.643-1.45-2.043-2.197-4.144-2.197-6.243 0-4.505 3.665-8.17 8.17-8.17a8.1 8.1 0 0 1 5.704 2.32l11.477 12.263c-1.114 1.602-2.66 3.139-4.592 4.569-3.259 2.41-6.47 3.677-6.605 3.73Zm0 0 .091.233-.09-.233Zm.534-18.406a6.083 6.083 0 0 1 4.89-2.434c3.379 0 6.129 2.75 6.129 6.128 0 3.685-2.468 6.704-5.228 8.926-2.658 2.138-5.538 3.495-6.579 3.95-1.038-.51-3.932-2.022-6.605-4.23-2.78-2.297-5.254-5.29-5.254-8.646 0-3.379 2.75-6.128 6.129-6.128 1.937 0 3.719.886 4.89 2.434a1.02 1.02 0 0 0 1.628 0Z"
    />
  </svg>
);
export default SvgAnimalShelterIcon;
