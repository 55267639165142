import React, { useEffect, useState, useContext } from "react";
import "./Sidebar.css";
import IresponseFireLogo from "../../Assets/Icons/IresponseFireLogo.svg";
import IresponseFireLogoDark from "../../Assets/Icons/IresponseFireLogoDark.svg";
import IResponse_LogoWhite from "../../Assets/Icons/IResponse_LogoWhite.svg";
import IResponse_Logo from "../../Assets/Icons/IResponse_Logo.svg";
import navLinks from "../../JsonData/navLinksData";
import { LayoutState } from "../../Context/LayoutContext/LayoutContext";
import { useNavigate } from "react-router-dom";
import NavItem from "../NavItem";
import { useKeycloak } from "@react-keycloak/web";
import { appserveraddr } from "../../Services/EndPoints";
import { Modal, Button } from "react-bootstrap";
import { getData } from "../../Services/Services";
import {
  getStoredFormTemplates,
  findPermissionsByUser,
  findRolesByUser,
} from "../../Services/EndPoints";
import Signout from "../Signout/Signout";
import { ThemeContext } from "../..";
const Sidebar = () => {
  const { collapsed, toggle } = LayoutState();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [navigableLinks, setNavigableLinks] = useState(navLinks);
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    addReportMenu();
  }, []);

  const sanitizeMenus = async (navLinkArr) => {
    let permissionArr = await getData(findPermissionsByUser);
    let permissionArrIdList = [];
    let childrenPermissionArrIdList = [];
    permissionArr.forEach(function (permission) {
      permissionArrIdList.push(permission.parent_id);
      childrenPermissionArrIdList.push(permission.id);
    });

    //Retain the parent menus as per role
    let navLinkSanitised = [];
    navLinkArr.forEach(function (navItem) {
      if (navItem["allowedPermissionIds"]) {
        if (
          navItem["allowedPermissionIds"].some((ai) =>
            permissionArrIdList.includes(ai)
          )
        ) {
          navLinkSanitised.push(navItem);
        }
      } else {
        navLinkSanitised.push(navItem);
      }
    });

    //Retain the children menus as per role
    navLinkSanitised.forEach(function (navItem) {
      if (navItem.children) {
        let navlinkChildrenTemp = navItem.children;
        let childrenArr = [];
        navlinkChildrenTemp.forEach(function (navChildren) {
          if (navChildren["allowedPermissionIds"]) {
            if (
              navChildren["allowedPermissionIds"].some((ai) =>
                childrenPermissionArrIdList.includes(ai)
              )
            ) {
              childrenArr.push(navChildren);
            }
          } else {
            childrenArr.push(navChildren);
          }
        });
        navItem["children"] = childrenArr;
      }
    });
    setNavigableLinks(navLinkSanitised);
  };
  const addReportMenu = async () => {
    var data = await getData(getStoredFormTemplates);
    let formStructArr = [];
    data.forEach(function (dataElems) {
      formStructArr.push({
        to: "../../report-view/" + dataElems.form_name,
        label: dataElems.form_name,
      });
    });
    navLinks[2].to = "../report-view/" + formStructArr[0].to;
    navLinks[2].children = formStructArr;
    sanitizeMenus(navLinks);
  };

  return (
    <div className={`${collapsed ? "sb-collapsed" : "sidebar"}`}>
      <div
        className={` mt-3 sidebar__top ${
          collapsed ? " sidebar__top--collapsed" : null
        }`}
      >
        {!collapsed ? (
          <img
            src={theme === "light" ? IResponse_Logo : IResponse_LogoWhite}
            alt="Prutech-logo"
            className={`${collapsed ? "logo-collapsed" : "logo"}`}
          />
        ) : (
          <img
            src={theme === "light" ? IresponseFireLogoDark : IresponseFireLogo}
            alt="Prutech-logo"
          />
        )}
      </div>
      <div className="sidebar__content">
        <div className="menu">
          <ul className={`nav__list ${collapsed && "nav__list--collapsed"}`}>
            {navigableLinks.map((item, index) => (
              <NavItem item={item} index={index} key={index} />
            ))}
          </ul>
        </div>

        {/* <div
          className={`sidebar__bottom ${collapsed && " logout"}`}
          onClick={invokeModal}
        >
          <span className="logoutLink">
            <Logout width="1.2em" height="1.2em" />
            {!collapsed ? "Sign Out" : null}
          </span>
        </div> */}
      </div>
      {/* <Modal show={isShow}>
        <Modal.Header closeButton onClick={hideModal}></Modal.Header>
        <Modal.Body>
          <div className="container  exit_btns_container">
            <div className="row mt-2 mb-3">
              <div className="col-6">
                <div
                  className={`exit_btns sidebar__bottom ${
                    collapsed && " logout"
                  }`}
                  onClick={() => {
                    localStorage.clear();
                    keycloak.logout({ redirectUri: appserveraddr });
                  }}
                >
                  Logout
                </div>
              </div>
              <div className="col-6">
                This will log you out from all the applications.
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-6">
                <div
                  className="exit_btns"
                  onClick={() => {
                    window.location.href = process.env.REACT_APP_COMMON_DASHBOARD_URL;
                  }}
                >
                  Exit Current
                </div>
              </div>
              <div className="col-6">
                This will exit your current application.
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Sidebar;
