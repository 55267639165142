import React from "react";
import { Button } from "react-bootstrap";
import ExpandTable from "../TableComponents/ExpandTable/ExpandTable";
import ExportTableData from "../TableComponents/ExportTableData/ExportTableData";
import FilterBtnGroup from "../TableComponents/FilterBtnGroup/FilterBtnGroup";
import LMSTotalSales from "../TableComponents/LMSTotalSales/LMSTotalSales";
import ResetTableData from "../TableComponents/ResetTableData/ResetTableData";
import SearchBox from "../TableComponents/SearchBox/SearchBox";
import TableTitle from "../TableComponents/TableTitle/TableTitle";
import "./TableHeader.css";

const TableHeader = (props) => {

  const {
    title,
    searchValue,
    onSearchHandler,
    showExport = true,
    showReset = true,
    onExpand,
    showFilter,
    addNewHandler,
    addBlockHandler,
    addUnBlockHandler,
    showSalesTotal,
    showProcurementTotal,
    addSalesTotalValue,
    showSearch = true,
    text,
    status,
    id,
    addButtonLabel,
    addButtonLabelBlock,
    addButtonLabelUnBlock = "Unblock"
  } = props;


  return (
    <div className="iccc__table-header">
      <div className="d-flex align-items-center">
        {addNewHandler && (
          <div className="table_header__addnewbutton">
            <Button size="btn_sm" variant="primary" onClick={addNewHandler}>
              {addButtonLabel}
            </Button>
          </div>
        )}
        {title && <TableTitle title={title} />}
      </div>
      {showFilter && <div className="filter__Button">
        <FilterBtnGroup {...props} />
      </div>
      }
      <div className="table-search">
        {showSearch && (
          <SearchBox
            searchValue={searchValue}
            onSearchHandler={onSearchHandler}
          />
        )}
        {showReset && <ResetTableData {...props} />}

        {status === "Blocked" ? (
          <div className="table_header__addblockbutton">
            <Button size="sm" variant="success" onClick={addUnBlockHandler}>
              {addButtonLabelUnBlock}
            </Button>
          </div>
        ) : (
          id && (
            <div className="table_header__addblockbutton">
              {addBlockHandler && (
                <Button size="sm" variant="success" onClick={addBlockHandler}>
                  {addButtonLabelBlock}
                </Button>
              )}
            </div>
          )
        )}

        {showSalesTotal && (
          <LMSTotalSales addSalesTotalValue={addSalesTotalValue} />
        )}
        {showExport && <ExportTableData {...props} />}
        {onExpand && <ExpandTable {...props} />}
      </div>
    </div>
  );
};

export default TableHeader;
