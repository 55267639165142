import * as React from "react";
const SvgExpand = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#expand_svg__a)" fill="currentColor">
      <path d="M18.19.02H.806A.807.807 0 0 0 0 .826v17.348c0 .445.361.806.806.806h17.348a.866.866 0 0 0 .846-.766V.83a.813.813 0 0 0-.81-.81Zm-.806 17.388H1.572V1.596h15.816v15.812h-.004Z" />
      <path d="M8.672 14.95c0 .445.361.806.806.806h5.448c.445 0 .806-.361.846-.806V9.502a.807.807 0 0 0-1.612 0v4.638H9.478a.81.81 0 0 0-.806.81ZM4.03 10.308c.405 0 .767-.361.767-.806V4.864h4.681a.807.807 0 0 0 0-1.612H4.03a.807.807 0 0 0-.806.806v5.444a.81.81 0 0 0 .806.806Z" />
    </g>
    <defs>
      <clipPath id="expand_svg__a">
        <path fill="currentColor" d="M0 0h19v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgExpand;
