import React, { useContext, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../..";

const HorizontalBarChart = (props) => {
  const { theme } = useContext(ThemeContext);
  const echartsDom = useRef(null);
  const { t } = useTranslation();
  const dataValue = props?.dataval && props?.dataval[0];

  const getMatchingKey = (keys, patterns) => {
    for (let key of keys) {
      for (let pattern of patterns) {
        if (key.includes(pattern)) {
          return key;
        }
      }
    }
    return null;
  };

  const getLegendColor = (theme) => {
    return theme === "light" ? "#2B6591" : "#fff";
  };

  const keys = Object?.keys(dataValue || {});
  const totalKey = getMatchingKey(keys, [
    "total_blocks",
    "total_dist",
    "total_municipal_bodies",
    "total_gram_panchyats",
  ]);
  const affectedKey = getMatchingKey(keys, [
    "affected_blocks",
    "affected_dist_count",
    "affected_municipal_bodies",
    "affected_gram_panchayats",
  ]);

  const total = dataValue?.[totalKey] || 0;
  const affected = dataValue?.[affectedKey] || 0;
  const nonAffected = total - affected;
  const partiallyAffected = dataValue?.[totalKey] || 0;

  useEffect(() => {
    const myChart = echarts.init(echartsDom.current);

    const options = {
      tooltip: {
        trigger: "axis",
        confine: true,
      },
      legend: {
        bottom: "1%",
        left: "center",

        selectedMode: false,
        textStyle: {
          color: getLegendColor(theme), // Legend text color
        },
        formatter: (name) => {
          if (name === t("flooddisaster.doughnutchartlegend")) {
            return `${name} (${affected})`;
          } else if (name === t("flooddisaster.doughnutchartlegend1")) {
            return `${name} (${nonAffected})`;
          }
        },
      },
      xAxis: {
        type: props.title === "Affected Municipalities" ? "value" : "category",
        axisLabel: {
          color: "#b3b4b6",
          show: false,
        },

        axisLine: {
          lineStyle: {
            color: "#b3b4b6",
          },
        },
        splitLine: {
          show: false, // Hide split lines
        },
      },
      yAxis: {
        type: props.title === "Affected Municipalities" ? "category" : "value",
        axisLabel: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#b3b4b6",
          },
        },
        splitLine: {
          show: false, // Hide split lines
        },
      },
      grid: {
        left: "5",
        bottom: "25%",
        top: "5%",
      },
      series: [
        {
          name: t("flooddisaster.doughnutchartlegend"),
          type: "bar",
          data: [affected],
          itemStyle: {
            color: "#E95060", // Color for the "affected" bar
          },
          barWidth: "20", // Adjust bar width as needed
        },
        {
          name: t("flooddisaster.doughnutchartlegend1"),
          type: "bar",
          data: [nonAffected],
          itemStyle: {
            color: "#327910", // Color for the "non-affected" bar
          },
          barWidth: "20", // Adjust bar width as needed
        },
      ],
    };

    myChart.setOption(options);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, [props.dataval, t, theme]);

  return (
    <div className="card" style={{ width: "100%", height: "100%" }}>
      <div className="card-body">
        <div
          className="card-title"
          style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{props.title}</span>
          <span>{affected}</span>
        </div>
        <div ref={echartsDom} style={{ height: "90%", width: "100%" }} />
      </div>
    </div>
  );
};

export default HorizontalBarChart;
