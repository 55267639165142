import * as yup from "yup";
export const usertaskmgntschema = [
  yup.object({
    name: yup.string().required("Please provide the name"),
  }),
];

export const TaskMgntFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "name",
        label: "taskmgmntformlabels.name",
        type: "input",
        mandatory: "true",
      },

      {
        name: "task_type",
        label: "taskmgmntformlabels.tasktype",
        type: "select",
        options: [
          { value: "0", label: "Activity" },
          { value: "1", label: "Notification" },
        ],
      },
      {
        name: "assign_type",
        label: "taskmgmntformlabels.assigntype",
        type: "select",
        options: [
          { value: "0", label: "Group" },
          { value: "1", label: "Individual" },
        ],
      },
      {
        name: "role_list",
        label: "taskmgmntformlabels.rolelist",
        type: "multi_select",
        mandatory: "true",
      },
      {
        name: "user_list",
        label: "taskmgmntformlabels.userlist",
        type: "multi_select",
        mandatory: "true"
      },

      {
        name: "description",
        label: "taskmgmntformlabels.summary",
        type: "text_area",
      },
    ],
  },
];
