import * as React from "react";
const SvgDocicon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.725 1.743v24.433h19.212V6.098H16.15V1.743H1.725Zm16.15 1.081.01 1.54h1.665l-1.675-1.542v.002Z"
      fill="#17A3A0"
    />
    <path
      d="M17.364 0H1.744A1.75 1.75 0 0 0 0 1.746v24.432a1.75 1.75 0 0 0 1.745 1.745h19.197a1.75 1.75 0 0 0 1.746-1.745V4.843L17.364 0Zm.524 2.836 1.658 1.527h-1.658V2.836ZM1.745 26.178V1.746h14.397v4.362h4.799v20.07H1.745Z"
      fill="#23C4C0"
    />
    <path
      d="M19.198 12.462H3.49v1.746h15.708v-1.746ZM5.94 3.42a1.896 1.896 0 0 0-.742-.524 2.727 2.727 0 0 0-1.09-.174H2.404v5.104h1.702a2.207 2.207 0 0 0 1.746-.654c.21-.228.373-.495.48-.786.095-.355.14-.722.133-1.09a3.817 3.817 0 0 0-.133-1.048 2.096 2.096 0 0 0-.393-.828Zm-.61 2.442a1.344 1.344 0 0 1-.219.524 1.03 1.03 0 0 1-.399.348c-.205.095-.429.14-.654.133h-.56v-3.23h.61a1.16 1.16 0 0 1 .96.4c.1.147.173.309.219.48.053.229.082.463.087.698.01.217-.006.435-.046.649l.001-.002ZM10.65 3.42a1.82 1.82 0 0 0-.698-.568 2.275 2.275 0 0 0-1.004-.218c-.36 0-.717.075-1.047.218a2.22 2.22 0 0 0-.698.568c-.177.253-.311.533-.4.828a3.912 3.912 0 0 0 0 2.007c.08.299.215.58.4.828.169.256.412.455.698.568.329.148.686.222 1.047.218a1.884 1.884 0 0 0 1.615-.698c.419-.549.62-1.232.567-1.92a3.72 3.72 0 0 0-.133-1.004 1.774 1.774 0 0 0-.347-.827Zm-.916 3.095a.937.937 0 0 1-.828.436.855.855 0 0 1-.829-.436 2.488 2.488 0 0 1-.265-1.266 2.38 2.38 0 0 1 .266-1.265 1.072 1.072 0 0 1 1.658 0c.198.39.29.827.265 1.265a2.38 2.38 0 0 1-.267 1.266ZM14.269 6.474a.955.955 0 0 1-.44.392.85.85 0 0 1-.35.044.764.764 0 0 1-.742-.4 2.38 2.38 0 0 1-.266-1.265v-.348c0-.133.044-.266.044-.4.044-.132.044-.265.087-.349a.703.703 0 0 1 .175-.306c.077-.085.167-.159.266-.218a.822.822 0 0 1 .35-.088.754.754 0 0 1 .567.219c.136.152.24.33.306.523l1.004-.265a2.091 2.091 0 0 0-.306-.699 2.069 2.069 0 0 0-.436-.436 2.505 2.505 0 0 0-2.138-.044c-.256.141-.48.335-.655.568a1.718 1.718 0 0 0-.217.872 3.579 3.579 0 0 0-.088.96c.005.322.035.643.088.96.062.296.18.577.348.829.163.245.389.44.654.568.314.148.657.222 1.004.218.433.01.86-.11 1.222-.349.166-.122.313-.27.436-.436a4.44 4.44 0 0 0 .35-.654l-1.048-.266a1.21 1.21 0 0 1-.215.37ZM14.398 23.675H3.49v1.746h10.908v-1.746ZM19.198 20.307H3.49v1.745h15.708v-1.745ZM19.198 16.317H3.49v1.746h15.708v-1.746ZM19.27 8.605H3.564v1.746h15.708V8.605Z"
      fill="#F3F3F3"
    />
  </svg>
);
export default SvgDocicon;
