import * as React from "react";
const SvgDownlaodNew = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.596 10.863V.83M10.905 8.423l-2.308 2.44-2.309-2.44"
      stroke="currentColor"
      strokeWidth={1.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.264 4.773h.738c1.611 0 2.917 1.374 2.917 3.07v4.07c0 1.692-1.302 3.063-2.91 3.063H4.19c-1.61 0-2.917-1.375-2.917-3.07V7.834c0-1.691 1.303-3.062 2.91-3.062h.745"
      stroke="currentColor"
      strokeWidth={1.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgDownlaodNew;
