import React from "react";
import "./LMSTotalSales.css";

const LMSTotalSales = ({ addSalesTotalValue }) => {
  return (
    <div>
      <div className="d-flex">
        <label
          htmlFor="totalsales"
          className="salesdata__totalsales--labelstyle"
        >
          TotalSales (Tonnes):
        </label>
        <div className="totalsalesvalue__container">{addSalesTotalValue()}</div>
      </div>
    </div>
  );
};

export default LMSTotalSales;
