// import React, { useEffect } from "react";
// import Modal from "react-bootstrap/Modal";
// import "./PreviewFile.css";
// import { Rejectedicon } from "../../../../../Components/IconComponents";
// import { getData } from "../../../Services/Services";

// const PreviewFile = (props) => {
//   const { title, show, previewHandler, file } = props;
//   console.log(file, "image");

//   useEffect(() => {
//     // getData(getMediaFileContent + "/" + id)
//     //   .then((response) => {
//     //     console.log("mediaaa response", response);
//     //     // setFileObj(response);
//     //   })
//     //   .catch((error) => console.log(error));
//   }, []);

//   return (
//     <>
//       <Modal
//         show={show}
//         onHide={previewHandler}
//         className="file__preview--popup"
//         centered
//       >
//         <Modal.Header>
//           <Modal.Title className="preview_title">{title}</Modal.Title>
//           <Rejectedicon onClick={previewHandler} width="1.5em" height="1.5em" />
//         </Modal.Header>
//         <Modal.Body>
//           <div>
//             <img
//               className={
//                 file.id ? "preview_file_space_id" : "preview__file--space"
//               }
//               src={
//                 file.id
//                   ? `http://192.168.0.3:9080/lms/media/file/${file.id}`
//                   : URL.createObjectURL(file)
//               }
//               alt="Preview"
//             />
//           </div>
//           <div className="preview__filename">{file?.name}</div>
//           <div className="preview__ok">
//             <button class="btn " type="button" onClick={previewHandler}>
//               Ok
//             </button>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default PreviewFile;

import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import "./PreviewFile.css";
import { Rejectedicon } from "../../../../../Components/IconComponents";

import { useSelector } from "react-redux";

const PreviewFile = (props) => {
  const { title, show, previewHandler, file } = props;
  // const { presentTheme } = useSelector((state) => state.appSettings);

  const handleDragStart = (e) => {
    e.preventDefault(); // Disable drag behavior on the image element
  };
  const preventClose = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating
  };
  return (
    <>
      <Modal
        show={show}
        onHide={previewHandler}
        centered
        // id={presentTheme}
        size="lg"
        className="modal__header--images"
        backdrop="static" // Prevent modal from closing on backdrop click
        // keyboard={false} // Prevent modal from closing on ESC key press
      >
        <Modal.Header className="image__modal ">
          <Modal.Title className="preview_title">{title}</Modal.Title>
          <Rejectedicon
            onClick={previewHandler}
            width="1.5em"
            height="1.5em"
            className="cursor-pointer"
          />
        </Modal.Header>
        <Modal.Body>
          <img
            className="preview__image"
            src={
              file?.id
                ? `http://localhost:7080/dms-service/media/file/${file.id}`
                : URL.createObjectURL(file)
            }
            alt="Preview"
            onDragStart={handleDragStart} // Disable drag behavior
            onClick={preventClose} // Prevent modal from closing on image click
          />
          <div className="preview__filename">{file?.name}</div>
        </Modal.Body>
        <Modal.Footer className=" text-center">
          <div className="preview__ok">
            <button class="btn " type="button" onClick={previewHandler}>
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PreviewFile;
