import React, { useEffect, useRef } from "react";
import Moment from "react-moment";
import {
   SOPInfoIcon
} from "../../../../Components/IconComponents";
import "../SOPManagement.css"
const SOPInfo = (props) => {
    return (
        <div className="container grid-stack grid-stack-instance-1 smartpole__header--transition">
           
    <div class="row">
        <div class="col-md-10 mx-auto ">
            <form class="mt-3 ps-3">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="name"  class="sop_label">Name</label>
                        <div class="sop_info_text">{props.sopInstanceData.name}</div>
                    </div>
                    <div class="col-sm-5">
                        <label for="description"  class="sop_label">Description</label>
                        <div class="sop_info_text">{props.sopInstanceData.description}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6 mt-3">
                        <label for="createdOn" class="sop_label">Created On</label>
                        <div class="sop_info_text">
                          <Moment format="DD/MM/YYYY, hh:mm a">{props.sopInstanceData.created_on}</Moment>
                        </div>
                    </div>
                    <div class="col-sm-5 mt-3">
                        <label for="updated_on"  class="sop_label">Last Updated On</label>
                        <div class="sop_info_text">
                          <Moment format="DD/MM/YYYY, hh:mm a">{props.sopInstanceData.updated_on}</Moment>
                        </div>
                    </div>
                    
                </div>
               
                
            </form>
        </div>
        <div class="col-md-2" ><div class="mt-4"><SOPInfoIcon /></div></div>
    </div>
</div>
    
    );
};

export default SOPInfo;
