// import React from "react";
// import { useState, useEffect } from "react";
// import "./FileUpload.css";
// import {
//   setDataTocontext,
//   getContextForkeyTest,
// } from "../../../Common/ContextDto";
// import {
//   Upload,
//   Rejectedicon,
//   Jpgicon,
//   Pdficon,
//   Docicon,
// } from "../../../../../Components/IconComponents";
// import PreviewFile from "../../Form/PreviewFile/PreviewFile";
// import { getData } from "../../../Services/Services";
// import { useTranslation } from "react-i18next";
// const FileUpload = ({
//   label,
//   name,
//   errors,
//   register,
//   mandatory,
//   getValues,
//   id,
// }) => {
//   console.log("name=",name);
//   const { t } = useTranslation();
//   const [fileObj, setFileObj] = useState(null);

//   const [ispreview, setIspreview] = useState(false);
//   const { onChange, ...registerParams } = register(name);
//   const handleChange = (filename, event) => {
//     const file = event.target.files[0];

//     if (
//       file.type === "application/pdf" ||
//       file.type === "image/png" ||
//       file.type === "image/jpg" ||
//       file.type === "image/jpeg" ||
//       file.type === "application/msword" ||
//       file.type ===
//         "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//     ) {
//       if (file.size < 15000000) {
//         setFileObj(event.target.files[0]);
//         setIspreview(false);
//         setDataTocontext(filename, file);
//       }
//     }
//   };
//     const values = getValues(name);
//     console.log("swat",values);

//   // useEffect(() => {
//   //   getData(getMediaFile + "/" + id)
//   //     .then((response) => {
//   //       console.log("media response", response);
//   //       setFileObj(response);
//   //     })
//   //     .catch((error) => console.log(error));
//   // }, []);
//   const previewHandler = () => setIspreview(() => !ispreview);
//   const handleDragOver = (e) => e.preventDefault();

//   // A funtion to handle file drop

//   const handleDrop = (e) => {
//     e.preventDefault();
//     const file = e.dataTransfer.files[0];
//     setFileObj(file);
//   };

//   // A function to convert the bytes in to other size units

//   const convertFileSize = (bytes) => {
//     const sizes = ["B", "KB", "MB", "GB", "TB"];
//     if (bytes === 0) return "0 B";
//     const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
//   };

//   // Trigger the file input click event

//   const handleClick = () => {
//     const fileInputTriger = document.getElementById(name);
//     fileInputTriger.click();
//   };

//   // A funtion to return the icon based on the file type

//   const generateIconByType = (type) => {
//     let icon;
//     if (type === "application/pdf") {
//       icon = <Pdficon />;
//     } else if (
//       type === "image/png" ||
//       type === "image/jpg" ||
//       type === "image/jpeg"
//     ) {
//       icon = <Jpgicon />;
//     } else if (
//       type === "application/msword" ||
//       type ===
//         "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
//       type === "docx" ||
//       type === "doc"
//     ) {
//       icon = <Docicon />;
//     }
//     return icon;
//   };

//   return (
//     <div className="upload">
//       <label>
//         {t(label)}
//         {mandatory && <span className="asterisk">*</span>}
//       </label>
//       <div
//         onDrop={handleDrop}
//         onDragOver={handleDragOver}
//         onClick={handleClick}
//       >
//         {fileObj ? (
//           <div>
//             <div
//               className="file__detail--box cursor-pointer"
//               onClick={() => {
//                 previewHandler();
//               }}
//             >
//               <div className="d-flex">
//                 <>
//                   <div className="file_icon">
//                     {generateIconByType(fileObj?.type)}
//                   </div>
//                 </>
//                 <div className="file__detail">
//                   <div
//                     className={
//                       fileObj?.name?.length > 10
//                         ? "file__name--ellipsis"
//                         : "file__detail"
//                     }
//                   >
//                     {fileObj?.name}
//                   </div>
//                   <div>{convertFileSize(fileObj?.size)}</div>
//                 </div>
//                 <div
//                   className="reject__iconplace cursor-pointer"
//                   onClick={() => {
//                     setFileObj(null);
//                     setIspreview(false);
//                   }}
//                 >
//                   <Rejectedicon />
//                 </div>
//               </div>
//               {ispreview && (
//                 <PreviewFile
//                   file={fileObj}
//                   show={ispreview}
//                   title="Preview"
//                   id={id}
//                 />
//               )}
//             </div>
//           </div>
//         ) : (
//           <div>
//             <div className="file__upload cursor-pointer">
//               <div className="d-flex file__icon--text">
//                 <div className="icon__size--file">
//                   <Upload />
//                 </div>
//                 <div className="file-text">{t('draganddrop')}</div>
//               </div>
//               <input
//                 id={name}
//                 type="file"
//                 accept="application/pdf, image/png, image/jpg, image/jpeg .doc, .docx"
//                 name={name}
//                 style={{ display: "none" }}
//                 onChange={(event) => {
//                   onChange(event);
//                   handleChange({ name }, event);
//                 }}
//                 {...registerParams}
//               />
//             </div>
//           </div>
//         )}
//       </div>
//       <span className="error__message">{errors[name]?.message}</span>
//     </div>
//   );
// };
// export default FileUpload;

import React from "react";
import { useState, useEffect } from "react";
import "./FileUpload.css";
import {
  Upload,
  Rejectedicon,
  Jpgicon,
  Pdficon,
  Docicon,
} from "../../../../../Components/IconComponents";
import PreviewFile from "../../Form/PreviewFile/PreviewFile";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setRegFilesToStore } from "../../../../../CentralStore/RegistrationsSlice/registrationSlice";
import {
  validFileTypes,
  validDocumentTypes,
  validImageTypes,
} from "./fileUploadJson";
import { isNotNullOrEmptyOrUndefined } from "../../../../../Components/UiComponents/utils";

const FileUpload = ({
  label,
  name,
  errors,
  register,
  mandatory,
  getValues,
  setValue,
  editId,
}) => {
   const { t } = useTranslation();
  const [fileObj, setFileObj] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const dispatch = useDispatch();
  const { onChange, ...registerParams } = register(name);

  const { registrationFiles } = useSelector((state) => state.registrations);

  useEffect(() => {
    const values = getValues(name);
    if (
      isNotNullOrEmptyOrUndefined(editId) &&
      isNotNullOrEmptyOrUndefined(values) &&
      isEmptyObject(values)
    ) {
      if (isNotNullOrEmptyOrUndefined(values?.id)) {
        setFileObj(values);
      } else {
        setFileObj(values[0]);
      }
    } else if (registrationFiles[name] !== null) {
      setFileObj(registrationFiles[name]);
    }
  }, []);

  const generateIconByType = () => {
    const { type } = fileObj;
    let icon;
    if (type === "application/pdf") {
      icon = <Pdficon />;
    } else if (validImageTypes.includes(type)) {
      icon = <Jpgicon />;
    } else if (validDocumentTypes.includes(type)) {
      icon = <Docicon />;
    }
    return icon;
  };

  const convertFileSize = () => {
    const { size: bytes } = fileObj;
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 B";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    // console.log(file)
    dispatch(setRegFilesToStore({ type: name, file }));
    if (validFileTypes.includes(file.type)) {
      if (file.size < 15000000) {
        setFileObj(event.target.files[0]);
        setShowPreview(false);
      }
    }
  };

  const previewHandler = () => setShowPreview(() => !showPreview);

  // Prevent default drag-over behavior
  const handleDragOver = (e) => e.preventDefault();

  // Handle file drop event
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    dispatch(setRegFilesToStore({ type: name, file }));
    setFileObj(file);
  };

  // Trigger the file input click event
  const handleClick = () => {
    const fileInputTriger = document.getElementById(name);
    if (fileInputTriger) {
      fileInputTriger.click();
    }
  };

  const isEmptyObject = (object) =>
    Object.keys(object).length > 0 ? true : false;

  return (
    <div className="upload">
      <label>
        {t(label)}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
      >
        {fileObj ? (
          <div
            className="file__detail--box cursor-pointer"
            onClick={() => {
              previewHandler();
            }}
          >
            <div className="d-flex">
              <div className="file_icon">{generateIconByType()}</div>
              <div className="file__detail">
                <div
                  className={
                    fileObj?.name?.length > 10 && "file__name--ellipsis"
                  }
                >
                  {fileObj?.name}
                </div>
                <div>{convertFileSize(fileObj?.size)}</div>
              </div>
              <div
                className="reject__iconplace cursor-pointer"
                onClick={() => {
                  setFileObj(null);
                  setShowPreview(false);
                  setValue(name, null);
                }}
              >
                <Rejectedicon />
              </div>
            </div>
            {showPreview && (
              <PreviewFile
                file={fileObj}
                show={showPreview}
                previewHandler={previewHandler}
                title="Preview"
              />
            )}
          </div>
        ) : (
          <div>
            <div className="file__upload cursor-pointer">
              <div className="d-flex file__icon--text">
                <div className="icon__size--file">
                  <Upload />
                </div>
                <div className="file-text">{t('draganddrop')}</div>
              </div>
              <input
                id={name}
                type="file"
                accept="application/pdf, image/*, .doc, .docx"
                name={name}
                style={{ display: "none" }}
                onChange={(event) => {
                  onChange(event);
                  handleChange(event);
                }}
                {...registerParams}
              />
            </div>
          </div>
        )}
      </div>
      <span className="error__message">{errors[name]?.message}</span>
    </div>
  );
};

export default FileUpload;
