import React, { useEffect, useState } from "react";
import "./CurrentDashboardCard.css";

export default function CurrentDashboardCard({
  data,
  config,
  clickedKey,
  setClickedKey,
  triggeredFunction,
}) {
  const colors = ["#519EFE", "#b981f6", "#E28771", "#D252AA"];
  const [cardData, setCardData] = useState({});
  useEffect(() => {
    setCardData(data);
  }, [data]);
  return (
    <div className="current-dashboard_card">
      <div className="curr-card">
        {config?.data.map((conf, index) => (
          <div
            key={index}
            className={`cds-single_card cursor-pointer ${
              clickedKey === conf.key ? "highlighted" : ""
            }`}
            onClick={() => {
              setClickedKey(conf?.key);
              triggeredFunction(
                conf?.label,
                conf?.apiKey,
                conf?.key,
                conf?.apiKey2
              );
            }}
            style={{ backgroundColor: colors[index % colors.length] }}>
            <div className="card-label">{conf.label}</div>
            <div>
              {cardData && cardData[conf?.key] ? cardData[conf?.key] : 0}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
