import * as React from "react";
const SvgSortdownarrow = (props) => (
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.62524 1.34961L4.12574 5.31811L6.62624 1.34961H1.62524Z"
      fill="white"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default SvgSortdownarrow;
