import React from "react";
import "./Checkbox.css";
import { Controller } from "react-hook-form";

const Checkbox = ({ label, name, control, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue} // Set default value to false
      render={({ field: { onChange, value } }) => (
        <div className="container">
          <label htmlFor={name}>
            <span className="dynamic__checkbox--label">{label}</span>
            <input
              type="checkbox"
              id={name}
              checked={value} // Set checked attribute based on the value
              onChange={(e) => {
                onChange(e.target.checked); // Update value when checkbox is toggled
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      )}
    />
  );
}
export default Checkbox;
