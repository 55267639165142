import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../Components/UiComponents/Table/Table";
import { Editicon, Deleteicon } from "../../../Components/IconComponents";
import { getData } from "../../../Services/Services";
import { getStoredFormTemplates } from "../../../Services/EndPoints";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { getExportedColumnsData } from "../../../Components/UiComponents/utils";
import { setTableData } from "../../../CentralStore/ExportLMSTable/dataSlice";
import { toast, ToastContainer } from "react-toastify";
import Confirmpopup from "../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../Common/StatusHandler/StatusHandler";
import { useTranslation } from "react-i18next";
const FormBuilderView = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  useEffect(() => {
    getStoredFormtemplates();
  }, []);
  const getStoredFormtemplates = async () => {
    var data = await getData(getStoredFormTemplates);
    let sortedList = data.sort((a, b) => {
      return new Date(b.created_on) - new Date(a.created_on);
    });
    setData(sortedList);
  };
  const addNewHandler = () => {
    navigate("/create-form");
    // localStorage.removeItem("DriverRegForm");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("adhoctableheaders.name"),
        accessor: "form_name",
        disableSortBy: true,
        leftAlign: true,
        // Cell: (props) => <TextEllipsis text={props?.value} maxLength={15} />,
      },

      {
        Header: t("adhoctableheaders.description"),
        accessor: "description",
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("adhoctableheaders.createdon"),
        accessor: "created_on",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
        leftAlign: true,
        // Cell: (props) => <TextEllipsis text={props?.value} maxLength={15} />,
      },
      {
        Header: t("adhoctableheaders.usedforreports"),
        accessor: "reportable",
        Cell: (props) => {
          if (props.value === true) {
            return "Yes";
          } else {
            return "No";
          }
        },
      },
      {
        Header: t("adhoctableheaders.action"),
        accessor: "status",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value === true) {
            return "Deactivate";
          } else {
            return "Activate";
          }
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <Table
        data={data}
        title=""
        showFilter={false}
        columns={columns}
        numberOfRows={7}
        addButtonLabel={t("adhoccreateformaddbuttonlabel")}
        addNewHandler={addNewHandler}
        showExport={false}
        showReset={false}
        onExpand={false}
        showSearch={true}
        isHeaderFixed={true}
        // addButtonLabel="Add New"
        // addNewHandler={addNewHandler}
        headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
        tableStyle={{
          border: "1px solid #f0f0f0",
          padding: "2px",
          borderRadius: "12px",
        }}
      />
    </div>
  );
};

export default FormBuilderView;
