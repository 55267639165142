import * as yup from "yup";
export const ContactFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "name",
        label: "contactformLabels.name",
        type: "input",
        mandatory: "true",
      },
      {
        name: "phone1",
        label: "contactformLabels.phone1",
        type: "input",
        mandatory: "true",
      },
      {
        name: "phone2",
        label: "contactformLabels.phone2",
        type: "input",
      },
      {
        name: "email",
        label: "contactformLabels.email",
        maxLength: "30",
        type: "input"
      },

      {
        name: "agency_id",
        label: "contactformLabels.agency",
        type: "select",
        // mandatory: "true",
      },
      // {
      //   name: "type",
      //   label: "Contact Type",
      //   type: "select",
      //   options: [{ value: "External", label: "External" }],
      // },
      {
        name: "region",
        label: "contactformLabels.region",
        type: "hier_select",
        // mandatory: "true",
      },
      {
        name: "job_title_id",
        label: "contactformLabels.jobtitle",
        type: "select",
        // mandatory: "true",
      },
      {
        name: "contact_type",
        label: "contactformLabels.contact",
        type: "select",
        mandatory: "true",
        options: [
          { value: "Individual", label: "Individual" },
          { value: "Team", label: "Team" },
        ],
      },
      {
        name: "quantity",
        label: "contactformLabels.quantity",
        type: "input",
        disabled: false,
      },
      {
        name: "skills",
        label: "contactformLabels.skills",
        type: "multi_select",
        options: [],
      },
      {
        name: "address",
        label: "contactformLabels.address",
        type: "text_area",
      },
    ],
  },
];

export const contactTableData = [];

export const userTableDatacolumnsForExport = [
  { label: "User Name", key: "user_name" },
  { label: "Phone", key: "phone1" },
  { label: "Secondary Phone", key: "phone2" },
  { label: "Email", key: "email" },
  { label: "Role", key: "user_role_map" },
];

export const contactformschema = [
  yup.object({
    name: yup.string().required("Please provide the name"),
    phone1: yup
      .string()
      .required("Please provide the phone number")
      .matches(
        /^(?:\+91|0)?[6789]\d{9}$/,
        "Please provide a valid phone number"
      )
      .max(10, "Phone Number must be at most 10 digits"),
    phone2: yup
      .string()
      .nullable() // Allow null values (empty string)
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ) // Transform an empty string to null
      .test(
        "is-valid-phone",
        "Please provide a valid phone number",
        (value) => value === null || /^(?:\+91|0)?[6789]\d{9}$/.test(value)
      )
      .max(15, "Phone number must be at most 15 digits"),
  
    address: yup
      .string()
      .nullable() // Allow null values (empty string)
      .max(200, "Description must be at most 200 characters"),
  }),
];

export const contactcolumnsForExport = [
  { label: "Name", key: "name" },
  {label: "Job Title", key: "job_title"},
  { label: "Phone1", key: "phone1" },
  { label: "Email", key: "email" },
  { label: "Address", key: "address" },
  { label: "Region", key: "region_name" },
  { label: "Agency Name", key: "agency_name" },
  { label: "Skill", key: "skills" },
];

// export const contactcolumnsForExport = [
//   { label: "Name", key: "Name" },
//   {label: "Job Title", key: "Job Title"},
//   { label: "Phone1", key: "Phone1" },
//   { label: "Email", key: "Email" },
//   { label: "Address", key: "Address" },
//   { label: "Region", key: "Region" },
//   { label: "Agency Name", key: "Agency Name" },
//   { label: "Skill", key: "Skill" },
// ];
