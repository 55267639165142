import React from "react";
import { DownlaodNew } from "../../../../IconComponents";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const ExportTableData = ({ data, columns, filename }) => {
  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Add column headers
    worksheet.columns = columns.map((col) => ({
      header: col.label,
      key: col.label,
      width: 20,
    }));

    // Add rows
    data.forEach((row) => {
      const rowData = columns.map((col) => row[col.label]);
      worksheet.addRow(rowData);
    });

    if (filename.startsWith("DailyFloodReportStatus")) {
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber !== 1) {
          // Skip header row

          const shouldHighlightRow =
            row.getCell("Affected Blocks").value === 0 &&
            row.getCell("Partially AffectedBlocks").value === 0 &&
            row.getCell("NotAffected Blocks").value === 0;

          const areBlocksEqual =
            row.getCell("Total Blocks").value ===
            row.getCell("TotalMarked Blocks").value;

          row.eachCell({ includeEmpty: true }, (cell) => {
            if (shouldHighlightRow) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF0000" }, // Red
              };
            } else if (areBlocksEqual) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF00FF00" }, // Light green
              };
            } else {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF00" }, // Yellow
              };
            }
          });
        }
      });
    }

    // Generate the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${filename}.xlsx`);
  };

  return (
    <div style={{ margin: "0.3em" }}>
      {data?.length > 0 && (
        <button
          onClick={handleDownload}
          style={{ background: "none", border: "none" }}
        >
          <DownlaodNew
            color="var(--white3-color)"
            width="1.2em"
            height="1.2em"
          />
        </button>
      )}
    </div>
  );
};

export default ExportTableData;
