import React, { useState } from "react";
import "./Signin.css";
import logo from "../../Assets/Images/logo_white.png";
import { ShowPwd, HidePwd } from "../../Components/IconComponents";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .required()
      .min(8, "Password must be atleast 8 characters"),
  })
  .required();

const Signin = () => {
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const submitHandler = (data) => {
    localStorage.setItem("token", "this_is_a_token_for_testing");
    window.location.href = "/";
  };

  return (
    <div className="login-page">
      <div className="login-wrapper">
        <img src={logo} className="logo" />
        <div className="login-container">
          <div className="login-header">
            <h4 className="heading">Sign in</h4>
            <h6 className="sub-heading">Super Administrator</h6>
          </div>
          <form className="login__form" onSubmit={handleSubmit(submitHandler)}>
            <div className="signin__input-group  mt-4">
              <label htmlFor="email">Email Address</label>
              <input
                className="input-item"
                placeholder="Youraddress@email.com"
                {...register("email", {
                  required: "Email Address is required",
                  pattern: /^\S+@\S+$/i,
                })}
              />
            </div>
            <div className="signin__error">{errors.email?.message}</div>
            <div className="password-container mt-4">
              <div className="signin__input-group">
                <label htmlFor="password">Password</label>
                <div className="d-flex">
                  <input
                    type={show ? "text" : "password"}
                    className="input-item password-left"
                    {...register("password", {})}
                    placeholder="********"
                  />
                  <span
                    className="password-right"
                    onClick={() => setShow(!show)}
                  >
                    {show ? (
                      <ShowPwd width="1.2em" height="1.2em" />
                    ) : (
                      <HidePwd width="1.2em" height="1.2em" />
                    )}
                  </span>
                </div>
              </div>
              <span className="signin__error">{errors.password?.message}</span>
              <span
                className="forgot-pwd"
                onClick={() => alert("clicked on Forgot Password")}
              >
                Forgot Password?
              </span>
            </div>

            <div className="signin__input-group  mt-4">
              <label htmlFor="city">City</label>
              <input
                className="input-item"
                {...register("city", {
                  disabled: true,
                })}
                placeholder="Thanjavur"
              />
            </div>

            <div className="bottom-container">
              <button className="btn btn-primary signin-button  mt-4">
                Sign in
              </button>
              <span className="facing-issues">Facing issues?</span>
              <a className="contact-us">Contact Us</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signin;
