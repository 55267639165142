// export const baseUrl = process.env.REACT_APP_LMS_BASE_URL;
export const baseUrl = process.env.REACT_APP_ASSET_BASE_URL;
export const keycloakbaseurl = process.env.REACT_APP_URM_BASE_URL;
export const appserveraddr = process.env.REACT_APP_APP_SERVER_ADDRESS;
export const urmservice = process.env.REACT_APP_URM_SERVICE;
export const serviceBaseURL = process.env.REACT_APP_DATA_SERVICE;
export const dataservice = process.env.REACT_APP_DATA_SERVICE;
export const gisserviceBaseURL = process.env.REACT_APP_GIS_SERVICE;
export const dmscoreservicecontextname =
  process.env.REACT_APP_CORE_CONTEXT_PATH_NAME;
export const userMgmtService = process.env.REACT_APP_DATA_SERVICE;

//user image
export const getUserImage = "/auth/media/file/";
export const postImage = "/user_profile/change_picture";
export const authUserData = "/user_profile/getUserProfile";
export const changePassword = "/auth/profile/change_password";
export const updateUserData = "/user_profile/update";

//User Management
export const createRole = "/role/create";
export const updateRole = "/role/update";
export const deleteRole = "/role/delete";
export const getAllRole = "/role/getall";
export const getAllActiveRole = "/role/getallActiveRoles";
export const deletePermissionsFromRole = "/role/deletePermissionsFromRole/";
export const findPermissionsByUser = "/role/findPermissionsByUser";
export const findRolesByUser = "/role/findRolesByUser";

export const getallUsersByRealm = "/urm/admin/realms/{REALM}/users";
export const getAdminAuthTokenByRealm =
  "/urm/realms/{REALM}/protocol/openid-connect/token";
export const createUserintoRealm = "/urm/admin/realms/{REALM}/users";
export const getallRolesByRealm = "/urm/admin/realms/{REALM}/roles";
export const addPermissionsToRole = "/role/addPermissionsToRole/";
export const createRoleintoRealm = "/urm/admin/realms/{REALM}/roles";

export const getRoleDataByRoleNameURL = "/urm/admin/realms/{REALM}/roles/";
export const getUserDataByEmailAttr =
  "/urm/admin/realms/{REALM}/users?email={EMAIL}&exact=true";
export const assignRolesToUser =
  "/urm/admin/realms/{REALM}/users/{USER_ID}/role-mappings/realm";
export const deleteUserByRealm = "/urm/admin/realms/{REALM}/users/{USER_ID}";
export const userImageUpload = "/user";
export const mediaFileLink = "/media/file";
export const findUsersById = "/user/findUsersById";

// ---------------------------------------------------------------------------------

//User Management
export const updateUserStatusByUserId =
  "/urm/admin/realms/{REALM}/users/{USER_ID}";
export const getUserByUserId = "/urm/admin/realms/{REALM}/users/";
export const getRoleById = "/urm/admin/realms/{REALM}/roles-by-id/";
export const getRolesByUserId =
  "/urm/admin/realms/{REALM}/users/{USER_ID}/role-mappings";
export const updateUserByUserId = "/urm/admin/realms/{REALM}/users/";
export const deletRoleById = "/urm/admin/realms/{REALM}/roles-by-id/";
export const getAllPermissions = "/permission/getall";
export const getUsersFromApp = "/user/getall";

//Resource Management
export const resourceListURL = "/resource/getall";
export const uniqeResourceListURL = "/resource/getUniqueResourceList";
export const createResourceURL = "/resource/create";
export const updateResourceURL = "/resource/update";
export const deleteResourcebyId = "/resource/delete";
export const findResourcesById = "/resource/findResourcesById";

export const regionListURL = "/region/getall";
export const minifiedRegionListURL = "/region/getMinifiedRegionList";
export const getChildRegionsById = "/region/findChildrenRegionsById";
export const findRegionsByUser = "/region/findRegionsByUser";
export const getRestrictedRegionListURL = "/region/getRestrictedRegionList";
export const findRegionsInfoById = "/region/findRegionsInfoById";

export const resourceTypListURL = "/config/getConfigsByCategoryName/RSRC_KND";
export const agencyListURL = "/agency/getall";
export const createAgencyURL = "/agency/create";
export const agencyTypeListURL = "/config/getConfigsByCategoryName/AGNCY_TYPE";
export const skillListURL = "/config/getConfigsByCategoryName/SKILL";
export const masterDataAttrTypeListURL =
  "/config/getConfigsByCategoryName/MASTER_DATA_TYPE";
export const getConfigsByCategoryName = "/config/getConfigsByCategoryName/";
export const createConfig = "/config/create";
export const updateConfig = "/config/update";
export const deleteConfig = "/config/delete";
export const hierarchylevelListURL = "/region/getall";
export const createResource = "/resource/create";
export const createRegion = "/region/create";
export const assignRoleToUser = "/user/create";
export const updateUserInAppURL = "/user/update";

//Region Management
export const findRegionById = "/region/findRegionsById";
export const updateRegion = "/region/update";
export const deleteRegionURL = "/region/delete";

//AgencyManagement
export const agencyTypeURL = "/config/getConfigsByCategoryName/AGNC_TYP";
export const findAgenciesById = "/agency/findAgenciesById";
export const updateAgencyURL = "/agency/update";
export const deleteAgencyURL = "/agency/delete";

//ContactManagement
export const createContact = "/contact/create";
export const getContacts = "/contact/getallUserAndContacts";
export const getAllContacts = "/contact/getall";
export const updateContact = "/contact/update";
export const deleteContactURL = "/contact/delete";
export const getskillTypesURL = "/config/getConfigsByCategoryName/SKILL";
export const deleteUserById = "/user/delete";
//FacilityManagement
export const createFacility = "/facility/create";
export const getFacilities = "/facility/getall";
export const creategisFacilityEntry = "/create/facility";
export const updateFacility = "/facility/update";
export const updategisFacilityEntry = "/update/facility";
export const findFacilityById = "/facility/findFacilitiesById";
export const getFacilityTypeURL = "/config/getConfigsByCategoryName/FCLTY_TYP";
export const deleteFacilityURL = "/facility/delete";
export const deleteCKById = "/api/upsertAPI?api_key=";


//Master Resource Data Management
export const createMasterResource = "/master_resource/create";
export const getMasterResourceListURL = "/master_resource/getall";
export const resourceActivityListURL = "/master_resource/getResourceActivties";
export const resourceCatListURL = "/master_resource/getResourceCategories/";
export const resourceNameListURL = "/master_resource/getResourceNames";
export const getMasterResourceByIdURL =
  "/master_resource/findMasterResourcesById";

//Job Title Management
export const createJobTitle = "/job_title/create";
export const getJobTitle = "/config/getConfigsByCategoryName/JOB_TITLE";

//Contact Management
export const findContactsById = "/contact/findContactsById";

//Task Management
export const getTaskConfigsURL = "/config/getConfigsByCategoryNames";
export const createTaskURL = "/task/create";
export const getTaskListURL = "/task/getall";
export const updateTaskURL = "/task/update";
export const deleteTaskURL = "/task/delete";
export const findTaskById = "/task/findTasksById";
export const createTaskandAssign = "/assignTasksToUsers";
export const getMatserUserTaskList = "/getData/getmasterusertasks";
export const getIncidentData = "/getData/incident";
export const getAllincidents = "/getData/getAllincidents";

//SOP Management
export const createMasterSOPURL = "/sop_master/create";
export const updateMasterSOPURL = "/sop_master/update";
export const getMasterSOPListURL = "/sop_master/getMasterSOPList";
export const getActiveMasterSOPList = "/sop_master/getActiveMasterSOPList";
export const getSOPTasksDtlBySOPId = "/sop_master/getSOPTasksDtlBySOPId/";
export const getSOPRsrcDtlBySOPId = "/sop_master/getSOPRsrcDtlBySOPId/";
export const deleteSopById = "/sop_master/delete";

//My Task Management
export const getMyTaskListURL = "/task/findTasksByUser";
export const getMyIndividualTaskListURL = "/getData/getuserinditask";
export const claimMytask = "/task/claimMyTask";
export const completeNotificationtask = "/completeNotification";
export const completeIndiTask = "/completeUserIndiTask";
export const completeTask = "/task/completeTask";

//For Builder
export const createFormStructure = "/formbuilder/create";
export const getFormStructureURL = "/formbuilder/view";
export const gtFormDataByFormNameURL =
  "/formbuilderdata/getFormdataByFormName/";
export const getStoredFormTemplates = "/formbuilder/getStoredFormTemplates";

//For Builder Data
export const createFormDataViaBuilder = "/formbuilderdata/create";

//Dashboard URLS
export const getBlockInfoFloodURL =
  "/dailyfloodreport/getBlockInfoFrFlood?to_date=TODATE&dist_id=DISTID&api_key=APIKEY&from_date=FROMDATE";
export const getAnimalShelterListURL = "/facility_report/getASTabularReport";
export const getCKListURL = "/facility_report/getCKTabularReport";
export const getMealsDetailsURL = "/facility_report/getMealDetailsByMealType";
export const getFloodReliefData = "/facility_report/getFLTabularReport";
export const getFacilityReportAttrByReportIdAndCat =
  "/facility_report/getFacilityReportAttrByReportIdAndCat";
export const getDailyReportListURL = "/dailyreport/getDailyReportTabularView";
export const getDailyReportbyId =
  "/dailyreport/getDailyReportbyId?dailyReportId=";
export const getFacilityOpenReport = "/facility_report/getFacilityOpenReport";
export const getFacilityReport = "/facility_report/getFacilityReport";
export const getFacilitySummaryReport =
  "/facility/getFacilitiesSummaryByFacilityTypeId";
export const getASStats = "/facility_report/getASStats";
export const getFloodEntityStats = "/facility_report/getFloodEntityStats";
export const getDailyReportByDistrict =
  "/dailyfloodreport/getDailyReportbyRegionId";
export const getDailyAffectedReportByDistrict =
  "/dailyfloodreportaffected/getDailyReportAffected";
export const getDailyPartiallyAffectedReportByDistrict =
  "/dailyfloodreportpartiallyaffected/getDailyReportPartiallyAffected";
export const getDailyReportByState = "/statefloodreport/getDailyStateReport";
export const getAffectedDailyReportByState =
  "/dailyfloodaffectedreport/getDailyFloodAfected";
export const getPartiallyAffectedDailyReportByState =
  "/dailyfloodpareport/getPADailyReportbyRegionId";
export const getStateFloodCountData =
  "/dailyfloodreport/getDasCountDetails?from_date=FROMDATE&to_date=TODATE";
export const getCurrStateFloodCountData =
  "/api/executeAPI?api_key=get_flood_curr_dashboard";
export const getCurrBarChartData = "/api/executeAPI?api_key=";
export const getForm1StatusData = "/api/executeAPI?api_key=get_form1_status";
export const getBlockWiseReport =
  "/dailyfloodreport/getBlockDetails?from_date=FROMDATE&to_date=TODATE";
export const getAffectedBlock =
  "/dailyfloodreport/getAffectedBlockDetails?from_date=FROMDATE&to_date=TODATE";
export const getAffectedDistrict =
  "/dailyfloodreport/getAffectedDistDetails?from_date=FROMDATE&to_date=TODATE";
export const getAffectedNagarNigam =
  "/dailyfloodreport/getAffectedNagarNigamDetails?from_date=FROMDATE&to_date=TODATE";
export const getAffectedPanchayat =
  "/dailyfloodreport/getAffectedGramPanchyatsDetails?from_date=FROMDATE&to_date=TODATE";
export const getAffectedDrilldownView =
  "/dailyfloodreport/API_KEY?from_date=FROMDATE&to_date=TODATE";
export const updateFloodReport = "/dailyfloodreport/update";
export const getDailyFloodReportListURL =
  "/dailyfloodreport/getDailyFloodReport?region_id=ID&reported_on=DATE";
export const cleardailyReportData =
  "/dailyfloodreport/clearFloodReportEntity?report_id=";
//Theme URLS
export const getThemeURL = "/user_profile/getUserProfile";
export const getUserProfileImage = "/user_profile/get_user_image";
export const updateThemeDataURL = "/user_profile/update";

export const getRiverListURL = "/config/getConfigsByCategoryName/RVRS";
