import * as React from "react";
const SvgClaim = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.7em"
    height="1.7em"
    viewBox="0 0 60.601 60.601"
    {...props}
  >
    <path
      d="M7.787 56.032a3.217 3.217 0 0 1-3.218-3.216V7.784A3.217 3.217 0 0 1 7.787 4.57h45.03a3.215 3.215 0 0 1 3.215 3.215v45.032a3.215 3.215 0 0 1-3.215 3.216H7.787z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "#fff",
        strokeWidth: 0.60625005,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
    />
    <path
      d="M33.667 20.324h-6.73V17.86a18.712 18.712 0 0 1 3.365-.308c1.125 0 2.252.106 3.365.31v2.463zm-18.37 0a2.045 2.045 0 0 0-2.046 2.045l-.002 19.782c0 1.13.916 2.046 2.046 2.046h3.555V20.324h-3.553m30.011 23.877c1.13 0 2.045-.918 2.045-2.048l-.005-19.781a2.046 2.046 0 0 0-2.046-2.048h-3.548V44.2l3.554.001m-5.514 0V20.323H35.63v-4.042l-.602-.141a20.59 20.59 0 0 0-9.449 0l-.601.142v4.041H20.81v23.873l18.984.002"
      style={{
        fill: "#ffa100",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    />
  </svg>
);
export default SvgClaim;
