import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Editicon, Deleteicon } from "../../../../Components/IconComponents";
import { toast, ToastContainer } from "react-toastify";
import Table from "../../../../Components/UiComponents/Table/Table";
import Moment from "react-moment";
import Loader from "react-spinner-loader";
import {
  agencyTableData,
  columnsForExport,
} from "../../../../JsonData/AgencyConfig";
import { getData, putDataWithBody } from "../../../../Services/Services";
import { agencyListURL, deleteAgencyURL } from "../../../../Services/EndPoints";
import { useDispatch } from "react-redux";
import {
  filterDate,
  getExportedColumnsData,
} from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { useTranslation } from "react-i18next";
const AgencyTable = forwardRef((props, ref) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const qs = require("qs");
  const [agencyData, setAgencyData] = useState([]);
  const navigate = useNavigate();
  const [rowCount, setRowCount] = useState(0);
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState([]);
  useEffect(() => {
    if (props.locationInstance && props.locationInstance.msg != "") {
      toast.success("Agency has been created successfully.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
    getAgecnyList();
  }, []);
  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = columnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };
  const addNewHandler = () => {
    navigate("/agency-list/create-agency");
  };

  const getAgecnyList = async () => {
    setLoader(true);
    var agencyList = await getData(agencyListURL);
    let sortedList = agencyList.sort((a, b) => {
      return new Date(b.created_on) - new Date(a.created_on);
    });
    setLoader(false);
    setAgencyData(sortedList);
    setRowCount(sortedList.length);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("agencytableheaders.name"),
        accessor: "name",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 30) {
              descriptionText = props.value.substring(0, 30) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("agencytableheaders.agencytype"),
        accessor: "agency_type",
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 30) {
              descriptionText = props.value.substring(0, 30) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("agencytableheaders.description"),
        accessor: "description",
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 25) {
              descriptionText = props.value.substring(0, 30) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      // {
      //   Header: "Status  ",
      //   accessor: "status",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     return <StatusHandler props={props} />;
      //   },
      // },
      {
        Header: t("agencytableheaders.createdon"),
        accessor: "created_on",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },
      ,
      {
        Header: t("agencytableheaders.action"),
        disableSortBy: true,

        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteAgencyURL, data, {});
            if (res.status == "success") {
              getAgecnyList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };

          return (
            <div className="usertable__actionsicon">
              <span
                onClick={() =>
                  navigate("/agency-list/create-agency", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }
              >
                <Editicon value={props.value} className="cursor-pointer me-2" />
              </span>

              <span onClick={deletePopUp}>
                <Deleteicon
                  value={props.value}
                  className="cursor-pointer mr-1"
                />
              </span>

              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.name}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t]
  );
  return (
    <div className="container  create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {agencyTableData && (
          <Table
            data={agencyData}
            setSearchData={setSearchData}
            columns={columns}
            numberOfRows={7}
            addNewHandler={addNewHandler}
            showExport={false}
            showFilter={false}
            addButtonLabel={t("createagencyaddbuttonlabel")}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
});

export default AgencyTable;
