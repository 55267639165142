import * as React from "react";
const SvgCsvicon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 29 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M22.593 0H0v36.635h28.698V6.105L22.593 0Z" fill="#E4E4E4" />
    <path d="M23.538 3.053H0V0h22.593l.945 3.053Z" fill="#007934" />
    <path
      d="M9.913 27.548a.966.966 0 0 0-.844.443c-.202.295-.303.702-.303 1.221 0 1.079.41 1.618 1.231 1.618.248 0 .488-.034.721-.103.233-.07.466-.152.702-.25v1.282c-.468.206-.997.31-1.588.31-.846 0-1.495-.245-1.946-.736-.451-.491-.677-1.2-.677-2.129 0-.58.11-1.09.328-1.53.218-.44.533-.778.943-1.014.41-.237.893-.355 1.448-.355.606 0 1.185.132 1.737.395l-.464 1.193a5.172 5.172 0 0 0-.621-.246 2.17 2.17 0 0 0-.667-.1ZM16.179 30.29c0 .348-.088.656-.265.926-.176.27-.43.48-.763.629-.332.15-.722.224-1.17.224a4.74 4.74 0 0 1-.939-.078 3.495 3.495 0 0 1-.79-.275v-1.35c.289.149.59.265.901.348.312.083.598.124.86.124.224 0 .389-.039.494-.117a.36.36 0 0 0 .094-.502.732.732 0 0 0-.203-.175 8.504 8.504 0 0 0-.746-.36c-.366-.166-.64-.327-.823-.483a1.483 1.483 0 0 1-.406-.537 1.782 1.782 0 0 1-.133-.717c0-.517.188-.92.564-1.208.376-.29.892-.434 1.55-.434.58 0 1.171.134 1.775.403l-.464 1.17c-.524-.24-.977-.36-1.358-.36-.197 0-.34.034-.43.103a.313.313 0 0 0-.133.257c0 .11.056.208.17.295.114.087.423.245.926.476.483.217.82.45 1.007.7.188.249.282.563.282.94ZM20.194 26.386h1.695l-1.837 5.606H18.27l-1.83-5.606h1.703l.763 2.842c.159.631.246 1.07.261 1.319.018-.18.054-.403.108-.671.053-.269.1-.48.141-.633l.779-2.857Z"
      fill="#A4A9AD"
    />
    <path d="M22.593 6.105h6.105L22.593 0v6.105Z" fill="#D1D3D3" />
    <path
      d="M16.358 9.708h-4.02v1.863h4.02V9.708ZM21.27 9.708h-4.02v1.863h4.02V9.708ZM16.358 12.405h-4.02v1.863h4.02v-1.864ZM21.27 12.405h-4.02v1.863h4.02v-1.864ZM16.358 15.101h-4.02v1.863h4.02v-1.863ZM21.27 15.101h-4.02v1.863h4.02v-1.863ZM11.447 15.101h-4.02v1.863h4.02v-1.863ZM16.358 17.798h-4.02v1.863h4.02v-1.863ZM21.27 17.798h-4.02v1.863h4.02v-1.863ZM11.447 17.798h-4.02v1.863h4.02v-1.863ZM11.387 14.036h-1.214l-.756-1.213-.749 1.213H7.481L8.77 12.05l-1.21-1.896H8.72l.701 1.2.675-1.2h1.197l-1.23 1.978 1.323 1.904Z"
      fill="#007934"
    />
  </svg>
);
export default SvgCsvicon;
