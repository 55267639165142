import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDataPf } from "../../Services/ProfileServices";
import { authUserData } from "../../Services/EndPoints";
export const fetchProfileData = createAsyncThunk(
  "profileData/fetchProfileData",
  async () => {
    try {
      const response = await getDataPf(authUserData);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
);
const dataSlice = createSlice({
  name: "data",
  initialState: {
    columns: [],
    tableData: [],
    profileDataStatus: "idle",
    profileData: null,

  },
  reducers: {
    setTableData: (state, action) => {
      state.columns = action.payload.columns;
      state.tableData = action.payload.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfileData.pending, (state) => {
      state.profileDataStatus = "loading";
    })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.profileDataStatus = "succeeded";
        state.profileData = action.payload;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },

});

export const { setTableData } = dataSlice.actions;
export default dataSlice.reducer;
