import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { taskmanagementschema } from "../../../../JsonData/CreateTaskConfig";
import DynamicTabs from "../../Common/Tabs/Tabs";
import { toast, ToastContainer } from "react-toastify";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import useFormPersist from "react-hook-form-persist";
import { TaskFormConfig } from "../../../../JsonData/CreateTaskConfig";
import { useTranslation } from "react-i18next";

import {
  getData,
  postDataWithBody,
  putDataWithBody,
  editData,
} from "../../../../Services/Services";
import {
  getTaskConfigsURL,
  getAllActiveRole,
  createTaskURL,
  findTaskById,
  getTaskListURL,
  updateTaskURL,
} from "../../../../Services/EndPoints";
import Loader from "react-spinner-loader";
import { downloadFile } from "../../../../Components/UiComponents/utils";
import { PaperClipIcon } from "../../../../Components/IconComponents";
//Task Management

const CreateTaskForm = (props) => {
  const [CreateTaskConfig, setCreateTaskConfig] = useState(TaskFormConfig);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const [taskConfigs, setTaskConfigs] = useState([]);
  const [setViewTask, setViewTaskById] = useState(null);
  const [roleArr, setRoleArr] = useState([]);
  const { state } = useLocation();
  let data = state?.data;
  let id = state?.data.id;
  let mediapath = state?.data.media_file_path;
  const [fileObjs, setFileObjs] = useState([]);

  let downloadAttachmentURL =
    process.env.REACT_APP_APP_SERVER_ADDRESS +
    "/" +
    process.env.REACT_APP_CORE_CONTEXT_PATH_NAME +
    "/download/download_attachments/?directoryPath=" +
    mediapath +
    "&fileName=Tasks.zip";

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(taskmanagementschema[activeTab]),
    mode: "all",
  });

  useEffect(() => {
    getInitData();
  }, []);
  // GET
  async function getViewData() {
    var getViewData = await getData(getTaskListURL);
    // setViewTask(getViewData);
    if (id) {
      await populateViewFormFrEdit(id);
    }
  }

  //EDIT
  async function populateViewFormFrEdit(id) {
    var viewData = await postDataWithBody(findTaskById, [id], {});
    viewData = viewData[0];
    setViewTaskById(viewData);
    reset(viewData);
  }

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        {taskConfigs.length > 0 && roleArr.length > 1 && (
          <FormHandler
            register={register}
            errors={errors}
            fields={fields}
            control={control}
            editId={id}
            columns={2}
            getValues={getValues}
            fileObjs={fileObjs}
            setFileObjs={setFileObjs}
          />
        )}
      </div>
    );
  };

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const submitHandler = async (data, e) => {
    e.preventDefault();
    const formData = new FormData();

    for (let dataElem in data) {
      if (dataElem === "task_media_file") {
        if (data[dataElem].length > 0) {
          for (let i = 0; i < data[dataElem].length; i++) {
            formData.append(dataElem, data[dataElem][i]);
          }
        }
      } else {
        formData.append(dataElem, data[dataElem]);
      }
    }

    formData.append("status", true);

    let headers = { "Content-Type": "multipart/form-data" };
    //data.parent_region_id = props.parentRegionId;
    var res = null;
    if (id) {
      formData.append("id", id);
      formData.delete("created_on");
      formData.delete("roleEntity");
      formData.delete("updated_on");
      

      res = await putDataWithBody(updateTaskURL, formData, headers);
    } else {
      res = await postDataWithBody(createTaskURL, formData, headers);
    }
    if (res.status == "success") {
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      navigate("/planning/view-task");
    } else {
      toast.error(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
  };

  async function getInitData() {
    setLoader(true);
    await getTaskConfigs();
    await fetchAllRoles();
    // await getTaskStatus();
    await getViewData();
    setLoader(false);
    if (id) {
      populateViewFormFrEdit(id);
    }
  }
  async function fetchAllRoles() {
    let rolearr = await getData(getAllActiveRole);
    setRoleArr(rolearr);
    let roleFrDropdown = [];
    for (let role in rolearr) {
      roleFrDropdown.push({
        label: rolearr[role].name,
        value: rolearr[role].id,
      });
    }
    CreateTaskConfig[0].fields[2]["options"] = roleFrDropdown;
    setCreateTaskConfig(CreateTaskConfig);
  }
  async function getTaskConfigs() {
    let res = await postDataWithBody(
      getTaskConfigsURL,
      ["TSK_PRIOR", "INCDNT_CAT", "TSK_STAT", "TSK_TYP"],
      {}
    );
    setTaskConfigs(res);
    let config_type = {};
    for (let config in res) {
      if (config_type[res[config].category_name]) {
        config_type[res[config].category_name].push({
          label: res[config].value,
          value: res[config].id,
        });
      } else {
        config_type[res[config].category_name] = [
          { label: res[config].value, value: res[config].id },
        ];
      }
    }
    //alert(config_type["INCDNT_CAT"])
    CreateTaskConfig[0].fields[0]["options"] = config_type["INCDNT_CAT"];
    CreateTaskConfig[0].fields[2]["options"] = config_type["TSK_PRIOR"];
    CreateTaskConfig[0].fields[3]["options"] = config_type["TSK_STAT"];
    CreateTaskConfig[0].fields[3]["options"] = config_type["TSK_TYP"];
    setCreateTaskConfig(CreateTaskConfig);
  }

  const tabsData = generateTabsData(CreateTaskConfig);

  return (
    <div className="mt-4">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />

      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name="CreateTaskRegForm">
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    </>
                  )}
                </>
              ))}
            </div>

            <div>
              {id && mediapath ? (
                <span className="multi_file_uploaded cursor-pointer ">
                  <div
                    onClick={() =>
                      downloadFile(
                        downloadAttachmentURL,
                        "Tasks" + new Date().getTime() + ".zip"
                      )
                    }
                  >
                    <PaperClipIcon className="cursor-pointer" /> Attached files
                  </div>
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                  {t("saveAndSubmit")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateTaskForm;
