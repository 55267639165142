import React, { useState, useEffect, useRef, useContext } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
import govt_logo from "../../../../../Assets/Images/bihar_logo.png";
import Loader from "react-spinner-loader";
// import html2pdf from 'html2pdf.js';
import "../../../../DMS/Dashboard/DailyFloodReportComp/Hindi_Font.js";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SelectComponent from "../../../../../Components/UiComponents/SelectComponent/SelectComponent.js";
// import { postDataWithBody, getData } from "../../../../Services/Services";
// import {
//   getChildRegionsById,
//   findRegionsByUser,
//   getDailyReportByDistrict,
//   getDailyFloodReportListURL,
// } from "../../../../../Services/EndPoints.js";
import { useTranslation } from "react-i18next";
import "./Form9Table.css";
import DatePicker from "../../../Common/Form/DatePicker/DatePicker.js";
// import { RoleContext } from "../../../../index.js";

const Form1Table = () => {
  // const roleName = useContext(RoleContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dailyReport, setDailyReport] = useState(null);
  const [districtData, setDistrictData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const pdfRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // State to store selected date
  // const rolName = roleName.roleName;
  // console.log("roleName in the comptable from rolecontext=", rolName);

  const getBlockIdListForReport = () => {
    if (slctdBlkId == 0) {
      let blockIdList = [];
      blockData.forEach((v) => blockIdList.push(v.id));
      return blockIdList;
    } else {
      return [slctdBlkId];
    }
  };

  // const editFloodData = async () => {
  //   if (slctdBlkId == 0) {
  //     toast.error("Please select a block to edit data.", {
  //       position: toast.POSITION.TOP_CENTER,
  //       autoClose: 2000, // Close after 2 seconds
  //     });
  //   } else {
  //     let slctdDate = document.getElementById("flood_date_picker").value;
  //     const data = await getData(
  //       getDailyFloodReportListURL
  //         .replace("ID", slctdBlkId)
  //         .replace("DATE", slctdDate)
  //     );
  //     if (data.length == 0) {
  //       toast.error("No data exists for the selected date and block", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     } else {
  //       navigate("/dmsdashboard/editdfr", {
  //         state: {
  //           blockId: slctdBlkId,
  //           slctd_dt: document.getElementById("flood_date_picker").value,
  //         },
  //       });
  //     }
  //   }
  // };
  // const getDailyReportData = async (blockListId) => {
  //   if (blockListId == null) {
  //     blockListId = getBlockIdListForReport();
  //   }
  //   var selectedDateStr = document.getElementById("flood_date_picker").value;
  //   if (selectedDateStr == "") {
  //     selectedDateStr = new Date().toISOString().split("T")[0];
  //   }
  //   setSelectedDate(selectedDateStr);
  //   setLoader(true);

  //   const index = blockListId.indexOf(0);
  //   if (index > -1) {
  //     // only splice array when item is found
  //     blockListId.splice(index, 1); // 2nd parameter means remove one item only
  //   }
  //   let inputobj = {
  //     region_id_list: blockListId,
  //     reported_on: selectedDateStr,
  //   };
  //   let dailyReportData = await postDataWithBody(
  //     getDailyReportByDistrict,
  //     inputobj,
  //     {}
  //   );
  //   setDailyReport(dailyReportData);

  //   setLoader(false);
  //   var skillsSelect = document.getElementById("district_drop");
  //   var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
  //   document.getElementById("selected_dist_name").innerHTML = selectedText;
  // };

  // const getDistrictData = async () => {
  //   try {
  //     const distList = await getData(findRegionsByUser);

  //     if (Array.isArray(distList) && distList.length > 0) {
  //       let blockdata = await getBlockListByDistId(distList[0].id);
  //       setSlctdBlkId(0);
  //       let blockIdList = [];
  //       blockdata.forEach((v) => blockIdList.push(v.id));
  //       setDistrictData(distList);
  //       getDailyReportData(blockIdList);
  //       setSelectedDistrictId(distList[0].id);
  //     } else {
  //       console.error("Error: District data is empty or not an array");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching district list:", error);
  //   }
  // };

  async function addImageProcess() {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = govt_logo;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
  const form9downloadPDF = async () => {
    console.log("Button clicked, initiating PDF download...");
    const content = document.getElementById("form9_table");
    const doc = new jsPDF("p", "pt", "a4");
    try {
      const image = await addImageProcess();
      let fontName = "Helvetica";

      if (i18n.language == "hi") {
        fontName = "Akshar Unicode";
      }
      doc.setFont(fontName);
      doc.setFontSize(10);
      doc.addImage(image, "png", 40, 25, 0, 0);
      var xOffset = doc.internal.pageSize.width / 2;
      doc.text(t("floodreport.govt_name"), xOffset, 60, { align: "center" });
      doc.text(t("floodreport.department_name"), xOffset, 80, {
        align: "center",
      });
      doc.text(t("statefloodreport.floodreporttxt"), xOffset, 100, {
        align: "center",
      });
      doc.autoTable({
        html: content,
        startX: 10,
        startY: 120,
        theme: "grid",
        rowPageBreak: "avoid",
        bodyStyles: { lineColor: [0, 0, 0] },
        didParseCell: function (data) {
          if (data.cell.raw.querySelector("b")) {
            data.cell.styles.textColor = "red";
          }
          if (data.cell.raw.querySelector("span")) {
            data.cell.styles.halign = "center";
          }
        },
        styles: {
          fontSize: 7,
          cellWidth: "auto",
          halign: "left",
          cellPadding: 1,
          minCellWidth: 27,
        },
      });
      doc.save("Form9 Report.pdf");
    } catch (error) {
      console.error("Error adding image:", error);
    }
  };

  useEffect(() => {}, []);

  const getFormattedDate = (dateStr) => {
    return <Moment format="DD/MM/YYYY">{dateStr}</Moment>;
  };

  // const getBlockListByDistId = async (distId) => {
  //   try {
  //     const blockList = await postDataWithBody(
  //       getChildRegionsById,
  //       [distId],
  //       {}
  //     );
  //     blockList.forEach(function (elem, index) {
  //       elem["value"] = elem["name"];
  //     });
  //     blockList.unshift({ id: 0, value: "All" });
  //     setBlockData(null);
  //     setTimeout(function () {
  //       setBlockData(blockList);
  //     }, 100);
  //     return blockList;
  //   } catch (error) {
  //     console.error("Error fetching block list:", error);
  //   }
  // };
  // async function getDistrictDataByCode(event) {
  //   setSelectedDistrictName(event.target.value);
  //   setSelectedDistrictId(event.target.value);
  //   getBlockListByDistId(event.target.value);
  // }
  // function setSelectedBlockIdVal(event) {
  //   setSlctdBlkId(event.target.value);
  // }
  const changeSelectedate = () => {
    /*if (selectedDistrictId && selectedDate) {
      getDailyReportData(selectedDistrictId, selectedDate);
    } else {
      console.error("Please select both district and date.");
    }*/
  };
  return (
    <div class="form9flood__table-container">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      {/* <div className="ps-2 d-flex">
        <div className="me-2 region_type_container">
          <label className="mb-2">District</label>
          <div>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              // onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div> */}

      {/* <div className="me-2 region_type_container">
          <label className="mb-2">Block</label>
          <SelectComponent
            id="block_drop"
            value={selectedBlockId}
            // onChange={setSelectedBlockIdVal}
            placeholder="All Blocks"
            options={blockData}
          />
        </div> */}

      {/* <div>
          <label className="mb-2">{t("floodreport.reported_on")}</label>
          <div className="date_picker_flood_container me-1">
            <DatePicker
              selected={selectedDate}
              maxDate={new Date().toISOString().split("T")[0]}
              name="flood_date_picker"
              onChange={(date) => {
                console.log("Selected date:", date);
                setSelectedDate(date);
              }}
              className="form-control date-picker date_picker_flood"
            />
          </div>
        </div> */}
      {/* <button
          className="btn btn-success ms-2 btn-sm flood__report--button"
          onClick={() => getDailyReportData(null)}
        >
          {t("floodreport.buttonapply")}
        </button> */}
      <button
        className="btn btn-success btn-sm form9__report--button"
        onClick={form9downloadPDF}
      >
        {t("floodreport.download")}
      </button>
      {/* <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => editFloodData()}
          disabled={rolName !== process.env.REACT_APP_SUPER_ADMIN}
        >
          {t("floodreport.edit_btl_lbl")}
        </button> */}
      {/* </div> */}
      <div className="pe-3" ref={pdfRef}>
        <div className="text-center fw-bold d-none">
          Daily Flood Report Dated on ({getFormattedDate(selectedDate)})
        </div>
        <table id="form9_table" class="form9flood__report--table" border="2">
          <tr>
            <td colSpan="2"></td>
            <td colSpan="11">
              <b class="table__data--headings ">No of affected</b>
            </td>

            <td colSpan="7">
              <b class="table__data--headings ">House Damaged</b>
            </td>
          </tr>
          <tr>
            <td rowSpan="2">
              <b class="table__data--headings">No.</b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings "> Name of District</b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings">Name of Affected Blocks</b>
            </td>
            <td colSpan="3">
              <b class="table__data--headings">Panchayats </b>
            </td>
            <td>
              <b class="table__data--headings">Village</b>
            </td>
            <td>
              <b class="table__data--headings">Persons </b>
            </td>
            <td>
              <b class="table__data--headings">Animals</b>
            </td>
            <td colSpan="4">
              {" "}
              <b class="table__data--headings">Affected area (lac ha) </b>
            </td>
            <td>
              <b class="table__data--headings">Estd crop damage</b>
            </td>
            <td colSpan="2">
              <b class="table__data--headings">Pucca</b>
            </td>
            <td colSpan="2">
              <b class="table__data--headings">Kucha </b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings">Huts</b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings">Total</b>
            </td>
          </tr>
          <tr>
            <td>
              <b class="table__data--headings">fully</b>
            </td>
            <td>
              <b class="table__data--headings">Partly</b>
            </td>
            <td>
              <b class="table__data--headings">Total</b>
            </td>
            <td></td>
            <td>
              <b class="table__data--headings">Iac</b>
            </td>
            <td>
              <b class="table__data--headings">Iac</b>
            </td>
            <td>
              <b class="table__data--headings">Agric</b>
            </td>
            <td>
              <b class="table__data--headings">Non-Ag</b>
            </td>
            <td>
              <b class="table__data--headings">Cropped</b>
            </td>
            <td>
              {" "}
              <b class="table__data--headings">Cropped</b>
            </td>
            <td></td>
            <td>
              <b class="table__data--headings">Iac</b>
            </td>
            <td>
              <b class="table__data--headings">Iac</b>
            </td>
            <td>
              <b class="table__data--headings">Iac</b>
            </td>
            <td>
              <b class="table__data--headings">Iac</b>
            </td>
          </tr>

          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
            <td>13</td>
            <td>14</td>
            <td>15</td>
            <td>16</td>
            <td>17</td>
            <td>18</td>
            <td colSpan="1">19</td>
            <td>20</td>
            <td></td>
          </tr>

          <tr>
            <td rowSpan="2">
              <b class="table__data--headings">No. of Damage Cattle Shade</b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings ">Value of Houses damaged</b>
            </td>

            <td rowSpan="2">
              <b class="table__data--headings">Public property damaged</b>
            </td>

            <td colSpan="2">
              <b class="table__data--headings">Damaged Net</b>
            </td>
            <td colSpan="2">
              <b class="table__data--headings">Damaged Net</b>
            </td>

            <td rowSpan="2">
              <b class="table__data--headings">Area of damage fish seed farm</b>
            </td>
            <td colSpan="2">
              <b class="table__data--headings">Live Lost </b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings">Population Evacuated</b>
            </td>
            <td colSpan="2">
              <b class="table__data--headings">Population kept in shelter</b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings">
                No. of villages surrounded with water
              </b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings">
                Population on roads/rail tracks/ roof tops etc.
              </b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings">No of Community Kitchen</b>
            </td>
            <td rowSpan="2">
              <b class="table__data--headings">
                Number of meals in community kitchen
              </b>
            </td>
            <td rowSpan="2"></td>
            <td rowSpan="2"></td>
            <td rowSpan="2"></td>
            <td rowSpan="2"></td>
          </tr>
          <tr>
            <td>
              <b class="table__data--headings">fully</b>
            </td>
            <td>
              <b class="table__data--headings">Partly</b>
            </td>
            <td>
              <b class="table__data--headings">fully</b>
            </td>
            <td>
              <b class="table__data--headings">Partly</b>
            </td>
            <td>
              <b class="table__data--headings">Human</b>
            </td>
            <td>
              <b class="table__data--headings">Animal</b>
            </td>

            <td>
              {" "}
              <b class="table__data--headings">Relief camps</b>
            </td>
            <td>
              <b class="table__data--headings">Population</b>
            </td>
          </tr>

          <tr>
            <td>21</td>
            <td>22</td>
            <td>23</td>
            <td>24</td>
            <td>25</td>
            <td>26</td>
            <td>27</td>
            <td>28</td>
            <td>29</td>
            <td>30</td>
            <td>31</td>
            <td>32</td>
            <td>33</td>
            <td>34</td>
            <td>35</td>
            <td>36</td>
            <td>37</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td rowspan="2" colspan="4">
              <b class="table__data--headings">Boats</b>
            </td>
            <td rowspan="2" colspan="2">
              <b class="table__data--headings">Centre</b>
            </td>
            <td rowspan="2" colspan="2">
              <b class="table__data--headings">GR Distribution</b>
            </td>
            <td rowspan="2" colspan="5">
              <b class="table__data--headings">Ready food (in qls)</b>
            </td>
            <td colspan="8">
              <b class="table__data--headings">Material Distributed</b>
            </td>
          </tr>
          <tr>
            <td>
              <b class="table__data--headings">Fodder</b>
            </td>
            <td>
              <b class="table__data--headings">Matches</b>
            </td>
            <td>
              <b class="table__data--headings">Candles</b>
            </td>
            <td>
              <b class="table__data--headings">K Oil</b>
            </td>
            <td>
              <b class="table__data--headings">Polythene</b>
            </td>
            <td>
              <b class="table__data--headings">Food Packet</b>
            </td>
            <td>
              <b class="table__data--headings">Dry Food Packet</b>
            </td>
            <td>
              <b class="table__data--headings">Packets Dropped by Air</b>
            </td>
          </tr>
          <tr>
            <td>
              <b class="table__data--headings">Motor</b>
            </td>
            <td>
              <b class="table__data--headings">Govt</b>
            </td>
            <td>
              <b class="table__data--headings">Pvt</b>
            </td>
            <td>
              <b class="table__data--headings">Total</b>
            </td>
            <td>
              <b class="table__data--headings">Health</b>
            </td>
            <td>
              <b class="table__data--headings">Vet</b>
            </td>
            <td>
              <b class="table__data--headings">No of Families</b>
            </td>
            <td>
              <b class="table__data--headings">Amount of Rs @ 7000/-</b>
            </td>
            <td>
              <b class="table__data--headings">Chana</b>
            </td>
            <td>
              <b class="table__data--headings">Salt</b>
            </td>
            <td>
              <b class="table__data--headings">Chura</b>
            </td>
            <td>
              <b class="table__data--headings">Sattu</b>
            </td>
            <td>
              <b class="table__data--headings">Gur</b>
            </td>
            <td>
              <b class="table__data--headings">In. qtl..</b>
            </td>
            <td>
              <b class="table__data--headings">no</b>
            </td>
            <td>
              <b class="table__data--headings">no.</b>
            </td>
            <td>
              <b class="table__data--headings">litres</b>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>38</td>
            <td>39</td>
            <td>40</td>
            <td>41</td>
            <td>42</td>
            <td>43</td>
            <td>44</td>
            <td>45</td>
            <td>46</td>
            <td>47</td>
            <td>48</td>
            <td>49</td>
            <td>50</td>
            <td>51</td>
            <td>52</td>
            <td>53</td>
            <td>54</td>
            <td>55</td>
            <td>56</td>
            <td>57</td>
            <td>58</td>
          </tr>
        </table>
        <div></div>
      </div>
    </div>
  );
};

export default Form1Table;
