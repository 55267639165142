import React, { useState } from "react";
import "./MultiFileUpload.css";
import {
  Upload,
  Rejectedicon,
} from "../../../../../Components/IconComponents";
import PreviewFile from "../../Form/PreviewFile/PreviewFile";
import { validFileTypes } from "../FileUpload/fileUploadJson";
import { useTranslation } from "react-i18next";

function MultiFileUpload({ label, name, errors, register, mandatory }) {
  const [fileObjs, setFileObjs] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(false);
  const [validMsg, setValidMsg] = useState(0);
  const { t } = useTranslation();

  const { onChange, ...registerParams } = register(name);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);

    const totalFileSize = fileObjs.reduce(
      (accumulator, existingFile) => accumulator + existingFile.size,
      0
    );

    const filteredFiles = newFiles.filter((file) => {
      const isFileValid =
        validFileTypes.includes(file.type) &&
        totalFileSize + file.size < 15000000 &&
        !fileObjs.find((existingFile) => existingFile.name === file.name);

      return isFileValid;
    });

    const totalSizeAfterUpload =
      totalFileSize + filteredFiles.reduce((acc, file) => acc + file.size, 0);
    setValidMsg(totalSizeAfterUpload);
    if (totalSizeAfterUpload / 1000000 > 5) {
      return; // Do not update state if total size exceeds the limit.
    }

    setFileObjs((prevFileObjs) => [...prevFileObjs, ...filteredFiles]);

    // Manually trigger onChange for register
    const eventMock = {
      target: {
        name,
        value: [...fileObjs, ...filteredFiles],
      },
    };
    onChange(eventMock);
  };

  const previewHandler = (fileIndex) => {
    setShowPreview(!showPreview);
  };

  const removeFile = (fileIndex) => {
    const newFiles = [...fileObjs];
    newFiles.splice(fileIndex, 1);
    setFileObjs(newFiles);

    const eventMock = {
      target: {
        name,
        value: newFiles,
      },
    };
    onChange(eventMock);
  };

  const handleClick = () => {
    const fileInputTriger = document.getElementById(name);
    if (fileInputTriger) {
      fileInputTriger.click();
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles = Array.from(event.dataTransfer.files);

    handleFileChange({ target: { files: newFiles } });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="sales__upload">
      <label className="form__label">
        {t(label)}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <div className="multifile-all_card_view">
        {fileObjs.map((file, index) => (
          <div key={index} className="multifile__detail--box">
            <div>
              <div
                className="reject__icon cursor-pointer"
                onClick={() => removeFile(index)}
              >
                <Rejectedicon width="1rem" height="1rem" />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  previewHandler();
                  setPreviewFile(file);
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  className="multifile-image"
                  alt=""
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        ))}
        {showPreview && (
          <PreviewFile
            file={previewFile}
            show={showPreview}
            previewHandler={previewHandler}
            title="Preview"
          />
        )}
        <div>
          <div
            className="multi_file_upload cursor-pointer "
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}
          >
            <div>
              <div className="icon__size--multifile">
                <Upload />
              </div>
              <div className="multi-file_text">
                Drag and drop here or browse
              </div>
            </div>
            <input
              id={name}
              type="file"
              name={name}
              style={{ display: "none" }}
              onChange={(event) => {
                onChange(event);
                handleFileChange(event);
              }}
              multiple
              {...registerParams}
            />
          </div>
        </div>
      </div>
      {validMsg / 1000000 > 5 && (
        <span className="error__message">
          Total file size should not exceeds 5 MB.
        </span>
      )}
      <span className="error__message">{errors[name]?.message}</span>
    </div>
  );
}

export default MultiFileUpload;
