import React, { useEffect, useContext } from "react";
import "./App.css";
import Signin from "./Pages/Signin/Signin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./Routes/Router";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { fetchProfileData } from "./CentralStore/ExportLMSTable/dataSlice";


function App() {
  const token = localStorage.getItem("token");
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  useEffect(() => {
    if (keycloak.authenticated) {
      // Access the token from Keycloak
      const token = keycloak.token;
      localStorage.setItem("accessToken", token);
      dispatch(fetchProfileData());
    }
  }, [keycloak.authenticated]);
  return (
    <>
      <Router />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
