import React, { useEffect, useRef } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../DMSHeader/DMSHeader";
import MasterDataTable from "./MasterDataTable/MasterDataTable";
import { useTranslation } from "react-i18next";

const MasterDataManagement = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    GridStack.init();
  }, []);

  const ref = useRef();
  if (props.configType) {
    ref?.current?.refreshConfigMasterTable(props.configType);
  }
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={t("configuration_management_header")}
          showdownload={true}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 10,
      id: 2,
      component: <MasterDataTable ref={ref} />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default MasterDataManagement;
