import * as React from "react";
const SvgSearchIconLeft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="SearchIconLeft_svg__a"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={22}
      height={19}
    >
      <path fill="currentColor" d="M0 0h22v19H0z" />
    </mask>
    <g
      clipPath="url(#SearchIconLeft_svg__b)"
      stroke="#17A3A0"
      strokeWidth={1.5}
    >
      <circle cx={12.5} cy={9.5} r={4.5} />
      <path d="m16 13 4.5 4.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="SearchIconLeft_svg__b">
        <path
          fill="currentColor"
          transform="translate(1.467 1.268)"
          d="M0 0h19.067v16.467H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSearchIconLeft;
