import React from "react";
import "./Select.css";
import { useTranslation } from "react-i18next";

const Select = ({ label, options, name, register, errors, mandatory, defaultselect }) => {
  const { onChange, ...params } = register(name);
  const { t } = useTranslation();


  return (
    <div>
      <label className="form__label">
        {t(label)}
        {mandatory && <span className="asterisk">*</span>}
      </label>
      <select
        className={
          errors[name]
            ? "form-select  form__select--errorborder"
            : "form-select  form__select"
        }
        {...params}
        onChange={onChange}
        id={name}
      >

        {defaultselect && <option selected className="text-muted" value="">
          Select option
        </option>}

        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <p className="error__message">{errors[name]?.message}</p>
    </div>
  );
};
export default Select;
