import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { XCircleblack } from "../../../Components/IconComponents";
import "./BlockPopUp.css";
import BlockDetails from "./BlockDetails";
import AffectedPopUpDetails from "./DistrictBlockDetails";
import { ThemeContext } from "../../..";
const BlockPopUp = (props) => {
  const { title, show, handleToggle, blockdata, distName, component } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleToggle}
        className="block__modal__popup"
        size="md"
        id={theme}
        centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <XCircleblack
            className="cursor-pointer blockpopup-cross_mark"
            onClick={handleToggle}
            width="1em"
            height="1em"
            
          />
        </Modal.Header>
        <Modal.Body>{component}</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default BlockPopUp;
