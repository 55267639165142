import * as React from "react";
const SvgSuccessSvg = (props) => {
  const { circleFill = "#1EAF2C" } = props;
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx={20} cy={20} r={20} fill={circleFill} />
      <path
        d="M13 19.625 18.259 25 30 13"
        stroke="#fff"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgSuccessSvg;
