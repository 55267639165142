import React from 'react'
import { downloadFile,getDRAttributeValue } from "../../../../../../Components/UiComponents/utils";
import { useTranslation } from 'react-i18next';
const FishingAndBoats = (props) => {
  let downloadAttachmentURL = process.env.REACT_APP_APP_SERVER_ADDRESS +"/"+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+"/download/download_attachments/?directoryPath=[DIRECTORY_PATH]&fileName=Fishing_Boats.zip";
  const { t, i18n } = useTranslation();

  return (
    <div className="affectedtable-container">
      <div className="affectedtable-header-style">{t('fishingandboats')}</div>
      <div className="m-2">
        {/* <div className="row mainsection"> */}
        <div className="row section1 px-3 mt-3 mb-4">
          <div className="col-md-3">
            <div className="affected-population-labelstyle">{t('damagedboats')}</div>
            <div>
              Completely : {getDRAttributeValue(props.data,'FBR_DAMAGED_BOATS_COMPLETELY')} , <span>Partially : {getDRAttributeValue(props.data,'FBR_DAMAGED_BOATS_PARTIALLY')}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">{t('damagednets')}</div>
            <div>
              Completely : {getDRAttributeValue(props.data,'FBR_DAMAGED_NETS_COMPLETELY')} , <span>Partially : {getDRAttributeValue(props.data,'FBR_DAMAGED_NETS_PARTIALLY')} </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="affected-population-labelstyle">
              {t('boatsprivate')}
            </div>
            <div>
              Small : {getDRAttributeValue(props.data,'FBR_PRIVATE_BOATS_SMALL')} ,   <span>Medium : {getDRAttributeValue(props.data,'FBR_PRIVATE_BOATS_MEDIUM')} , </span>
                   <span>Large : {getDRAttributeValue(props.data,'FBR_PRIVATE_BOATS_LARGE')}</span>
            </div>
          </div>
          
        </div>

        <div className="row section2 px-3 mt-3 mb-4">
         
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('boatsgovt')}
            </div>
            <div>
              Small : {getDRAttributeValue(props.data,'FBR_GOVERNMENT_BOATS_SMALL')} ,   <span>Medium : {getDRAttributeValue(props.data,'FBR_GOVERNMENT_BOATS_MEDIUM')} , </span>
                   <span>Large : {getDRAttributeValue(props.data,'FBR_GOVERNMENT_BOATS_LARGE')}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">{t('attachments')}</div>
            <div>
              {
              getDRAttributeValue(props.data,'attachment_path') != null ? (
              <div className="affected-population-labelstyle" role="button"
              onClick={() => downloadFile(downloadAttachmentURL.replace("[DIRECTORY_PATH]",getDRAttributeValue(props.data,'attachment_path')),'Fishing_Boat_Report_'+new Date().getTime()+'.zip')} 
              >Download</div>
              ) : (
                "Not Available"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default FishingAndBoats