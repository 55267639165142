import * as React from "react";
const SvgMasterResourceManagement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Master_Resource_Management_svg__Layer_1"
    width="1em"
    height="1em"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <style>
        {
          ".Master_Resource_Management_svg__cls-1{fill:currentColor;stroke:currentColor;stroke-miterlimit:10;stroke-width:1.91px}"
        }
      </style>
    </defs>
    <circle
      cx={12}
      cy={18.7}
      r={1.91}
      className="Master_Resource_Management_svg__cls-1"
    />
    <path
      d="M9.14 23.48A2.87 2.87 0 0 1 12 20.61a2.87 2.87 0 0 1 2.86 2.87"
      className="Master_Resource_Management_svg__cls-1"
    />
    <circle
      cx={19.64}
      cy={18.7}
      r={1.91}
      className="Master_Resource_Management_svg__cls-1"
    />
    <path
      d="M16.77 23.48a2.87 2.87 0 0 1 2.87-2.87 2.87 2.87 0 0 1 2.86 2.87"
      className="Master_Resource_Management_svg__cls-1"
    />
    <circle
      cx={4.36}
      cy={18.7}
      r={1.91}
      className="Master_Resource_Management_svg__cls-1"
    />
    <path
      d="M1.5 23.48a2.87 2.87 0 0 1 2.86-2.87 2.87 2.87 0 0 1 2.87 2.87M4.36 13.93v-3.82h15.28v3.82M12 13.93V7.25"
      className="Master_Resource_Management_svg__cls-1"
    />
    <circle
      cx={12}
      cy={4.39}
      r={2.86}
      className="Master_Resource_Management_svg__cls-1"
    />
  </svg>
);
export default SvgMasterResourceManagement;
