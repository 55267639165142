import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  Editicon,
  Deleteicon,
  Viewicon,
} from "../../../../Components/IconComponents";
import Table from "../../../../Components/UiComponents/Table/Table";
import { useTranslation } from "react-i18next";
//import { Modal, Button } from "react-bootstrap";
import {
  contactTableData,
  contactcolumnsForExport,
} from "../../../../JsonData/ContactConfig";
import { getContacts, deleteContactURL } from "../../../../Services/EndPoints";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { getData, putDataWithBody } from "../../../../Services/Services";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import Loader from "react-spinner-loader";

const ContactTable = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const qs = require("qs");
  const [contactData, setContactData] = useState(contactTableData);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [searchData, setSearchData] = useState([]);
  let contactRegionMap = {};
  useEffect(() => {
    getContactList();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const addNewHandler = () => {
    navigate("/planning/create-contact");
  };
  const getSanitizedContactList = (contactList) => {
    let contactListUnique = [];
    let contactListTempMap = [];
    contactList.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
    });
    contactList.forEach(function (contact) {
      if (
        !(
          contactListTempMap.indexOf(contact["type"] + "_" + contact["id"]) > -1
        )
      ) {
        contactListUnique.push(contact);
      }
      contactListTempMap.push(contact["type"] + "_" + contact["id"]);
      if (contact["region_name"]) {
        if (contactRegionMap[contact["type"] + "_" + contact["id"]]) {
          let regionArr =
            contactRegionMap[contact["type"] + "_" + contact["id"]];
          regionArr.push(contact["region_name"]);
        } else {
          contactRegionMap[contact["type"] + "_" + contact["id"]] = [
            contact["region_name"],
          ];
        }
      }
    });
    return contactListUnique;
  };
  const getContactList = async () => {
    setLoader(true);
    var contactList = await getData(getContacts);

    contactList = getSanitizedContactList(contactList);

    setLoader(false);
    setContactData(contactList);
    setRowCount(contactList.length);
  };

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = contactcolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("contacttableHeaders.name"),
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: t("contacttableHeaders.jobtitle"),
        accessor: "job_title",
        disableSortBy: true,
      },

      {
        Header: t("contacttableHeaders.phone1"),
        accessor: "phone1",
        disableSortBy: true,
      },
      {
        Header: t("contacttableHeaders.email"),
        accessor: "email",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            let emailText = props.value;
            if (props.value.length > 15) {
              emailText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{emailText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("contacttableHeaders.address"),
        accessor: "address",
        Cell: (props) => {
          if (props.value) {
            let text = props.value;
            if (text == "") {
              return "NA";
            } else {
              if (props.value.length > 15) {
                text = props.value.substring(0, 15) + "...";
              }
              return <span title={props.value}>{text}</span>;
            }
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("contacttableHeaders.region"),
        accessor: "region_name",
        Cell: (props) => {
          if (props.value) {
            let idVal = props.row.original.type + "_" + props.row.original.id;
            if (contactRegionMap[idVal]) {
              let text = contactRegionMap[idVal].join(",");
              if (text.length > 15) {
                text = text.substring(0, 15) + "...";
                return (
                  <span title={contactRegionMap[idVal].join(",")}>{text}</span>
                );
              } else {
                return (
                  <span title={contactRegionMap[idVal].join(",")}>
                    {contactRegionMap[idVal].join(",")}
                  </span>
                );
              }
            } else {
              return "NA";
            }
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("contacttableHeaders.agencyname"),
        accessor: "agency_name",
        Cell: (props) => {
          if (props.value) {
            let text = props.value;
            if (props.value.length > 15) {
              text = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{text}</span>;
          } else {
            return "NA";
          }
        },

        disableSortBy: true,
      },

      {
        Header: t("contacttableHeaders.type"),
        accessor: "type",
        disableSortBy: true,
      },
      {
        Header: t("contacttableHeaders.skill"),
        accessor: "skills",
        Cell: (props) => {
          if (props.value) {
            let text = props.value;
            if (props.value.length > 15) {
              text = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{text}</span>;
          } else {
            return "NA";
          }
        },

        disableSortBy: true,
      },

      // {
      //   Header: "Contact Type",
      //   accessor: "type",
      //   disableSortBy: true,
      // },
      // {
      //   Header: "Status  ",
      //   accessor: "status",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     return <StatusHandler props={props} />;
      //   },
      // },
      {
        Header: t("contacttableHeaders.action"),
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteContactURL, data, {});
            if (res.status == "success") {
              getContactList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };
          return (
            <div className="usertable__actionsicon">
              <span
                onClick={() =>
                  navigate("/planning/create-contact", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }
              >
                {props.row.original.type == "external" && (
                  <Editicon
                    value={props.value}
                    className="cursor-pointer me-2"
                  />
                )}
              </span>
              {props.row.original.type == "external" && (
                <span onClick={deletePopUp}>
                  <Deleteicon
                    value={props.value}
                    className="cursor-pointer mr-1"
                  />
                </span>
              )}
              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  //   data={data}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.name}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t]
  );
  return (
    <div className="container  create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {contactTableData && (
          <Table
            data={contactData}
            columns={columns}
            numberOfRows={7}
            setSearchData={setSearchData}
            addButtonLabel={t("contactaddButtonLabel")}
            addNewHandler={addNewHandler}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            enableColumnFilters={true}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              padding: "0px 2px 2px 2px",
              borderRadius: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
});

export default ContactTable;
