import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export function FormConfig() { // Renamed function to follow component naming convention
  const { t } = useTranslation();

  const formConfig = [
    {
      name: 'first_name',
      label: t('formconfiglabels.firstname'),
      type: 'input',
      mandatory: true,
      "disabled": true
    },
    // {
    //   name: 'middle_name',
    //   label: t('formconfiglabels.middlename'),
    //   type: 'input',
    //   "disabled": true,
    // },
    {
      name: 'last_name',
      label: t('formconfiglabels.lastname'),
      type: 'input',
      mandatory: true,
      "disabled": true,
    },
    // {
    //   name: 'email',
    //   label: t('formconfiglabels.emailid'),
    //   type: 'input',
    //   mandatory: true,
    //   "disabled": true,
    // },
    {
      name: 'user_name',
      label: t('formconfiglabels.username'),
      type: 'input',
      mandatory: true,
      "disabled": true,
    },
    {
      name: 'phone_number',
      label: t('formconfiglabels.phonenumber'),
      type: 'input',
      mandatory: true,
      "disabled": true,
    },
    {
      name: 'theme',
      label: t('formconfiglabels.theme'),
      type: 'select',
      options: [
        { value: 'dark', label: 'Dark' },
        { value: 'white', label: 'White' }
      ],
      mandatory: true,
    },
    {
      name: 'locale',
      label: t('formconfiglabels.language'),
      type: 'select',
      options: [
        { value: 'en', label: 'English' },
        { value: 'hi', label: 'Hindi' }
      ],
      mandatory: true,
    },
  ];

  return formConfig;
}

export function AuthDetailsConfig() {
  const { t } = useTranslation();

  const authDetailsConfig = [
    {
      name: 'user_name',
      label: t('formconfiglabels.username'),
      type: 'input',
    },
    // {
    //   name: 'email',
    //   label: t('formconfiglabels.emailid'),
    //   type: 'input',
    //   mandatory: true, // Fix boolean value
    // },
  ];
  return authDetailsConfig;
}

export const chngPwSchema = yup.object({
  old_password: yup.string().required("Please provide the Old Password"),
  new_password: yup
    .string()
    .required("Please provide new password")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
      "Password must contain at least one uppercase letter, one numeric digit, one special character, and be 8-15 characters long"
    ),
  retype_password: yup
    .string()
    .required("Please provide the Confirm Password")
    .oneOf([yup.ref("new_password")], "Password must match"),
});
