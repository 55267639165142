import * as React from "react";
const SvgUserRoleManagement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.56 11.87a3.75 3.75 0 1 1 3.75-3.75 3.76 3.76 0 0 1-3.75 3.75Zm0-6a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM3.56 18.87a.75.75 0 0 1-.75-.75c0-4.75 5.43-4.75 7.75-4.75.72 0 1.36 0 1.94.07a.75.75 0 0 1 .69.8.76.76 0 0 1-.81.69c-.54 0-1.14-.06-1.82-.06-5.18 0-6.25 1.3-6.25 3.25a.74.74 0 0 1-.75.75ZM12.67 19.63a.75.75 0 0 1-.53-.22.719.719 0 0 1-.22-.59l.16-1.92a.75.75 0 0 1 .21-.47l5.52-5.52a2.06 2.06 0 0 1 2.8 0 2 2 0 0 1 .58 1.44 1.861 1.861 0 0 1-.53 1.33l-5.52 5.52a.74.74 0 0 1-.46.22l-1.94.18-.07.03Zm.88-2.34-.06.76.78-.07 5.33-5.33a.39.39 0 0 0 .09-.27.59.59 0 0 0-.14-.38.57.57 0 0 0-.68 0l-5.32 5.29Z"
    />
  </svg>
);
export default SvgUserRoleManagement;
