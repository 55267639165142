import * as React from "react";
const SvgViewicon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.162 8.053a3.162 3.162 0 1 1-6.324-.001 3.162 3.162 0 0 1 6.324.001Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M9.998 15.355c3.808 0 7.291-2.738 9.252-7.302C17.289 3.489 13.806.751 9.998.751h.004C6.194.751 2.711 3.489.75 8.053c1.961 4.564 5.444 7.302 9.252 7.302h-.004Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgViewicon;
