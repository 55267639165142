import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TextEllipsis = (props) => {
  const { text, maxLength, crossIconShow = false } = props;

  const renderTooltip = (props) => (
    <Tooltip {...props} className="text-ellipsis">
      <span>{text}</span>
    </Tooltip>
  );

  const displayText =
    text?.length > maxLength ? (
      <OverlayTrigger
        placement="top-start"
        delay={{ show: 50, hide: 100 }}
        overlay={renderTooltip}>
        <span>{text?.slice(0, maxLength)}...</span>
      </OverlayTrigger>
    ) : (
      text
    );

  return displayText;
};

export default TextEllipsis;
