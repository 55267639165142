

import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { chngPwSchema } from "./PersonalDetailsConfig";
import lmsToast from "../../DMS/Common/CustomToastifier/CustomToastifier";
import { useKeycloak } from "@react-keycloak/web";
import { appserveraddr, changePassword } from "../../../Services/EndPoints";
import { putDataWithBodyPf } from "../../../Services/ProfileServices";
import { useTranslation } from "react-i18next";
import DmsButton from "../../DMS/Common/DmsButton/DmsButton";
import { FaEye, FaEyeSlash } from "react-icons/fa"; 
import "./PersonalDetails.css";

export default function ChangePasswordComp({ togglePopup }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(chngPwSchema), mode: "all" });
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  // State to manage visibility of each password field
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "retype":
        setShowRetypePassword(!showRetypePassword);
        break;
      default:
        break;
    }
  };

  const submitHandler = (data) => {
    if (data.new_password === data.retype_password) {
      putDataWithBodyPf(changePassword, data)
        .then((res) => {
          togglePopup();
          lmsToast({ toasterType: "success", operationType: "pwChange" });
          localStorage.clear();
          keycloak.logout({ redirectUri: appserveraddr });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="password_input">
        <label>
          {t('old_password')}<span className="asterisk">*</span>
        </label>
        <div className="input-wrapper">
          <input
            type={showOldPassword ? "text" : "password"}
            {...register("old_password")}
          />
          <span
            className="password-toggle-icon"
            onClick={() => togglePasswordVisibility("old")}
          >
            {showOldPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="error__message">{errors["old_password"]?.message}</div>
      </div>

      <div className="password_input">
        <label>
          {t('new_password')}<span className="asterisk">*</span>
        </label>
        <div className="input-wrapper">
          <input
            type={showNewPassword ? "text" : "password"}
            {...register("new_password")}
          />
          <span
            className="password-toggle-icon"
            onClick={() => togglePasswordVisibility("new")}
          >
            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="error__message">{errors["new_password"]?.message}</div>
      </div>

      <div className="password_input">
        <label>
          {t('cofrm_password')}<span className="asterisk">*</span>
        </label>
        <div className="input-wrapper">
          <input
            type={showRetypePassword ? "text" : "password"}
            {...register("retype_password")}
          />
          <span
            className="password-toggle-icon"
            onClick={() => togglePasswordVisibility("retype")}
          >
            {showRetypePassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="error__message">
          {errors["retype_password"]?.message}
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <DmsButton
          label={t('popup.save')}
          variant="success"
          size="btn_sm"
          onClick={handleSubmit(submitHandler)}
        />
      </div>
    </form>
  );
}
