import * as React from "react";
const SvgXCircleblack = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#x-circleblack_svg__a)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11 20.166a9.167 9.167 0 1 0 0-18.333 9.167 9.167 0 0 0 0 18.333ZM13.75 8.25l-5.5 5.5M8.25 8.25l5.5 5.5" />
    </g>
    <defs>
      <clipPath id="x-circleblack_svg__a">
        <path fill="currentColor" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgXCircleblack;
