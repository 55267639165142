import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import { toast, ToastContainer } from "react-toastify";
import { getTaskListURL,resourceListURL,createMasterSOPURL,updateMasterSOPURL } from "../../../../Services/EndPoints";
import { getData,postDataWithBody ,putDataWithBody} from "../../../../Services/Services";
import { sopformschema ,SoPFormConfig } from "../../../../JsonData/CreateTaskConfig";
import { Button } from "react-bootstrap";
import "./SOPForm.css";
import { useTranslation } from 'react-i18next';

const SOPForm = () => {
  const [dataLoadComplete, setDataLoadComplete] = useState(false);
  const [SoPConfig, setSoPConfig] = useState(SoPFormConfig);
  const { state } = useLocation();
  let edit_data = state?.data;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(sopformschema[activeTab]), mode: "all" });

  useEffect(() => {
    getInitFormData();
  }, []);


  const getInitFormData = async() => {
    await  getTaskLists();
    await  getResourceLists();
    setDataLoadComplete(true);
    if(edit_data){
      reset(edit_data);
      populatAttachedTasks(edit_data);
      populatAttachedResources(edit_data);
    }
  } 
  function populatAttachedTasks(data){
    let taskArr = SoPConfig[0].fields[1]["options"];
    let taskArrFrEdit = [];
    let taskMap = {};
    taskArr.forEach(function(task){
      taskMap[task.value]= task.label;
    });
    data.task_id_list.split(",").forEach(function(task_id){
      if(taskMap[task_id]){
        taskArrFrEdit.push({"label":taskMap[task_id],"value":task_id})
      }
    });
    setValue("sop_task",taskArrFrEdit);
  }
  function populatAttachedResources(data){
    let resourceArr = SoPConfig[0].fields[2]["options"];
    let resourceArrFrEdit = [];
    let respourceMap = {};
    resourceArr.forEach(function(resource){
      respourceMap[resource.value]= resource.label;
    });
    data.resource_id_list.split(",").forEach(function(resource_id){
      if(respourceMap[resource_id]){
        resourceArrFrEdit.push({"label":respourceMap[resource_id],"value":resource_id})
      }
    });
    setValue("sop_resource",resourceArrFrEdit);
  }
  async function getTaskLists(){
    var taskList = await getData(getTaskListURL);
    let taskArr = [];
    taskList.forEach(function(data){
      taskArr.push({"label":data.task_name,"value":data.id})
    });
    SoPConfig[0].fields[1]["options"] = taskArr;
    setSoPConfig(SoPFormConfig);
  }

  async function getResourceLists(){
    var resourceList = await getData(resourceListURL);
    let resourceArr = [];
    let resourceListUnqueArr = [];

    
    resourceList.forEach(function(data){
      if(!(resourceListUnqueArr.indexOf(data.resource_name)>-1)){
        resourceArr.push({"label":data.resource_name,"value":data.id});
        resourceListUnqueArr.push(data.resource_name);
      }
    });
    SoPConfig[0].fields[2]["options"] = resourceArr;
    setSoPConfig(SoPFormConfig);
  }
  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        <FormHandler
          register={register}
          errors={errors}
          fields={fields}
          control={control}
          columns={2}
          getValues={getValues}
        />
      </div>
    );
  };

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const tabsData = generateTabsData(SoPConfig);

  const navToCreateTask = () =>{
    navigate("../create-task");
  }
  const navToCreateResource = () =>{
    navigate("../../resource-list/create-resource");
  }
  const submitHandler = async (data, e) => {
    e.preventDefault();
    data.status = true;
    let sop_task_map = [];
    data.sop_task.forEach(function(task){
      if(edit_data){
        sop_task_map.push({
          task_id: task.value,
          sop_id:data.id
        });
      }
      else{
        sop_task_map.push({
          task_id: task.value,
        });
      }
     

    });
    data["sop_task_map"] = sop_task_map;

    if(data.sop_resource){
      let sop_resource_map = [];
      data.sop_resource.forEach(function(resource){
        if(edit_data){
          sop_resource_map.push({
            sop_id:data.id,
            resource_id: resource.value,
          });
        }
        else{
          sop_resource_map.push({
            resource_id: resource.value,
          });
        }
      });
      data["sop_resource_map"] = sop_resource_map;
    }
    var res = null;
    if(edit_data){
      res = await putDataWithBody(updateMasterSOPURL, data, {}); 
    }
    else{
      res = await postDataWithBody(createMasterSOPURL, data, {});
    }
    if(res.status == "success"){
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      navigate("../view-sop");
    }
    else{
      toast.error(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }


  };

  return (
    <div className="mt-4 facility-container">
      <div className="form-body">
        <div className="registration__form--body">
          <div onClick={navToCreateTask} className="add_task_sop_mgmnt">{t('addtask')}</div>
          <div onClick={navToCreateResource} className="add_resource_sop_mgmnt">{t('addresource')}</div>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                  {/* Save & Submit */}
                  {t('saveAndSubmit')}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SOPForm;
