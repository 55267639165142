import React from "react";
// import "./ResourceTable.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { toast, ToastContainer } from "react-toastify";
import Moment from "react-moment";
import Table from "../../../../Components/UiComponents/Table/Table";
import { Editicon } from "../../../../Components/IconComponents";
import {
  getDataFrGIS,
  postDataWithBodyFrGIS,
} from "../../../../Services/Services";
import { getMatserUserTaskList } from "../../../../Services/EndPoints";
import { useTranslation } from "react-i18next";

const UserTaskTable = () => {
  const { t, i18n } = useTranslation();
  const [userTaskData, setUserTaskData] = useState([]);
  const [rowCount, setRowCount] = useState([]);
  const navigate = useNavigate();
  const addNewHandler = () => {
    navigate("/my-tasks/create-usertask");
  };

  useEffect(() => {
    getTaskList();
  }, []);

  const getTaskList = async () => {
    //setLoader(true);
    var masterUserTaskList = await postDataWithBodyFrGIS(
      getMatserUserTaskList,
      "",
      {}
    );
    masterUserTaskList = masterUserTaskList.data;
    setUserTaskData(masterUserTaskList);
    console.log(masterUserTaskList, "hus");
    setRowCount(masterUserTaskList?.length);
    //setLoader(false);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: t("dailytaskmgmnttableheaders.name"),
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: t("dailytaskmgmnttableheaders.tasktype"),
        accessor: "task_type",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value == 0) {
            return "Activity";
          } else if (props.value == 1) {
            return "Notification";
          }
        },
      },
      {
        Header: t("dailytaskmgmnttableheaders.assigntype"),
        accessor: "assign_type",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value == 0) {
            return "Group";
          } else if (props.value == 1) {
            return "Users";
          }
        },
      },
      {
        Header: t("dailytaskmgmnttableheaders.createdon"),
        accessor: "created_on",
        disableSortBy: true,
        Cell: (props) => {
          return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
        },
      },

      {
        Header: t("dailytaskmgmnttableheaders.action"),
        disableSortBy: true,
        Cell: (props) => {
          return (
            <div className="usertable__actionsicon">
              <span
                className="icon__position"
                onClick={() =>
                  navigate("/my-tasks/create-usertask", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }>
                <i
                  className="cursor-pointer"
                  role="button"
                  class="fa fa-eye ms-3"
                  value={props.value}></i>
              </span>
            </div>
          );
        },
      },
    ],
    [t]
  );
  return (
    <div>
      <div className="container  create-user">
        <div className="datatable__container">
          {/* {userTaskData && ( */}
          <Table
            data={userTaskData ? userTaskData : []}
            addButtonLabel={t("dailytaskaddbuttonlabel")}
            addNewHandler={addNewHandler}
            columns={columns}
            numberOfRows={7}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default UserTaskTable;
