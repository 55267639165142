import * as React from "react";
const SvgUpload = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 41 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.5}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.69 11.625c0-4.28 3.497-7.75 7.81-7.75s7.81 3.47 7.81 7.75v1.938h1.952c3.774 0 6.833 3.036 6.833 6.78 0 3.746-3.06 6.782-6.833 6.782h-1.953a1.945 1.945 0 0 0-1.952 1.938c0 1.07.874 1.937 1.953 1.937h1.952C36.192 31 41 26.23 41 20.344c0-5.272-3.857-9.65-8.923-10.505C31.21 4.267 26.357 0 20.5 0 14.642 0 9.789 4.267 8.923 9.84 3.857 10.694 0 15.071 0 20.343 0 26.229 4.808 31 10.738 31h1.953a1.945 1.945 0 0 0 1.952-1.938c0-1.07-.874-1.937-1.953-1.937h-1.952c-3.774 0-6.833-3.036-6.833-6.781 0-3.745 3.06-6.782 6.833-6.782h1.953v-1.937Zm15.048 6.38-5.857-5.812a1.963 1.963 0 0 0-2.762 0l-5.857 5.812a1.927 1.927 0 0 0 0 2.74 1.963 1.963 0 0 0 2.761 0l2.525-2.505v10.823c0 1.07.874 1.937 1.952 1.937a1.945 1.945 0 0 0 1.952-1.938V18.24l2.525 2.505a1.963 1.963 0 0 0 2.76 0 1.927 1.927 0 0 0 0-2.74Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUpload;
