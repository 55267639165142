import React, { useEffect, useState, useRef, useContext } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../..";

const AnimalCenterTrendView = (props) => {
  const echartsDom = useRef(null);
  const { theme } = useContext(ThemeContext);
  const [chart, setChart] = useState(null);
  const { t } = useTranslation();

  const data = {
    facility_type: t("navlinklabels.animalshelter"),
    attributes: [
      { name: t("animaltreated"), count: 10 },
      { name: t("animalfed"), count: 56 },
    ],
  };
  const getLegendColor = (theme) => {
    return theme === "light" ? "#2B6591" : "#fff";
  };
  const options = {
    tooltip: {
      trigger: "item",
      confine: true,
    },
    legend: {
      orient: "vertical",
      right: "0", // Align the legend to the right
       bottom: "center",
      selectedMode: false,
      textStyle: {
        color: getLegendColor(theme), // Legend text color
      },
    },
    xAxis: {
      type: "category",
      axisLine: {
        lineStyle: {
          color: getLegendColor(theme), // X-axis line color
        },
      },
      axisTick: {
        show: false, // Remove ticks if unnecessary
      },
      splitLine: {
        show: false, // Remove split lines
      },
      axisLabel: {
        show: false, // Hide axis labels
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: getLegendColor(theme), // Y-axis line color
        },
      },
      splitLine: {
        show: false, // Remove split lines
      },
      min: 0, // Minimum value for the y-axis
    },
    series: [
      {
        name: "Animals Treated",
        data: [10,0], // Men data for the first category
        type: "bar",
        itemStyle: {
          color: "#327910",
          barBorderRadius: [10, 10, 0, 0],
        },
        barWidth: "30",
      },
      {
        name: "Animals Fed",
        data: [0,20], // Women data for the second category
        type: "bar",
        itemStyle: {
          color: "#E95060",
          barBorderRadius: [10, 10, 0, 0],
        },
        barWidth: "30",
      },
    ],
    grid: {
      left: "5%", // Adjust as needed to fit the layout
      bottom: "25%",
      top: "5%",
      right: "35%", // To provide some spacing on the right
    },
  };
  // const getAnimalsTreatedCount = () => {
  //   let count = 0;
  //   if (props)
  //     props.dataval.forEach(function (data, index) {
  //       if (data['attribute_code'].indexOf('TRTD') > -1) {
  //         count += parseInt(data['facility_report_attribute_value']);
  //       }
  //     });
  //   return count;
  // }
  // const getAnimalsFedCount = () => {
  //   let count = 0;
  //   props.dataval.forEach(function (data, index) {
  //     if (data['attribute_code'].indexOf('FED') > -1) {
  //       count += parseInt(data['facility_report_attribute_value']);
  //     }
  //   });
  //   return count;
  // }
  useEffect(() => {
    const myChart = echarts.init(echartsDom.current); // echarts theme

    myChart.setOption(options);
  }, [props.dataval, t]);
  return (
    <div class="card" style={{ width: "100%", height: "95%" }}>
      <div class="card-body" style={{ height: "100%" }}>
        <div class="card-title">
          {data.facility_type}
          {/* <span className="float-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots-vertical"
              viewBox="0 0 16 16">
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
          </span> */}
        </div>
        <div ref={echartsDom} style={{ height: "100%", width: "100%" }} />
      </div>
    </div>
  );
};
export default AnimalCenterTrendView;
