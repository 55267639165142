export const CreateRoleConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "name",
        label: "roleformlabels.name",
        type: "input",
        mandatory: "true"
      },
      {
        name: "permission",
        label: "roleformlabels.permission",
        type: "hier_select",
        mandatory: "true",
        
      },
      {
        name: "description",
        label: "roleformlabels.description",
        type: "text_area"
      }
    ]
  }
];

    