import * as React from "react";
const SvgConfigurationManagement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    fill="currentColor"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.41421,
    }}
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M3.552 35.207V12.278a3.027 3.027 0 0 1 3.026-3.026h34.649a3.03 3.03 0 0 1 3.028 3.026v22.929a3.03 3.03 0 0 1-3.028 3.026H6.578a3.027 3.027 0 0 1-3.026-3.026Zm38.788 0V12.278c0-.61-.499-1.11-1.113-1.11H6.578c-.613 0-1.112.498-1.112 1.11v22.929c0 .613.499 1.111 1.112 1.111h34.649c.612 0 1.113-.498 1.113-1.111Z" />
    <path d="M6.913 13.092a.92.92 0 1 1 .92.917.922.922 0 0 1-.92-.917Zm2.601 0a.919.919 0 1 1 1.838-.002.919.919 0 0 1-1.838.002Zm2.599 0a.92.92 0 0 1 .917-.92c.505 0 .919.413.919.92a.922.922 0 0 1-.919.917.92.92 0 0 1-.917-.917ZM31.438 29.202l-.223-.947a3.807 3.807 0 0 1-.713-.294l-.926.575c-.241.151-.521.198-.725 0l-.42-.425c-.2-.199-.142-.466 0-.724l.57-.928a3.806 3.806 0 0 1-.349-.929l-.886-.213c-.279-.062-.513-.228-.513-.509v-.599c0-.28.234-.429.513-.512l1.021-.239.289-.605c-.166-.271-.334-.541-.496-.81-.155-.242-.201-.525 0-.723l.421-.422c.199-.201.471-.14.724 0l.866.537.588-.24c.078-.322.165-.643.259-.96.084-.279.228-.513.511-.513h.6c.28 0 .446.234.511.513l.243.956.706.293c.253-.151.506-.295.761-.438.256-.138.524-.201.722 0l.426.423c.202.198.15.481 0 .721l-.447.751.226.478c.369.093.72.185 1.036.278.279.081.513.227.513.512v.599c0 .281-.234.445-.513.509l-.899.229-.281.803c.177.293.347.591.513.892.143.252.201.521 0 .72l-.423.424c-.2.2-.481.151-.721 0l-.817-.485a3.775 3.775 0 0 1-.822.355l-.223.947c-.065.279-.228.513-.511.513h-.6c-.281 0-.427-.234-.511-.513Zm2.641-4.484c0-1.008-.821-1.83-1.83-1.83-1.008 0-1.83.822-1.83 1.83 0 1.009.822 1.83 1.83 1.83 1.009 0 1.83-.821 1.83-1.83Z" />
    <path d="M25.127 24.718c0-3.923 3.196-7.121 7.122-7.121 3.924 0 7.122 3.198 7.122 7.121 0 3.926-3.198 7.123-7.122 7.123-3.926 0-7.122-3.197-7.122-7.123Zm13.202 0c0-3.347-2.733-6.079-6.08-6.079-3.349 0-6.079 2.732-6.079 6.079a6.09 6.09 0 0 0 6.079 6.08c3.347 0 6.08-2.73 6.08-6.08ZM6.913 31.744v-.002c0-.853.701-1.549 1.563-1.549h3.402a1.56 1.56 0 0 1 1.564 1.549v.002a1.56 1.56 0 0 1-1.564 1.55H8.476a1.559 1.559 0 0 1-1.563-1.55Zm3.793-.078v-.002a.969.969 0 0 0-.971-.962H8.457a.969.969 0 0 0-.972.962v.002c0 .53.437.962.972.962h1.278a.969.969 0 0 0 .971-.962ZM14.274 30.724c0-.292.239-.533.532-.533h6.288a.534.534 0 0 1 0 1.065h-6.288a.533.533 0 0 1-.532-.532Zm-.023 1.927c0-.291.238-.532.531-.532h2.659a.534.534 0 0 1 0 1.065h-2.659a.532.532 0 0 1-.531-.533ZM6.911 25.963v-.002a1.56 1.56 0 0 1 1.565-1.549h3.402c.862 0 1.562.696 1.562 1.549v.002c0 .853-.7 1.549-1.562 1.549H8.476a1.56 1.56 0 0 1-1.565-1.549Zm5.959-.078v-.002a.968.968 0 0 0-.971-.962H10.62a.967.967 0 0 0-.97.962v.002c0 .529.436.962.97.962h1.279a.97.97 0 0 0 .971-.962ZM14.244 24.942c0-.292.239-.532.532-.532h3.092c.291 0 .533.24.533.532a.535.535 0 0 1-.533.532h-3.092a.533.533 0 0 1-.532-.532Zm.052 1.928c0-.292.238-.532.531-.532h4.433c.292 0 .532.24.532.532 0 .293-.24.531-.532.531h-4.433a.531.531 0 0 1-.531-.531ZM6.916 19.528v-.002c0-.854.7-1.549 1.562-1.549h3.402c.862 0 1.564.695 1.564 1.549v.002a1.56 1.56 0 0 1-1.564 1.55H8.478a1.558 1.558 0 0 1-1.562-1.55Zm3.792-.078v-.003a.97.97 0 0 0-.971-.962H8.459a.968.968 0 0 0-.97.962v.003c0 .529.435.962.97.962h1.278a.97.97 0 0 0 .971-.962ZM14.276 18.507c0-.292.239-.532.532-.532h6.288a.533.533 0 0 1 0 1.064h-6.288a.532.532 0 0 1-.532-.532Zm.022 1.928c0-.292.238-.532.531-.532h4.433c.292 0 .532.24.532.532 0 .293-.24.532-.532.532h-4.433a.532.532 0 0 1-.531-.532Z" />
  </svg>
);
export default SvgConfigurationManagement;
